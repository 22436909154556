import { API } from './instances'

export const getClinicsDetailed = async () => {
    const url = '/Clinicas'
    return await API.get(url)
}

export const getPediatricPatientData = async (docType, docNum, clinicId) => {
    const url = `/Turnos/DatosPacientePediatria`
    let params = { tipoDoc: docType, nroDoc: docNum, clinica: clinicId }
    return await API.get(url, { params })
}

export const getPatientDataAtClinic = async clinicId => {
    const url = `/Turnos/DatosPaciente/${clinicId}`
    return await API.get(url)
}

export const getPatientAppointments = async (patientId, clinic) => {
    const url = `/Turnos?idPaciente=${patientId}&clinica=${clinic}`
    return await API.get(url)
}

export const getPatientCancelledAppointments = async () => {
    const url = `/Turnos/Cancelados`
    return await API.get(url)
}

export const getCancelReasons = async clinic => {
    const url = `/Turnos/MotivosBaja?clinica=${clinic}`
    return await API.get(url)
}

export const getFutureAppointmentGol = async patientErid => {
    const url = `/Guardias/ObtenerProximoTurno?pacienteErid=${patientErid}`
    return await API.get(url)
}

export const cancelAppointment = async (appointment, clinic, reason, date) => {
    const url = `/Turnos/Cancelar`
    const params = {
        clinica: clinic,
        nroSolicitud: appointment,
        codigoMotivo: reason,
    }
    if(date) {
        params.fechaTurnoDateTime = date
    }
    return await API.post(url, params)
}

export const getSpecialties = async data => {
    const url = '/Turnos/Especialidades'
    return await API.get(url, { params: data })
}

export const getProfessionals = async data => {
    const url = '/Turnos/Profesionales'
    return await API.get(url, { params: data })
}

export const getAvailableAppointments = async data => {
    const url = '/Turnos/Disponibles'
    return await API.get(url, { params: data })
}

export const confirmAppointment = async data => {
    const url = '/Turnos/Confirmar'
    return await API.post(url, data)
}

  // export const sendConfirmationAppointment = async params => {
  //   const url = '/Turnos/EnviarConfirmacion'
  //   return await API.post(url, params)
  // }