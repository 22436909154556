import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Modal from './Modal'
import { Button, MaterialIcon } from '../styled/generics'

const AppointmentsCancelledModal = props => {
    const renderMessage = () => {
        return (
            <React.Fragment>
                <Title>
                    <MaterialIcon name="highlight_off" />
                    <h2>Atención</h2>
                </Title>

                <h3>Cancelación de Turnos</h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <HeaderCell>Fecha y Hora</HeaderCell>
                            <HeaderCell>Profesional</HeaderCell>
                            <HeaderCell>Especialidad</HeaderCell>
                            <HeaderCell>Lugar</HeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.cancelledAppointments.map((appointment, index) => {
                            return(
                            <TableRow key={index}>
                                <TableCell>{appointment.fechaTurno}</TableCell>
                                <TableCell>{appointment.nombreProfesional}</TableCell>
                                <TableCell>{appointment.descripcion}</TableCell>
                                <TableCell>{appointment.clinica}</TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                </Table>
                
                <ContactInformation>
                    <p>Podés reprogramarlo desde la sección <b>turnos en consultorios externos</b> o a través de nuestros canales no presenciales de atención, una vez que finalice la cuarentena.</p>
                    <p>Escribinos por <b>WhatsApp 11 4493 2017</b></p>
                    <p>Mandanos un mensaje privado por Facebook o Instagram</p>
                    <p>Llamanos <b>0800 666 6587</b></p> 
                </ContactInformation>
  
            </React.Fragment>
        )
    }
  
    return (
      <Modal>
        <ModalContainer>
          {renderMessage()}
          <ActionContainer>
            <Button onClick={props.action}>Aceptar</Button>
          </ActionContainer>
        </ModalContainer>
      </Modal>
    )
  }
  
  AppointmentsCancelledModal.propTypes = {
    action: PropTypes.func.isRequired,
    cancelledAppointments: PropTypes.arrayOf(PropTypes.shape({
        nombreProfesional: PropTypes.string.isRequired,
        descripcion: PropTypes.string.isRequired,
        clinica: PropTypes.string.isRequired,
        fechaTurno: PropTypes.string.isRequired,
      })).isRequired,
  }
  
  export default AppointmentsCancelledModal
  
  /*
   * Styles
   */
  const ModalContainer = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    color: ${props => props.theme.colors.green};
  
    @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
      width: ${props => props.theme.sizes.breakPoints.small};
    }
  `
  
  const ActionContainer = styled.div`
    margin-top: 30px;
    width: 100%;
  
    > button:first-child {
      margin-bottom: 10px;
    }
  
    @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
      display: flex;
      width: 370px;
      margin-left: auto;
      margin-right: auto;
    }
  `

  const Title = styled.div`
    > h2 {
        margin: 0; 
    }

    > i {
        font-size: 50px;
    }
  `
  
  const Table = styled.table`
    margin: auto;
    width: 90%;
    border: 2px solid ${props => props.theme.colors.green};
  `

  const ContactInformation = styled.div`
    margin-top: 20px;

    > p {
        margin: 10px; 
    }
  `

  const TableHeader = styled.thead`
    
  `
  const HeaderCell = styled.th`

  `

  const TableBody = styled.tbody`

  `
  const TableRow = styled.tr`

  `
  const TableCell = styled.td`

  `