import { API } from './instances'

export const getUsers = async data => {
    const url = `/Usuarios/Buscar`
    return await API.post(url, data)
}

export const getUserById = async userId => {
    const url = `/Usuarios?idUsuario=${userId}`
    return await API.get(url)
}

export const getGenders = async () => {
    const url = '/Metadatos/ObtenerValores?clave=Sexo'
    return await API.get(url)
}

export const getDocumentTypes = async () => {
    const url = '/Metadatos/ObtenerValores?clave=TipoDocumento'
    return await API.get(url)
}

export const getHealthInsurances = async () => {
    const url = '/Usuarios/ObrasSociales'
    return await API.get(url)
}

export const getHealthInsurancePlans = async healthInsurance => {
    const url = `/Usuarios/Planes?obraSocial=${healthInsurance}`
    return await API.get(url)
}

export const signUp = async params => {
    const url = '/Auth/SignUp'
    return await API.post(url, params)
}

export const signUpMW = async params => {
    const url = '/Auth/CreateUserMW'
    return await API.post(url, params)
}

export const createUser = async data => {
    const url = '/Usuarios/Alta'
    return await API.post(url, data)
}

export const updateUser = async data => {
    const url = '/Usuarios/Actualizar'
    return await API.post(url, data)
}

export const blankPassword = async email => {
    const url = `/Usuarios/Blanquear?email=${email}`
    return await API.get(url)
}

export const deleteUser = async idUsuario => {
    const url = `/Usuarios/Baja?idUsuario=${idUsuario}`
    return await API.post(url)
}

export const setPP = async data => {
    const url = `/Usuarios/SetPP`
    return await API.post(url, data)
}

export const updateUserName = async data => {
    const url = `/Usuarios/ActualizarNombre`
    return await API.post(url, data)
}