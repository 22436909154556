import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import moment from 'moment'
import to from 'await-catch'
import { withRouter } from 'react-router-dom'

import { setCheckInInfo } from '../actions'
import { getCheckInAppointment, recoverOldAppointmentData } from '../api'
import { Button } from '../styled/generics'

class ClinicGuardCheckIn extends React.Component {
  constructor(props) {
    super(props)

    this.clinicInfo = null
    if (Number.isInteger(props.activeClinicId)) {
      const selected = props.clinicsTimes.find(
        ct => ct.clinica.codigoEmpresa === props.activeClinicId
      )
      this.clinicInfo = {
        id: selected.clinica.id,
        name: selected.clinica.nombre,
        waitingTime: this.estimateWaitingTime(selected.espera)
      }
    }
  }

  estimateWaitingTime(waitingTime) {
    return waitingTime >= 0 ? moment().add(waitingTime, 'm') : null
  }

  handleBackClick = () => this.props.history.goBack()

  getDocumentOrDefault = () => {
    return !!this.props.patientAtClinicDetails.numeroDocumento
      ? this.props.patientAtClinicDetails.numeroDocumento
      : null
  }

  handleClick = async () => {
    const params = {
      clinica: this.clinicInfo.id,
      especialidad: this.props.speciality.idEspecialidad,
      fecha: moment(this.clinicInfo.waitingTime).format('DD/MM/YYYY HH:mm'),
      NroDoc: this.getDocumentOrDefault()
    }

    const [err, { data } = {}] = await to(getCheckInAppointment(params))
    if (!err) {
      if (!data.nuevo) {
        const [err, { data: oldApp } = {}] = await to(
          recoverOldAppointmentData({
            clinic: this.clinicInfo.id,
            appointment: data.turno
          })
        )
        if (!err) {
          this.clinicInfo.waitingTime = moment(oldApp.fechaTurnoDateTime)
        }
      }
      this.props.setCheckInInfo({ ...data, ...this.clinicInfo })
      this.props.history.push(`${this.props.match.url}/fila`)
    }
  }

  render() {
    return (
      <Container>
        <AppointmentInfo>
          {!this.clinicInfo.waitingTime ? (
            <SectionName>
              La especialidad seleccionada no está disponible en esta clínica
            </SectionName>
          ) : (
            <React.Fragment>
              <P>
              Ingresá a la fila online para ir a la guardia de <b>{this.clinicInfo.name}</b>
              </P>
              <TextGreen>{this.props.speciality.nombre}</TextGreen>
              <P green>
                Te esperamos a partir de las <b>{this.clinicInfo.waitingTime.format('HH:mm')}</b>
              </P>
              <ButtonsContainer>
                <Button onClick={this.handleBackClick}>Volver</Button>
                <Button onClick={this.handleClick}>Ingresar a la fila</Button>
              </ButtonsContainer>
            </React.Fragment>
          )}
        </AppointmentInfo>
      </Container>
    )
  }
}

const mapDispatchToProps = { setCheckInInfo }
function mapStateToProps({
  smartGuard: { clinicsTimes, speciality },
  appointment: { patientAtClinicDetails }
}) {
  return { clinicsTimes, speciality, patientAtClinicDetails }
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClinicGuardCheckIn)
)

/*
 * Styles
 */
const Container = styled.div`
  margin-top: 40px;
`
const AppointmentInfo = styled.div`
  text-align: center;
`
const P = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};

  ${props =>
    props.green &&
    css`
      color: ${props => props.theme.colors.green};
      font-size: 20px;
    `};
`
const TextGreen = styled.p`
  margin: 10px 0;
  font-size: 42px;
  font-weight: 600;
  color: ${props => props.theme.colors.green};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 28px;
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-top: 111px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 60px;
  }
`

const SectionName = styled.h4`
  font-size: 26px;
  text-align: center;
`
