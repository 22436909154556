import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import moment from 'moment'
import 'moment/locale/es'

import Calendar from './Calendar'
import { getAvailableAppointments } from '../api'
import { APPOINTMENT_DATE_FORMAT } from '../constants'
import FormSelectAppointment from './FormSelectAppointment'

moment.locale('es')

class ScheduleAppointment extends React.Component {
  state = {
    appointmentsAvailable: [],
    selectedMonth: moment().startOf('month'),
    appointmentsForDay: []
  }

  componentDidMount() {
    this.getAppointments(this.state.selectedMonth)
  }

  getAppointments = async month => {
    const params = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      idObraSocial: this.props.patient.idObraSocial,
      idPlan: this.props.patient.idPlan,
      idEspecialidad: this.props.specialty,
      idProfesional: this.props.professional,
      idSistema: '',
      fechaDesde: month.format('YYYY-MM-DD'),
      fechaHasta: moment(month)
        .add(1, 'months')
        .format('YYYY-MM-DD'),
      horaDesde: moment({ hour: 0, minute: 0, second: 0 }).format('HH:mm:ss'),
      horaHasta: moment({ hour: 23, minute: 59, second: 59 }).format('HH:mm:ss')
    }

    const [err, { data } = {}] = await to(getAvailableAppointments(params))
    if (!err) {
      this.setState({
        appointmentsAvailable: data,
        selectedMonth: month,
        selectedDay: '',
        appointmentsForDay: []
      })
    }
  }

  changeMonth = previous => {
    let newMonth = moment(this.state.selectedMonth)
    if (previous) {
      if (this.state.selectedMonth.isSame(moment(), 'months')) return
      newMonth.subtract(1, 'months')
    } else {
      newMonth.add(1, 'months')
    }
    this.getAppointments(newMonth)
  }

  handleClickDay = date =>
    this.setState({
      selectedDay: moment(date),
      appointmentsForDay: this.findAppointmentsForToday(date).turnos
    })

  findAppointmentsForToday = day => {
    return this.state.appointmentsAvailable.find(appointment =>
      day.isSame(moment(appointment.fecha, APPOINTMENT_DATE_FORMAT), 'days')
    )
  }

  onFormSubmit = ({ schedule }) => this.props.onSubmit({ schedule: this.state.appointmentsForDay[schedule] })

  render() {
    return (
      <React.Fragment>
        <MonthSelector>
          <ChangeMonth
            disabled={this.state.selectedMonth.isSame(moment(), 'months')}
            onClick={() => this.changeMonth(true)}
          >
            <Icon className="material-icons">keyboard_arrow_left</Icon>
            <MonthName>
              {moment(this.state.selectedMonth)
                .subtract(1, 'months')
                .format('MMMM')}
            </MonthName>
          </ChangeMonth>
          {this.state.selectedMonth.format('MMMM YYYY')}
          <ChangeMonth onClick={() => this.changeMonth(false)}>
            <MonthName>
              {moment(this.state.selectedMonth)
                .add(1, 'months')
                .format('MMMM')}
            </MonthName>
            <Icon className="material-icons">keyboard_arrow_right</Icon>
          </ChangeMonth>
        </MonthSelector>
        <Calendar
          selectedMonth={this.state.selectedMonth}
          selectedDay={this.state.selectedDay}
          appointments={this.state.appointmentsAvailable}
          onClickDay={this.handleClickDay}
        />
        <FormSelectAppointment
          allDoctors={this.props.professional < 0}
          schedules={this.state.appointmentsForDay}
          onSubmit={this.onFormSubmit}
          onCancel={this.props.onCancel}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps({ appointment, patient }) {
  return { appointment, patient }
}
export default connect(mapStateToProps, null)(ScheduleAppointment)

/**
 * Styles
 */
const MonthSelector = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${props => props.theme.colors.green};
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 24px;
    margin-top: 32px;
  }
`
const ChangeMonth = styled.div`
  font-size: 16px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.grey};
      &:hover {
        cursor: not-allowed;
      }
    `};
`
const Icon = styled.i`
  font-size: 22px;
`
const MonthName = styled.span`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: none;
  }
`
