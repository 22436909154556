import { API } from './instances'

export const login = async credentials => {
  const url = '/Auth/Login'
  return await API.post(url, credentials)
}

export const logout = async () => {
  const url = '/Auth/Logout'
  return await API.post(url)
}

export const changeEmail = (id, email, newEmail) => {
  const url = `/Auth/ChangeEmail?id=${id}&email=${email}&newEmail=${newEmail}`
  return API.get(url)
}

export const restoreAccount = async email => {
  const url = `/Auth/ResetPassword?email=${email}`
  return await API.get(url)
}

export const changePassword = async (hash, newPassword) => {
  const url = `/Auth/ChangePassword`
  let params = { token: hash, newPassword: newPassword }
  return await API.post(url, params)
}

export const changeExistingPassword = async (userId, newPassword) => {
  const url = `/Auth/ChangeExistingPassword`
  let params = { userId: userId, newPassword: newPassword }
  return await API.post(url, params)
}