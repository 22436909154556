import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'

import { APPOINTMENT_STAGE_CANCELLED } from '../constants';
import AppointmentHeader from './AppointmentHeader'
import { Paragraph, Button } from '../styled/generics'
import { clearAppointmentState, setAppointmentStage } from '../actions'

class CancelledAppointment extends React.Component {

  componentDidMount() {
    this.props.setAppointmentStage(APPOINTMENT_STAGE_CANCELLED);
  }

  handleFinishFlow = () => {
    this.props.clearAppointmentState()
    this.props.history.push('/inicio')
  }
  render() {
    return (
      <Content>
        <AppointmentHeader
          fullName={this.props.patient.nombreApellido}
          healthInsurance={this.props.patient.coberturaMedica}
          memberNumber={this.props.patient.nroAfiliado}
        />
        <Container>
          <Icon className="material-icons">close</Icon>
          <Title>Tu turno fue cancelado</Title>
        </Container>
        <ButtonsContainer>
          <Button2 onClick={this.handleFinishFlow}>Finalizar</Button2>
        </ButtonsContainer>
      </Content>
    )
  }
}

const mapDispatchToProps = { clearAppointmentState, setAppointmentStage }
function mapStateToProps({ patient }) {
  return { patient }
}
export default connect(mapStateToProps, mapDispatchToProps)(CancelledAppointment)

/**
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`
const Container = styled.div`
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  width: 578px;
  margin: auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
const Icon = styled.i`
  margin-top: 25px;
  font-size: 120px;
  color: ${props => props.theme.colors.redTwo};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 90px;
  }
`
const Title = styled(Paragraph)`
  font-size: 36px;
  color: ${props => props.theme.colors.redTwo};
  margin-bottom: 25px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 28px;
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`
const Button2 = styled(Button)`
  width: 320px;
  margin-top: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    margin-top: 20px;
  }
`
