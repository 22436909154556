import React from 'react'
import styled from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'
import Layout from './shared/Layout'
import FormFilterLabResults from './FormFilterLabResults'
import {
  getClinicsDetailed,
  downloadExamResult,
  getExamsResults,
  logUserDataConfirmation
} from '../api'
import { PAGINATION_PARAMETERS, CLINICS_ID } from '../constants'
import Table from './Table'
import { MaterialIcon, MobileFullWidth } from '../styled/generics'

class LabsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clinics: [],
      request: {
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas
      },
      searchedAndNoData: false,
      patientsSearched: false
    }
  }

  async componentDidMount() {
    if (this.props.patient) {
      const logParams = {
        nroDoc: this.props.patient.numeroDocumento,
        nombreApellido: this.props.patient.nombreApellido,
        coberturaMedica: this.props.patient.coberturaMedica,
        nombreApenroAfiliadollido: this.props.patient.nroAfiliado
      }

      await to(logUserDataConfirmation(logParams))
    }

    const [err, { data } = {}] = await to(getClinicsDetailed())
    if (!err) {
      const clinicsList = [{ id: 0, nombre: 'Ver todos los resultados' }].concat(
        data.filter(
          clinic => clinic.id !== CLINICS_ID.CentroBazte && clinic.id !== CLINICS_ID.CentroRita
        )
      )
      this.setState({ clinics: clinicsList })
    }

    this.handleSubmit({ clinic: 0 })
  }

  handleSubmit = async (formValues = undefined) => {
    let form = {}
    if (formValues) {
      form = {
        ...this.state.request,
        clinica: formValues.clinic,
        pacienteERID: this.props.patient.pacienteERID,
        items: []
      }
    } else {
      form = {
        ...this.state.request
      }
    }

    const [err, { data } = {}] = await to(getExamsResults(form))
    if (!err) {
      this.setState({
        patientsSearched: true
      })
      if (data.hasOwnProperty('items')) {
        this.setState({
          request: { ...form, ...data },
          searchedAndNoData: false
        })
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  setInitialPage = () => {
    const newState = this.state
    newState.request.pagina = 1
    this.setState(newState)
  }

  downloadExamResult = async (e, row) => {
    e.preventDefault()
    const request = {
      actId: row.actId
    }

    const [err, { data } = {}] = await to(downloadExamResult(request))
    if (!err) {
      var link = document.createElement('a')
      link.href = data
      link.target = '_blank'
      link.click()
    }
  }

  render() {
    const columns = [
      {
        title: 'Descargar',
        index: 'accion',
        size: 'minmax(100px, 100px)',
        render: row => {
          return (
            <Download onClick={e => this.downloadExamResult(e, row)}>
              <MaterialIcon name="get_app" />
              <span>PDF</span>
            </Download>
          )
        }
      },
      { title: 'Numero de Orden', index: 'numeroOrden', size: 'minmax(200px,1fr)' },
      { title: 'Fecha Informe', index: 'fechaInforme', size: 'minmax(200px,1fr)' },
      { title: 'Clinica', index: 'clinica', size: 'minmax(200px,1fr)' }
    ]

    return (
      <Layout
        topSpace
        title="Resultados de Laboratorio"
        subTitle={
          <p>
            Consultá acá tus resultados de laboratorio hechos en las clínicas Bazterrica, del Sol o
            Santa Isabel.
          </p>
        }
      >
        <FiltersContainer>
          <FormFilterLabResults
            clinics={this.state.clinics}
            onSubmit={values => {
              this.setInitialPage()
              this.handleSubmit(values)
            }}
          />
        </FiltersContainer>
        {this.state.patientsSearched && (
          <MobileFullWidth>
            <Table
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
            />
          </MobileFullWidth>
        )}
      </Layout>
    )
  }
}

function mapStateToProps({ patient, auth }) {
  return { patient, auth }
}

export default connect(
  mapStateToProps,
  null
)(LabsList)

/**
 * Styles
 */
const FiltersContainer = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
  background: ${props => props.theme.colors.greyFive};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    min-height: 55px;
    height: auto;
  }
`

const Download = styled.button`
  display: flex;
  width: auto;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.green};
`
