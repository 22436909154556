import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '../styled/generics'
import { Link } from 'react-router-dom'
import { DISABLED_FEATURE_MESSAGE } from '../constants'
import DashboardButtonMessage from './DashboardButtonMessage'

const DashboardButton = props => {
  let dashboardContainer = null

  let buttonMessage = props.cta
  let showNotifications = props.notification
  let extraMargin = props.notification !== null ? 1 : 0

  if (!props.show) return dashboardContainer

  if (props.disable) {
    showNotifications = false
    buttonMessage = DISABLED_FEATURE_MESSAGE
    extraMargin = 0
  }

  const dashboardContent = (
    <Fragment>
      {showNotifications && <DashboardButtonMessage message={props.notification} />}
      <Title>{props.title}</Title>
      <ActionButton disabled={props.disable} outline>{buttonMessage}</ActionButton>
    </Fragment >
  )

  if (props.isExternalLink) {
    dashboardContainer = (
      <Anchor
        image={props.image}
        href={props.link}
        extra_margin={extraMargin}
        disable={props.disable}
      >
        {dashboardContent}
      </Anchor>
    )
  } else {
    dashboardContainer = (
      <Container
        image={props.image}
        to={props.link}
        extra_margin={extraMargin}
        disable={props.disable}
      >
        {dashboardContent}
      </Container>
    )
  }

  return dashboardContainer
}

DashboardButton.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  notification: PropTypes.string,
  isExternalLink: PropTypes.bool,
  disable: PropTypes.bool,
  show: PropTypes.bool
}

DashboardButton.defaultProps = {
  disable: false,
  show: true
}

export default DashboardButton

/*
 * Styles
 */
const ContainerCSS = css`
      ${props => props.disable && css`pointer-events: none;`};
      display: flex;
      width: 200px;
      height: 210px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 2%;
      text-decoration: none;
      position: relative;
      background: url(${props => props.image}) center / cover no-repeat;
      box-shadow: inset 0 0 0 1000px ${props =>
    props.disable
      ? 'rgb(162 157 157 / 0.8);'
      : 'rgba(4, 112, 94, 0.7);'}
      margin: 0px 5px 45px;


      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        padding - top: 40px;
      padding-bottom: 25px;
      width: 100%;
      height: auto;
      margin-bottom: 0;
      &:not(:first-child) {
        margin - left: 0;
      margin-top: 20px;
    }
      ${props =>
    props.extra_margin &&
    css`
        &:not(:first-child) {
          margin-top: 50px;
        }
      `};
  }

      &:hover {
        box - shadow: inset 0 0 0 1000px rgba(4, 112, 94, 0.85);
  }
      `
const Container = styled(Link)`
      ${ContainerCSS};
      `
const Anchor = styled.a`
      ${ContainerCSS};
      `

const Title = styled.h2`
      font-size: 26px;
      color: white;
      margin-top: auto;
      margin-bottom: auto;

      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        font - size: 24px;
      margin-bottom: 20px;
  }
`
const ActionButton = styled(Button)`
${props =>
    props.disabled &&
    css`
    white-space: pre-wrap;
    background: transparent;
    border-color: ${props => props.theme.colors.grey};
    box-shadow: inset 0 0 0 1000px rgb(162 157 157 / 0);
    border: 1px solid white;
  `}
`