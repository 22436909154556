import React from 'react'
import styled from 'styled-components'
import { Button, Select } from '../styled/generics'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'

import { ErrorField } from '../styled/generics'

const initialValues = { reason: '' }
const validations = yup.object().shape({
  reason: yup.string().required('Campo requerido')
})

const FormCancelAppointment = props => (
  <Container>
    <Formik
      onSubmit={values => {
        props.onSubmit(values, props.appointment)
      }}
      initialValues={initialValues}
      validationSchema={validations}
      render={renderProps => (
        <form onSubmit={renderProps.handleSubmit}>
          <Select warning="true" component="select" name="reason">
            <option value="" disabled hidden>
              Motivo de baja para cancelar el turno
            </option>
            {props.cancelReasons.map((reason, index) => (
              <option value={reason.codigo} key={index}>
                {reason.motivo}
              </option>
            ))}
          </Select>
          {renderProps.touched.reason &&
            renderProps.errors.reason && <ErrorField>{renderProps.errors.reason}</ErrorField>}
          <ButtonsContainer>
            <Button warning onClick={props.onCancel}>
              Volver
            </Button>
            <Button warning type="submit">
              Confirmar
            </Button>
          </ButtonsContainer>
        </form>
      )}
    />
  </Container>
)

FormCancelAppointment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  appointment: PropTypes.number.isRequired,
  cancelReasons: PropTypes.array.isRequired
}

export default FormCancelAppointment

/**
 * Styles
 */
const Container = styled.div`
  width: 520px;
  margin: auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    padding: 0;
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 25px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 12.6px;
  }
`
