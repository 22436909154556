export const CLINICS_LIST = {
  1: { name: 'Clínica Santa Isabel', url: 'http://www.santaisabel.com.ar/santaisabel/' },
  3: { name: 'Clínica Del Sol', url: 'http://www.cdelsol.com.ar/dnn_clsol/' },
  2: { name: 'Centro Médico Bazterrica', url: 'http://www.cmbazterrica.com.ar/cmbazterrica/' },
  5: { name: 'Clínica Bazterrica', url: 'http://www.bazterrica.com.ar/bazterrica/' },
  4: { name: 'Centro Médico Santa Rita', url: 'http://www.cmsantarita.com.ar/cmsantarita/' } //TODO: uncomment when site is completed and/or mkt says so?
}

export const CLINICS_ID = {
  SantaIsabel: 1,
  Bazterrica: 2,
  DelSol: 3,
  CentroBazte: 4,
  CentroRita: 5
}

export const MEDICAL_CLINIC_ID = 30
export const PEDIATRIC_CLINIC_ID = 32