import React from "react";
import Switch from "react-switch";
import PropTypes from 'prop-types'
 
const ToggleSwitch = props =>(
        <Switch onChange={props.handleChange} checked={props.checked} />
) 
 
ToggleSwitch.propTypes = {
    handleChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired
    }
  
export default ToggleSwitch

