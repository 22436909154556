const devLocal = {
  endpoints: {
    API_URL: 'http://localhost:8081/api',
    PREGNANCYPLANNER_URL: 'http://localhost:3002',
    GUARDIA_URL: 'about:blank',
    TURNERA_URL: 'about:blank'
  },
  showDevTools: true
}

const remoteDev = {
  endpoints: {
    API_URL: 'http://172.16.4.161:8081/api',
    GUARDIA_URL: 'http://172.16.4.161:5000',
    TURNERA_URL: 'http://172.16.4.161:4500'
  },
  showDevTools: true
}

const remoteTest = {
  endpoints: {
    API_URL: 'http://172.16.4.161:4081/api',
    PREGNANCYPLANNER_URL: 'http://VDESA-TOTEMSGH:7000',
    GUARDIA_URL: 'http://172.16.4.161:5000',
    TURNERA_URL: 'http://172.16.4.161:4500'
  },
  showDevTools: true
}

const remotePreProd = {
  endpoints: {
    API_URL: 'http://172.16.5.41:8081/api',
    PREGNANCYPLANNER_URL: 'http://VDESA-TOTEMSGH:7001',
    GUARDIA_URL: 'http://172.16.4.161:5000',
    TURNERA_URL: 'http://172.16.4.161:4500'
  },
  showDevTools: true
}

const remoteProd = {
  endpoints: {
    API_URL: 'https://www.miportalclinicas.com.ar/backend/api',
    PREGNANCYPLANNER_URL: 'http://www.pregnancyplanner.com.ar',
    GUARDIA_URL: 'http://guardiasmart.miportalclinicas.com.ar/',
    TURNERA_URL: 'http://turnera.miportalclinicas.com.ar/'
  },
  showDevTools: false
}

const config =
  process.env.REACT_APP_STAGE === 'prod'
    ? remoteProd
    : process.env.REACT_APP_STAGE === 'preprod'
    ? remotePreProd
    : process.env.REACT_APP_STAGE === 'remotetest'
    ? remoteTest
    : process.env.REACT_APP_STAGE === 'remotedev'
    ? remoteDev
    : devLocal

export default {
  ...config
}
