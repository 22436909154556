import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Modal from './Modal'

import { Button, MaterialIcon } from '../styled/generics'

const ModalMessage = props => (
  <Modal>
    <ModalContainer>
      {(props.iconCode) && ( <MaterialIcon name={props.iconCode} /> )}
      {props.message}
      <ActionContainer>
        <Button onClick={props.button.action}>{props.button.label}</Button>
      </ActionContainer>
    </ModalContainer>
  </Modal>
)

ModalMessage.propTypes = {
  iconCode: PropTypes.string,
  message: PropTypes.string.isRequired,
  button: PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
  })
}

export default ModalMessage

/* 
 * Styles
 */
const ModalContainer = styled.div`
display: flex;
  text-align: center;
  flex-direction: column;
  padding: 25px;
  font-size: 20px;

  color: ${props => props.theme.colors.green};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: ${props => props.theme.sizes.breakPoints.small};
  }

  > i {
    font-size: 45px;
    margin-bottom: 20px;
  }
`

const ActionContainer = styled.div`
margin: 30px auto 0 auto;
width: 50%;
align-items: center;
justify-content: center;

> button:first-child {
  margin-bottom: 10px;
}

@media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
  display: flex;

  > button {
    :first-child {
      margin: 0 20px 0 0;
    }

    :last-child {
      margin: 0 0 0 20px;
    }
  }
}
`
