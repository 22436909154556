import { CLINICS_ID } from './clinics'

export const WOMEN_CENTER = 'centro-mujer'
export const SPORT_MEDICINE = 'deportologia'
export const FACE_TO_FACE = 'presencial'


export const SPECIALTIES_CLINICS = {
    [WOMEN_CENTER]: [CLINICS_ID.DelSol],
    [SPORT_MEDICINE]: [CLINICS_ID.DelSol, CLINICS_ID.CentroRita],
}

export const SPECIALTIES_GROUPS = {
    [WOMEN_CENTER]: 5109830,
    [SPORT_MEDICINE]: 5109828
}