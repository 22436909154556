export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE'
export function addFlashMessage(payload) {
  return {
    type: ADD_FLASH_MESSAGE,
    payload
  }
}
export const CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE'
export function clearFlashMessage() {
  return {
    type: CLEAR_FLASH_MESSAGE
  }
}