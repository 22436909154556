import React from 'react'
import { setPatientDetails } from '../actions'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import LabHeader from './LabHeader'
import AlertMessage from './ui/AlertMessage'
import PatientDetails from './PatientDetails'
import { getClinicsDetailed } from '../api'
import to from 'await-catch'
import Layout from './shared/Layout'
import { CLINICS_ID } from '../constants'

class LabConfirmPatientData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clinics: [],
      showAlertMessage: false,
      allDataFetched: false
    }
  }

  handleNotCorrect = () => this.setState({ showAlertMessage: true })

  async componentDidMount(){
    const [err, { data: clinicsData} = {}] = await to(getClinicsDetailed())
    
    if (!err) {
      this.setState({ clinics: clinicsData.filter(
        clinic => clinic.id !== CLINICS_ID.CentroBazte && clinic.id !== CLINICS_ID.CentroRita
      ) 
    })
      this.setState({ allDataFetched: true })
    }
  }

  render() {
    return (
      <Layout topSpace>
      <React.Fragment>
        {this.state.allDataFetched && (
          <Content>
            {this.props.patient ? (
              <React.Fragment>
                <LabHeader
                  fullName={this.props.patient.nombreApellido}
                  healthInsurance={this.props.patient.coberturaMedica}
                  memberNumber={this.props.patient.nroAfiliado}
                />
                <PatientDetails
                  patient={this.props.patient}
                  handleNotCorrect={this.handleNotCorrect}
                  handleCorrect = "/inicio/laboratorios"
                  showButtons={!this.state.showAlertMessage}
                />
              </React.Fragment>
            ) : (
              <SectionName>La clínica seleccionada no tiene datos del paciente</SectionName>
            )}        
          </Content>
        )}
        {this.state.showAlertMessage && (
          <AlertContent>
            <AlertMessage type="error">
              Para modificar tus datos, por favor comunicate con la Central de Turnos o acercate a
              la Clínica.
            </AlertMessage>
          </AlertContent>
        )}
      </React.Fragment>
      </Layout>
    )
  }
}
const mapDispatchToProps = {setPatientDetails}
function mapStateToProps({ patient, auth }) {
  return { patient, auth }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabConfirmPatientData)

/*
 * Styles
 */
const SectionName = styled.h4`
  font-size: 26px;
  text-align: center;
`
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`
const AlertContent = styled.div`
  ${margin};
`
