import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { MaterialIcon, Checkbox } from '../styled/generics'
import { USERS_FILTERS, PATIENT_GROUP, REDUCED_USERS_FILTERS } from '../constants'

const initialValues = {
  roles: USERS_FILTERS.filter(item => item.key !== PATIENT_GROUP).map(item => item.key)
}

const validations = yup.object().shape({
  roles: yup.array()
})

class FormFilterUsers extends React.Component {
  constructor(props) {
    super(props)
    const activeRoles = USERS_FILTERS.map(filter => filter.key)
      .reduce((o, key) => ({
        ...o,
        [key]: key !== PATIENT_GROUP
      }), {})

    this.state = activeRoles
  }

  handleChange = (event) => {
    const { id, checked } = event.target
    let actualState = this.state
    actualState[id] = checked
    this.setState(actualState)
    const roles = Object.entries(this.state)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)

    this.props.onChange({ roles })
  }

  state = { showFilters: false }
  render() {
    return (
      <React.Fragment>
        <FiltersMobile onClick={() => this.setState({ showFilters: !this.state.showFilters })}>
          <span>Filtros</span>
          <MaterialIcon
            name={this.state.showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          />
        </FiltersMobile>
        <Formik
          onChange={values => {
            this.setState({ showFilters: false })
            this.props.onSubmit(values)
          }}
          initialValues={initialValues}
          validationSchema={validations}
          render={renderProps => {
            return (
              <Form onSubmit={renderProps.handleSubmit}
                showFilters={this.state.showFilters}
                onChange={this.handleChange}
              >
                {USERS_FILTERS.map((item, index) => (
                  <Checkbox
                    key={index}
                    groupName="roles"
                    item={item}
                  />
                ))}
              </Form>
            )
          }}
        />
      </React.Fragment>
    )
  }
}

export default FormFilterUsers

/**
 * Styles
 */
const FiltersMobile = styled.div`
  display: none;
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
  padding: 12px 30px 18px 30px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: ${props => (props.showFilters ? 'block' : 'none')};
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    > * {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) and (max-width: ${props => props.theme.sizes.breakPoints.medium}){
    overflow-x: scroll;
  }
`
