import React from 'react'
import to from 'await-catch'
import { connect } from 'react-redux'

import Layout from './shared/Layout'
import { setUserCreationData } from '../actions'
import FormUpdateUser from './FormUpdateUser'
import { getDetailedGroupRoles, getUserById } from '../api'
import {
  AUTOMANAGEMENTFUNDER_GROUP,
  FUNDER_GROUP,
  PATIENTADMIN,
  PREGNANCYPLANNER_GROUP
} from '../constants'

class UsersUpdate extends React.Component {
  state = { user: {}, roleGroups: [], roles: [], dataFetched: false, canEditRoles: true }

  async componentDidMount() {
    if (!this.props.userCreation.values) {
      this.props.history.push('/inicio/usuarios')
    } else {
      // Gestores de usuarios no deberian poder modificar roles
      if (
        this.props.auth.gruposRoles.includes(PREGNANCYPLANNER_GROUP) ||
        this.props.auth.roles.includes(PATIENTADMIN)
      ) {
        this.setState({ canEditRoles: false })
      }
      // Obtengo Usuario Completo + GruposRoles
      const [err, [{ data: userData } = {}, { data: rolesData } = {}]] = await to([
        getUserById(this.props.userCreation.values.idUsuario),
        getDetailedGroupRoles() //TODO: could be consumed from redux if prev. loaded in UsersList.js
      ])

      if (!err) {
        let roleGroups = rolesData

        // Autogestores solo deberian editar usuarios dentro del mismo grupo
        if (this.props.auth.gruposRoles.find(gr => gr === AUTOMANAGEMENTFUNDER_GROUP)) {
          roleGroups = roleGroups.filter(rg => rg.clave === FUNDER_GROUP)
        }

        // Asigno Usuario, GruposRoles y Roles a state
        const roles = roleGroups.map(rg => rg.roles).reduce((a, b) => a.concat(b), [])
        this.setState({ roleGroups: roleGroups, roles: roles, user: userData, dataFetched: true })
      }
    }
  }

  handleSubmit = async (formValues, metadatas) => {
    const values = {
      ...this.state.user,
      nombre: formValues.name,
      apellido: formValues.lastName,
      email: formValues.email,
      roles: this.state.roles.filter(role => formValues.roles.some(r => r === role.clave)),
      metadatoValores: metadatas.map(metadata => ({
        idUsuario: this.state.user.idUsuario,
        metadato: metadata,
        valor: formValues[metadata.clave]
      }))
    }

    this.props.setUserCreationData({
      isNewUser: false,
      values: values
    })

    this.props.history.push(`${this.props.match.path}/confirmar`)
  }

  render() {
    const subtitle = this.state.canEditRoles ? 'Asigne roles al usuario' : ''
    return (
      <Layout topSpace title="Modificar usuario" subTitle={subtitle}>
        {this.state.dataFetched && (
          <FormUpdateUser
            user={this.state.user}
            roleGroups={this.state.roleGroups}
            roles={this.state.roles}
            canEditRoles={this.state.canEditRoles}
            onSubmit={this.handleSubmit}
          />
        )}
      </Layout>
    )
  }
}

function mapStateToProps({ userCreation, auth }) {
  return { userCreation, auth }
}
const mapDispatchToProps = { setUserCreationData }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersUpdate)
//export default withUser(UsersUpdate)
