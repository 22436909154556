import React from 'react'
import styled from 'styled-components'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { clearAppointmentState } from '../actions'
import Layout from './shared/Layout'
import TabClinics from './TabClinics'
import ClinicGuardButtons from './ClinicGuardButtons'
import ClinicGuardCheckIn from './ClinicGuardCheckIn'
import ClinicGuardCheckInLine from './ClinicGuardCheckInLine'
import ClinicGuardCheckInCancelled from './ClinicGuardCheckInCancelled'
import ProgrammedGuard from './ProgrammedGuard'

class ClinicGuard extends React.Component {
  constructor(props) {
    super(props)

    let clinics = []
    if (props.clinicsTimes.length) {
      clinics = [
        {
          ...props.clinicsTimes[0].clinica,
          mensaje: props.clinicsTimes[0].rangoEspera,
          direccion: ''
        },
        {
          ...props.clinicsTimes[1].clinica,
          mensaje: props.clinicsTimes[1].rangoEspera,
          direccion: ''
        }
      ]
    }

    this.state = {
      listOfClinics: clinics,
      activeTab: null,
      activeProgrammedGuard: null
    }
  }

  componentWillUnmount() {
    this.props.clearAppointmentState()
  }

  onClickTab = clinicId => {
    // NOTE: Override url route
    if (this.props.match.url !== this.props.location.pathname) {
      this.props.history.push(this.props.match.url)
    }
    this.setState({ activeTab: clinicId })
  }

  onClickBack = () => this.setState({ activeTab: null })

  onClickButton = clinicId => this.setState({ activeProgrammedGuard: clinicId })

  render() {
    if (!this.state.listOfClinics.length) {
      return <Redirect to="/inicio/guardia-smart" />
    }

    return (
      <Layout topSpace title="Elegí la clínica" subTitle="¿A qué guardia querés ir?">
        <TabClinics
          listTabs={this.state.listOfClinics}
          onClickTab={this.onClickTab}
          activeTab={this.state.activeTab}
          onClickBack={this.onClickBack}
        />

        {this.state.activeTab && (
          <Container>
            <ClinicGuardButtons
              activeClinicId={this.state.activeTab}
              activeProgrammedClinicId={this.state.activeProgrammedGuard}
              onClickButton={this.onClickButton}
            />

            <Route
              exact
              path={`${this.props.match.url}/check-in`}
              render={() => <ClinicGuardCheckIn activeClinicId={this.state.activeTab} />}
            />
            <Route
              exact
              path={`${this.props.match.url}/check-in/fila`}
              render={() => <ClinicGuardCheckInLine activeClinicId={this.state.activeTab} />}
            />
            <Route
              exact
              path={`${this.props.match.url}/check-in/fila/cancelado`}
              render={() => <ClinicGuardCheckInCancelled />}
            />
            <Route
              path={`${this.props.match.url}/guardia-programada`}
              render={() => (
                <ProgrammedGuard
                  activeClinicId={this.state.activeProgrammedGuard}
                  key={this.state.activeProgrammedGuard}
                />
              )}
            />
          </Container>
        )}
      </Layout>
    )
  }
}

const mapDispatchToProps = { clearAppointmentState }
function mapStateToProps({ smartGuard: { clinicsTimes } }) {
  return { clinicsTimes }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClinicGuard)

/*
 * Styles
 */

const Container = styled.div`
  margin-top: 5px;
  padding: 30px 60px 50px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 20px 20px;
  }
`
