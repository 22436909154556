import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { LinkButton } from '../styled/generics'

class ClinicGuardButtons extends React.Component {
  static propTypes = { match: PropTypes.object.isRequired }

  checkInName = 'check-in'
  guardName = 'guardia-programada'

  //NOTE: showName not used anymore but left here in case it's needed later
  renderProgrammedClinicButton(clinic, showName) {
    return (
      <ActionButton
        active={`${this.props.location.pathname.includes(this.guardName) &&
          this.props.activeProgrammedClinicId === clinic.codigoEmpresa}`}
        to={`${this.props.match.url}/${this.guardName}`}
        onClick={() => this.props.onClickButton(clinic.codigoEmpresa)}
        disabled={!this.props.golEnabledSpecs.includes(this.props.speciality.idEspecialidad)}
      >
        Sacar turno {showName && ` en ${clinic.nombre}`}
      </ActionButton>
    )
  }

  render() {
    return (
      <Container>
        <ActionButton
          active={`${this.props.location.pathname.includes(this.checkInName)}`}
          to={`${this.props.match.url}/${this.checkInName}`}
        >
          Ir ahora
        </ActionButton>
        {this.props.clinicsTimes[0].clinica.codigoEmpresa === this.props.activeClinicId ? (
          this.renderProgrammedClinicButton(this.props.clinicsTimes[0].clinica, false)
        ) : (
          <React.Fragment>
            {this.renderProgrammedClinicButton(this.props.clinicsTimes[1].clinica, false)}
          </React.Fragment>
        )}
      </Container>
    )
  }
}
function mapStateToProps({ smartGuard: { clinicsTimes, speciality, golEnabledSpecs } }) {
  return { clinicsTimes, speciality, golEnabledSpecs }
}
export default withRouter(connect(mapStateToProps)(ClinicGuardButtons))

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const ActionButton = styled(LinkButton)`
  color: ${props => props.theme.colors.green};
  flex: 1;
  font-size: 14px;
  max-width: 178px;
  height: 60px;
  background: white;
  border: none;
  text-align: center;

  :hover {
    background: white;
  }

  :not(:first-child) {
    margin-left: 5px;
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    font-size: 11px;
  }

  ${props =>
    props.active === 'true' &&
    css`
      box-shadow: inset 3px 3px 0 0 ${props.theme.colors.green},
        inset -3px -3px 0 0 ${props.theme.colors.green};
      font-size: 16px;
      font-weight: 600;
    `};

  ${props =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.grey};
      color: ${props.theme.colors.greyFour};
      pointer-events: none;
      :hover {
        background-color: ${props.theme.colors.grey};
      }
    `};
`
