export const LOGIN = 'LOGIN'
export function login(payload) {
  return {
    type: LOGIN,
    payload
  }
}

export const LOGOUT = 'LOGOUT'
export function logout() {
  return {
    type: LOGOUT
  }
}