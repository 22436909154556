import {
  SET_ACTIVE_CLINIC,
  SET_APPOINTMENT_DETAILS,
  CLEAR_APPOINTMENT_STATE,
  SET_APPOINTMENT_STAGE,
  SET_APPOINTMENT_IS_ONLINE,
  TOGGLE_APPOINTMENT_MESSAGE,
  TOGGLE_MEGOLI_APPOINTMENT_MESSAGE,
  CLEAR_SMART_GUARD_STATE,
  SET_PATIENT_AT_CLINIC_DETAILS
} from '../actions'

export const appointmentDefaults = {
  isOnline: false,
  activeClinic: null,
  patientAtClinicDetails: null,
  appointmentDetails: null,
  appointmentMessageDismissed: false,
  megoliAppointmentMessageDismissed: false,
  appointmentStage: 0
}
export const appointmentReducer = function (state = appointmentDefaults, action) {
  switch (action.type) {
    case SET_ACTIVE_CLINIC:
      return {
        ...state,
        activeClinic: action.payload
      }
    case SET_PATIENT_AT_CLINIC_DETAILS:
      return {
        ...state,
        patientAtClinicDetails: action.payload
      }
    case SET_APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentDetails: action.payload
      }
    case SET_APPOINTMENT_STAGE:
      return {
        ...state,
        appointmentStage: action.payload
      }
    case SET_APPOINTMENT_IS_ONLINE:
      return {
        ...state,
        isOnline: action.payload
      }
    case TOGGLE_APPOINTMENT_MESSAGE:
      return {
        ...state,
        appointmentMessageDismissed: !state.appointmentMessageDismissed
      }
    case TOGGLE_MEGOLI_APPOINTMENT_MESSAGE:
      return {
        ...state,
        megoliAppointmentMessageDismissed: !state.megoliAppointmentMessageDismissed
      }
    case CLEAR_APPOINTMENT_STATE:
    case CLEAR_SMART_GUARD_STATE:
      return {
        ...appointmentDefaults,
        appointmentMessageDismissed: state.appointmentMessageDismissed,
        megoliAppointmentMessageDismissed: state.megoliAppointmentMessageDismissed
      }
    default:
      return state
  }
}