import React from 'react'
import styled from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'

import Layout from './shared/Layout'
import {
  USERS_ACTION_PASSWORD,
  USERS_ACTION_DELETE,
  ADMINISTRATOR_GROUP,
  PAGINATION_PARAMETERS,
  AUTOMANAGEMENTFUNDER_GROUP,
  USERS_FILTERS,
  REDUCED_USERS_FILTERS,
  PATIENT_GROUP,
  FUNDER_GROUP,
  PREGNANCYPLANNER_GROUP,
  PATIENTADMIN,
  SEARCH_DELAY_MS
} from '../constants'
import { setUserCreationData } from '../actions'
import Table from './Table'
import { LinkButton, MobileFullWidth, MaterialIcon } from '../styled/generics'
import { getUsers, getDetailedGroupRoles, blankPassword, setPP, deleteUser } from '../api'
import FormFilterUsers from './FormFilterUsers'
import ModalMessage from './ModalMessage'
import ModalConfirmation from './ModalConfirmation'
import ToggleSwitch from './ToggleSwitch'

class UsersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      typingTimer: null,
      request: {
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas
      },
      filters: [],
      searchedAndNoData: false,
      usersSearched: false,
      companiesCuits: [],
      showConfirmModal: false,
      showSuccessModal: false,
      userRow: {},
      userAction: '',
      toBlankMail: '',
      blankResponse: ''
    }

    if (props.auth.gruposRoles.includes(AUTOMANAGEMENTFUNDER_GROUP)) {
      const cuit = this.props.auth.metadatos.find(metadata => metadata.metadato.clave === 'CUIT')
        .valor
      const metadatos = [{ clave: 'CUIT', valor: cuit }]

      this.state.request.metadatos = metadatos
    }
  }

  async componentDidMount() {
    //TODO: the data for DetailedGruposRoles is used in both UsersNew.js and UsersUpdate.js
    //      it may be better to load/use this data with redux to avoid refeteching
    const [err, { data } = {}] = await to(getDetailedGroupRoles())
    if (!err) {
      this.setState({
        companiesCuits: data
          .find(gr => gr.clave === FUNDER_GROUP)
          .metadatos.find(m => m.clave === 'CUIT').opciones
      })
    }

    if (
      this.props.auth.gruposRoles.includes(PREGNANCYPLANNER_GROUP) ||
      this.props.auth.roles.includes(PATIENTADMIN)
    ) {
      this.handleSubmit({
        roles: REDUCED_USERS_FILTERS.map(item => item.key)
      })
    } else {
      this.handleSubmit({
        roles: USERS_FILTERS.filter(item => item.key !== PATIENT_GROUP).map(item => item.key)
      })
    }
  }

  handleSubmit = async (formValues = undefined) => {
    let form = {}
    if (formValues) {
      form = {
        ...this.state.request,
        gruposRoles: formValues.roles,
        items: []
      }
    } else {
      form = {
        ...this.state.request
      }
    }

    const [err, { data } = {}] = await to(getUsers(form))
    if (!err) {
      this.setState({
        usersSearched: true
      })
      if (data.hasOwnProperty('items')) {
        this.setState({
          request: { ...form, ...data },
          searchedAndNoData: false
        })
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleSearch = KeyboardEvent => {
    clearTimeout(this.state.typingTimer)
    const inputValue = KeyboardEvent.target.value

    this.setState({
      request: {
        ...this.state.request,
        pagina: 1,
        items: [],
        buscarUsuario: { EMAIL: inputValue.trim() }
      },
      typingTimer: setTimeout(this.handleSubmit, SEARCH_DELAY_MS)
    })
  }

  setInitialPage = () => {
    const newState = this.state
    newState.request.pagina = 1
    this.setState(newState)
  }

  getCompanyByCuit = cuit => {
    return this.state.companiesCuits.find(cc => cc.id === cuit)
      ? this.state.companiesCuits.find(cc => cc.id === cuit).valueText
      : cuit
  }

  toggleConfirmModal = () => this.setState({ showConfirmModal: !this.state.showConfirmModal })

  toggleSuccessModal = () => this.setState({ showSuccessModal: !this.state.showSuccessModal })

  getActionHandler = () => {
    switch (this.state.userAction) {
      case USERS_ACTION_DELETE:
        return this.deleteUser
      case USERS_ACTION_PASSWORD:
        return this.blankPassword
      default:
        return () => console.log('Metodo no especificado.')
    }
  }

  getConfirmMessage = () => {
    if (!this.state.userRow) {
      return
    }
    switch (this.state.userAction) {
      case USERS_ACTION_DELETE:
        return `¿Está seguro que desea borrar el usuario '${this.state.userRow.email}'?`
      case USERS_ACTION_PASSWORD:
        return `¿Está seguro que desea blanquear la contraseña para el usuario '${this.state.userRow.email
          }'?`
      default:
        return ''
    }
  }

  getSuccessMessage = () => {
    switch (this.state.userAction) {
      case USERS_ACTION_DELETE:
        return 'El usuario fue dado de baja correctamente.'
      case USERS_ACTION_PASSWORD:
        return this.state.blankResponse
      default:
        return ''
    }
  }

  showConfirmModal = (e, row, action) => {
    this.setState(
      {
        userRow: { ...row },
        userAction: action
      },
      () => {
        this.toggleConfirmModal()
      }
    )
  }

  blankPassword = async () => {
    this.toggleConfirmModal()

    const [err, { data } = {}] = await to(blankPassword(this.state.userRow.email))
    if (!err) {
      this.setState({ blankResponse: data }, () => {
        this.toggleSuccessModal()
      })
    }
  }

  deleteUser = async () => {
    this.toggleConfirmModal()
    const [err] = await to(deleteUser(this.state.userRow.idUsuario))
    if (!err) {
      this.toggleSuccessModal()
      this.handleSubmit()
    }
  }

  handleChange = async (e, row) => {
    row.pp = !row.pp
    const [err] = await to(setPP(row))
    if (err) {
      //pseudo rollback
      row.pp = !row.pp
    }

    this.forceUpdate()
  }

  editUser = row => {
    this.props.setUserCreationData({
      isNewUser: false,
      values: { idUsuario: row.idUsuario }
    })

    this.props.history.push(`${this.props.match.path}/modificar`)
  }

  roleBadges = user => {
    const uniqueGroups = [...new Set(user.gruposRoles.map(role => role.clave))]
    return (
      <BadgeContainer>
        {uniqueGroups.map((gr, index) => (
          <Badge key={index}>{gr}</Badge>
        ))}
      </BadgeContainer>
    )
  }

  render() {
    //Start of Table Definition
    let columns = [
      { title: 'Nombre', index: 'nombre', size: 'minmax(60px,1fr)' },
      { title: 'Apellido', index: 'apellido', size: 'minmax(60px,1fr)' },
      { title: 'Email', index: 'email', size: 'minmax(250px,1fr)' },
      {
        title: 'Roles',
        index: 'roles',
        render: row => this.roleBadges(row),
        size: 'minmax(200px,1fr)'
      },
      {
        title: 'Empresa',
        index: 'empresa',
        render: row => {
          return (
            <React.Fragment>
              {row.metadatoValores.find(metadata => metadata.metadato.clave === 'CUIT')
                ? this.getCompanyByCuit(
                  row.metadatoValores.find(metadata => metadata.metadato.clave === 'CUIT').valor
                )
                : null}
            </React.Fragment>
          )
        },
        size: 'minmax(150px,1fr)'
      },
      {
        title: 'Acción', // acciones
        render: row => {
          return (
            <ActionContainer>
              <ActionIcon onClick={e => this.showConfirmModal(e, row, USERS_ACTION_PASSWORD)}>
                <MaterialIcon name="password" />
                <span>Blanquear</span>
              </ActionIcon>

              {this.props.auth.gruposRoles.includes(ADMINISTRATOR_GROUP) && (
                <React.Fragment>
                  <ActionIcon onClick={e => this.editUser(row)}>
                    <MaterialIcon name="edit" />
                    <span>Editar</span>
                  </ActionIcon>

                  <ActionIcon onClick={e => this.showConfirmModal(e, row, USERS_ACTION_DELETE)}>
                    <MaterialIcon name="delete" />
                    <span>Borrar</span>
                  </ActionIcon>
                </React.Fragment>
              )}
            </ActionContainer>
          )
        },
        size: 'minmax(200px,1fr)'
      },
      {
        title: 'PP',
        render: row => {
          return <ToggleSwitch handleChange={e => this.handleChange(e, row)} checked={row.pp} />
        },
        size: 'minmax(100px,1fr)'
      }
    ]

    if (!this.props.auth.gruposRoles.includes(PREGNANCYPLANNER_GROUP)) {
      columns = columns.filter(c => c.title !== 'PP')
    }
    //End of Table Definition

    return (
      <Layout
        extended
        topSpace
        title="Listado de Usuarios"
        subTitle={
          <div>
            <SearchBox
              type="search"
              placeholder="Buscá por nombre de usuario o email"
              id="search"
              onChange={this.handleSearch}
            />
          </div>
        }
      >
        {!(
          this.props.auth.gruposRoles.includes(PREGNANCYPLANNER_GROUP) ||
          this.props.auth.roles.includes(PATIENTADMIN)
        ) && (
            <React.Fragment>
              <Subtitle>Filtrar por tipo de usuario</Subtitle>
              <FiltersContainer>
                <FormFilterUsers
                  onChange={this.handleSubmit}
                />
              </FiltersContainer>
            </React.Fragment>
          )}
        <TableContainer>
          <MobileFullWidth>
            <Table
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
            >
              {!this.props.auth.roles.includes(PATIENTADMIN) && (
                <React.Fragment>
                  <SizedButton to="/inicio/usuarios/nuevo">Crear nuevo usuario</SizedButton>
                  <MobileButton green={1} outline={1} to="/inicio/usuarios/nuevo">
                    Nuevo Usuario
                  </MobileButton>
                </React.Fragment>
              )}
            </Table>
          </MobileFullWidth>
        </TableContainer>

        {this.state.showConfirmModal && (
          <ModalConfirmation
            iconCode="help"
            message={this.getConfirmMessage()}
            buttonCancel={{ action: this.toggleConfirmModal, label: 'Cancelar' }}
            buttonConfirm={{ action: this.getActionHandler(), label: 'Aceptar' }}
          />
        )}

        {this.state.showSuccessModal && (
          <ModalMessage
            iconCode="check_circle"
            message={this.getSuccessMessage()}
            button={{ label: 'Volver', action: this.toggleSuccessModal }}
          />
        )}
      </Layout>
    )
  }
}

function mapStateToProps({ userCreation, auth }) {
  return { userCreation, auth }
}
const mapDispatchToProps = { setUserCreationData }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList)

/*
 * Styles
 */
const FiltersContainer = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.greyFive};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) and (max-width: ${props =>
    props.theme.sizes.breakPoints.medium}) {
    height: 104px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    min-height: 55px;
    display: block;
    height: auto;
  }
`

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TableContainer = styled.div`
  margin: 0 20px;
`
const SearchBox = styled.input`
  height: 50px;
  margin: 50px 0 0 0;
  padding: 0 5%;
  width: 100%;
  border: none;
  background: ${props => props.theme.colors.greyFive};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 26px 0 10px 0;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.medium}) {
    text-align: center;
  }
`
const Subtitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.warmGreyTwo};
`

const SizedButton = styled(LinkButton)`
  width: 230px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: none;
  }
`

const MobileButton = styled(LinkButton)`
  display: none;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    width: 142px;
    height: 30px;
  }
`

const Badge = styled.span`
  background: ${props => props.theme.colors.greyFive};
  color: ${props => props.theme.colors.greyTwo};
  border: 1px solid ${props => props.theme.colors.grey};
  font-size: 10px;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
`

const ActionContainer = styled.div`
  width: 100%
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const ActionIcon = styled.a`
  display: flex;
  width: auto;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.green};
`
