import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const ProgrammedGuardDayTab = props => (
  <Container>
    {props.listTabs &&
      props.listTabs.map(tab => (
        <Tab
          key={tab.id}
          active={tab.id.getTime() === props.activeTab.getTime()}
          onClick={() => props.onClick(tab.id)}
        >
          <h4>{tab.name}</h4>
        </Tab>
      ))}
  </Container>
)

ProgrammedGuardDayTab.propTypes = {
  listTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.instanceOf(Date),
      name: PropTypes.string
    })
  ).isRequired,
  activeTab: PropTypes.instanceOf(Date),
  onClick: PropTypes.func
}

ProgrammedGuardDayTab.defaultProps = { activeTab: 1 }

export default ProgrammedGuardDayTab

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  width: 200px;
  margin: 10px auto 0 auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 164px;
  }
`
const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.colors.warmGrey};
  border-bottom: 3px solid ${props => props.theme.colors.warmGrey};
  padding-bottom: 10px;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.colors.green};
      border-bottom: 6px solid ${props => props.theme.colors.green};
    `} &:not(:first-child) {
    margin-left: 6px;
  }
  h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding-bottom: 2px;
  }
`
