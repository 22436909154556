//Appointment Stage
export * from './appointmentStages'

//Users Filters
export * from './usersFilters'

//clinics
export * from './clinics'

//delivery status
export * from './deliveryStatus'

//date formats
export * from './dateFormats'

//pagination parameters
export * from './paginationParameters'

//actions
export * from './actions'

// roles
export * from './roles'

//permits
export * from './permits'

//specialties group
export * from './specialtiesGroups'

// routes by rol group
export * from './routesByRolGroup'

//Especialidades Megoli Guardia
export * from './megoliSpecialties'

export const PERSONAL_DATA_TEXT =
  'El titular de los datos personales no se encuentra obligado a proporcionar datos sensibles. Asimismo, el titular se responsabiliza por la exactitud de los datos proporcionados. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismo en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25326.\n La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control de la Ley N° 25326, tiene la atricución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. www.sssalud.gov.ar, 0800-222-72583.\n RNEMP: 1336.'

export const SEARCH_DELAY_MS = 500

//Mensajes
export const DISABLED_FEATURE_MESSAGE = 'Momentáneamente \n no disponible.'