import React from 'react'
import to from 'await-catch'

import { getRoles } from '../api'
import { PAGINATION_PARAMETERS } from '../constants'
import Layout from './shared/Layout'
import Table from './Table'
import { MobileFullWidth } from '../styled/generics'

class RolesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      request: {
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas
      },
      searchedAndNoData: false
    }
  }

  async componentDidMount() {
    this.handleSubmit()
  }

  handleSubmit = async () => {
    const params = { ...this.state.request }
    const [err, { data } = {}] = await to(getRoles(params))
    if (!err) {
      if (data.hasOwnProperty('items')) {
        this.setState({
          request: { ...params, ...data },
          searchedAndNoData: false
        })
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  render() {
    const columns = [
      { title: 'Nombre', index: 'nombre', size: 'minmax(100px,1fr)' },
      { title: 'Descripción', index: 'descripcion', size: 'minmax(300px,2fr)' },
      { title: 'Tipo', index: 'clave', size: 'minmax(200px,1fr)' }
    ]
    return (
      <Layout
        topSpace
        title="Roles"
        subTitle="Desde aquí podrás ver todos los Roles vigentes en el sistema."
      >
        <MobileFullWidth>
          <Table
            columns={columns}
            rows={this.state.request.items}
            totalPages={this.state.request.totalPaginas}
            itemsPerPage={this.state.request.itemsPorPagina}
            onClickPage={this.handlePagination}
            searchedAndNoData={this.state.searchedAndNoData}
            pagina={this.state.request.pagina}
            handleSort={this.handleSort}
          />
        </MobileFullWidth>
      </Layout>
    )
  }
}

export default RolesList
