import React from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'

import Layout from './shared/Layout'
import FormFilterHospitalizedPatients from './FormFilterHospitalizedPatients'
import { getClinicsDetailed, getPatients, downloadPatientList } from '../api'
import { PAGINATION_PARAMETERS, CLINICS_ID } from '../constants'
import withUser from '../hoc/withUser'
import Table from './Table'
import { Button, MaterialIcon, MobileFullWidth } from '../styled/generics'

class HospitalizedPatients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clinics: [],
      request: {
        cuit: this.props.auth.metadatos.find(metadata => metadata.metadato.clave === 'CUIT').valor,
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas
      },
      searchedAndNoData: false,
      patientsSearched: false
    }
  }

  async componentDidMount() {
    const [err, { data } = {}] = await to(getClinicsDetailed())
    if (!err) {
      this.setState({ clinics: data.filter(clinic => 
        clinic.id !== CLINICS_ID.CentroBazte && clinic.id !== CLINICS_ID.CentroRita) 
      })
    }
  }

  handleSubmit = async (formValues = undefined) => {
    var form = {}
    if (formValues) {
      form = {
        ...this.state.request,
        fechaDesde: formValues.startDate.format('DD/MM/YYYY'),
        fechaHasta: formValues.endDate.format('DD/MM/YYYY'),
        clinica: formValues.clinic,
        items: []
      }
    } else {
      form = {
        ...this.state.request
      }
    }

    const [err, { data } = {}] = await to(getPatients(form))
    if (!err) {
      this.setState({
        patientsSearched: true
      })
      if (data.hasOwnProperty('items')) {
        this.setState({
          request: { ...form, ...data },
          searchedAndNoData: false
        })
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  setInitialPage = () => {
    const newState = this.state
    newState.request.pagina = 1
    this.setState(newState)
  }

  downloadPatientList = async () => {
    const request = {
      desde: this.state.request.fechaDesde,
      hasta: this.state.request.fechaHasta,
      clinica: this.state.request.clinica,
      cuit: this.state.request.cuit
    }

    const [err, { data } = {}] = await to(downloadPatientList(request))
    if (!err) {
      const blob = new Blob([data])
      const filename = `listado_paciente_${new Date().getTime()}.xls`

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      }
    }
  }

  render() {
    const columns = [
      { title: 'N° Afiliado', index: 'numAfiliado', size: 'minmax(200px,1fr)' },
      { title: 'Paciente', index: 'nombre', size: 'minmax(200px,1fr)' },
      { title: 'Ingreso', index: 'fechaIngreso', size: 'minmax(200px,1fr)' },
      { title: 'Egreso', index: 'fechaEgreso', size: 'minmax(200px,1fr)' },
      { title: 'Clínica', index: 'clinica', size: 'minmax(200px,1fr)' },
      { title: 'Área', index: 'area', size: 'minmax(200px,1fr)' },
      { title: 'Habitación', index: 'habitacion', size: 'minmax(200px,1fr)' },
      { title: 'Cama', index: 'cama', size: 'minmax(200px,1fr)' },
      { title: 'Condición de Ingreso', index: 'condicionIngreso', size: 'minmax(200px,1fr)' },
      { title: 'Diagnóstico de Ingreso', index: 'diagnosticoIngreso', size: 'minmax(200px,1fr)' }      
    ]

    return (
      <Layout topSpace title="Listado de pacientes">
        <FiltersContainer>
          <FormFilterHospitalizedPatients
            clinics={this.state.clinics}
            onSubmit={values => {
              this.setInitialPage()
              this.handleSubmit(values)
            }}
          />
        </FiltersContainer>
        {this.state.patientsSearched && (
          <MobileFullWidth>
            <Table
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
            >
              <SizedButton
                outline
                green
                onClick={this.downloadPatientList}
                disabled={!this.state.request.items.length}
              >
                <MaterialIcon name="get_app" />
                Exportar Excel
              </SizedButton>
            </Table>
          </MobileFullWidth>
        )}
      </Layout>
    )
  }
}

export default withUser(HospitalizedPatients)

/**
 * Styles
 */
const FiltersContainer = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
  background: ${props => props.theme.colors.greyFive};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    min-height: 55px;
    height: auto;
  }
`
const SizedButton = styled(Button)`
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;
  i {
    font-size: 18px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 142px;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${props => props.theme.colors.greyTwo};
      &:hover {
        color: ${props => props.theme.colors.greyTwo};
      }
    `};
`
