import { API } from './instances'

export const searchAuditLogs = async data => {
    const url = `/Auditoria/Buscar`
    return await API.post(url, data)
}

export const getAuditActions = async () => {
    const url = `/Auditoria/Acciones`
    return await API.get(url)
}

export const downloadLogList = async data => {
    const url = `/Auditoria/Exportar`
    return await API.post(url, data)
}