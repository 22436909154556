import React from 'react'
import styled from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'

import { login as loginAction } from '../actions'
import Layout from './shared/Layout'
import FormPerfil from './FormPerfil'
import ModalMessage from './ModalMessage'

import { changeEmail, updateUserName } from '../api'

class Perfil extends React.Component {
  state = { showModal: false }

  handleSubmit = async values => {
    if (!this.isSameEmail(values.email)) {
      const [err] = await to(changeEmail(this.props.auth.idUsuario, this.props.auth.email, values.email))

      if (!err) {
        this.toggleModal()
      }
    }

    if (!this.isSameName(values.name, values.lastName)){
      var params = {
        id: this.props.auth.idUsuario,
        nombre: values.name,
        apellido: values.lastName
      }

      const [err] = await to(updateUserName(params))

      if (!err)
      {
        // actualizo la informacion de login
        // TODO: ver si vale la pena crear un action nuevo solo para esto?
        this.props.loginAction(
          {
            ...this.props.auth,
            nombre: params.nombre, 
            apellido: params.apellido
          }
        )
      }
    }
  }

  toggleModal = () => this.setState({ showModal: !this.state.showModal })

  isSameEmail = (newEmail) => {
    return this.props.auth.email.toUpperCase() === newEmail.toUpperCase()
  }

  isSameName = (newFirstName, newLastName) => {
    const isSameFirstName = this.props.auth.nombre.toUpperCase() === newFirstName.toUpperCase();
    const isSameLastName = this.props.auth.apellido.toUpperCase() === newLastName.toUpperCase();

    return isSameFirstName && isSameLastName
  }

  render() {
    return (
      <Layout topSpace title="Perfil" subTitle="Desde aquí podés realizar cambios en tu perfil">
        <Container>
          {this.state.showModal && (
            <ModalMessage
              message="Se ha enviado mail de confirmación a su nuevo email"
              button={{
                label: "Volver",
                action: this.toggleModal
              }}
            />
          )}
          <FormPerfil 
            onSubmit={this.handleSubmit} 
            defaults={this.props.auth}
            isSameEmail={this.isSameEmail}
            isSameName={this.isSameName}
          />
        </Container>
      </Layout>
    )
  }
}

const mapDispatchToProps = { loginAction }
function mapStateToProps({ auth }) {
  return { auth }
}
export default connect(mapStateToProps, mapDispatchToProps)(Perfil)
//export default withUser(Perfil)

const Container = styled.div`
  padding: 20px;
  background: ${props => props.theme.colors.cloudyBlueThree};
`
