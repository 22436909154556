import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { store } from './store'

import Routes from './Routes'
import { unregister } from './registerServiceWorker'
import ApiErrorMessage from './components/ui/ApiErrorMessage'
import Loader from './components/Loader'

import theme from './styled/theme'
import './styled/globals'
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <ApiErrorMessage />
        <Loader />
        <Routes />
      </React.Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

unregister()
