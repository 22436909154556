import React from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import moment from 'moment'
import withUser from '../hoc/withUser'

import Layout from './shared/Layout'
import { PAGINATION_PARAMETERS, LOG_ACTION_LOGIN, SEARCH_DELAY_MS } from '../constants'
import { searchAuditLogs, getAuditActions, downloadLogList } from '../api'
import Table from './Table'
import { Button, MobileFullWidth, MaterialIcon } from '../styled/generics'
import FormFilterLogs from './FormFilterLogs'

class LogsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      logActions: null,
      typingTimer: null,
      request: {
        fechaDesde: moment().subtract(3, 'days'),
        fechaHasta: moment(),
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas
      },
      filters: [],
      searchedAndNoData: false,
      logsSearched: false
    }
  }

  async componentDidMount() {
    // fetching for "log actions"...
    const [err, { data } = {}] = await to(getAuditActions())
    if (!err) {
      const actions = data.map(action => ({ key: action.id, name: action.nombre }))
      this.setState({ logActions: actions })

      this.handleSubmit({
        actions: actions.filter(item => item.key !== LOG_ACTION_LOGIN).map(item => item.key),
        startDate: this.state.request.fechaDesde,
        endDate: this.state.request.fechaHasta
      })
    }
  }

  handleSubmit = async (formValues = undefined) => {
    var form = {}
    if (formValues) {
      form = {
        ...this.state.request,
        acciones: formValues.actions,
        fechaDesde: formValues.startDate,
        fechaHasta: formValues.endDate,
        items: []
      }
    } else {
      form = {
        ...this.state.request
      }
    }

    const [err, { data } = {}] = await to(searchAuditLogs(form))
    if (!err) {
      this.setState({
        logsSearched: true
      })
      if (data.hasOwnProperty('items')) {
        this.setState({
          request: { ...form, ...data },
          searchedAndNoData: false
        })
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleSearch = KeyboardEvent => {
    clearTimeout(this.state.typingTimer)
    const inputValue = KeyboardEvent.target.value
    this.setState({
      request: {
        ...this.state.request,
        pagina: 1,
        items: [],
        busqueda: inputValue.trim()
      },
      typingTimer: setTimeout(this.handleSubmit, SEARCH_DELAY_MS)
    })
  }

  setInitialPage = () => {
    const newState = this.state
    newState.request.pagina = 1
    this.setState(newState)
  }

  renderLogData = log => {
    var logObject
    try {
      logObject = JSON.parse(log)
    } catch (e) {
      return <div>{log}</div>
    }

    debugger;

    return (
      <div>
        <LogList>
          {Object.keys(logObject).map((key, index) => {
            if (!logObject[key]){
              return <React.Fragment/>;
            }

            return (
              <LogItem key={index}>
                <strong>{key}</strong>
                {`: ${logObject[key].toString().trim().substring(0, 255)}`}
              </LogItem>
            )
          })}
        </LogList>
      </div>
    )
  }

  downloadLogList = async () => {
    const request = {
      acciones: this.state.request.acciones,
      busqueda: this.state.request.busqueda,
      fechaDesde: this.state.request.fechaDesde,
      fechaHasta: this.state.request.fechaHasta
    }

    const [err, { data } = {}] = await to(downloadLogList(request))
    if (!err) {
      const blob = new Blob([data])
      const filename = `MiPortal_Logs_${new Date().getTime()}.xls`

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      }
    }
  }

  render() {
    // Start of Table Definition
    let columns = [
      {
        title: 'Usuario',
        render: row => {
          return <React.Fragment>{row.nombreApellidoUsuario}</React.Fragment>
        },
        size: 'minmax(200px, 1fr)'
      },
      {
        title: 'Email',
        render: row => {
          return <React.Fragment>{row.emailUsuario}</React.Fragment>
        },
        size: 'minmax(225px, 1fr)'
      },
      { title: 'Fecha', index: 'fechaHora', size: 'minmax(125px, 1fr)' },
      {
        title: 'Acción',
        render: row => {
          return <React.Fragment>{row.nombreAccion}</React.Fragment>
        },
        size: 'minmax(210px, 1fr)'
      },
      {
        title: 'Log',
        render: row => {
          return this.renderLogData(row.descripcion)
        },
        size: 'minmax(1250px, 1fr)'
      }
    ]
    // End of Table Definition

    return (
      <Layout
        extended
        topSpace
        title="Reporte de Accesos"
        subTitle={
          <div>
            <SearchBox
              type="search"
              placeholder="Buscá por email, nombre de usuario o profesional"
              id="search"
              onChange={this.handleSearch}
            />
          </div>
        }
      >
        <FiltersContainer>
          {!!this.state.logActions && (
            <React.Fragment>
              <FormFilterLogs
                actions={this.state.logActions}
                defaultFrom={this.state.request.fechaDesde}
                defaultTo={this.state.request.fechaHasta}
                onSubmit={values => {
                  this.setInitialPage()
                  this.handleSubmit(values)
                }}
              />
            </React.Fragment>
          )}
        </FiltersContainer>

        <TableContainer>
          <MobileFullWidth>
            <Table
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
            >
              <SizedButton
                outline
                green
                onClick={this.downloadLogList}
                disabled={!this.state.request.items.length}
              >
                <MaterialIcon name="get_app" />
                Exportar Excel
              </SizedButton>
            </Table>
          </MobileFullWidth>
        </TableContainer>
      </Layout>
    )
  }
}

export default withUser(LogsList)

/*
 * Styles
 */
const FiltersContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.greyFive};
  min-height: 55px;
  display: block;
  height: auto;
`

const LogList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`
const LogItem = styled.li``

const TableContainer = styled.div``

const SearchBox = styled.input`
  height: 50px;
  margin: 50px 0 0 0;
  padding: 0 5%;
  width: 100%;
  border: none;
  background: ${props => props.theme.colors.greyFive};
  text-align: center;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 26px 0 10px 0;
    text-align: left;
  }
`

const SizedButton = styled(Button)`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 20px;
  font-weight: 500;
  i {
    font-size: 14px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 142px;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${props => props.theme.colors.greyTwo};
      &:hover {
        color: ${props => props.theme.colors.greyTwo};
      }
    `};
`
