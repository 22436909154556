import React from 'react'
import styled, { css } from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Input, InputPassword, Button, ErrorField } from '../styled/generics'
import SANTA_ISABEL from '../images/santa-isabel-login.svg'
import BAZTERRICA from '../images/bazterrica-login.svg'
import DEL_SOL from '../images/del-sol-login.svg'
import CENTRO_BAZTERRICA from '../images/centro-bazterrica-login.svg'
import SANTA_RITA from '../images/santa-rita-login.svg'
import envConfig from '../config'
import features from '../features'
import { DISABLED_FEATURE_MESSAGE } from '../constants'

const initialValues = { email: '', password: '' }
const validations = yup.object().shape({
  email: yup
    .string()
    .email('Email no válido')
    .required('Campo requerido'),
  password: yup.string().required('Campo requerido')
})

const FormLogin = props => (
  <Container>
    <ClinicLogoContainer>
      <ClinicLogo src={SANTA_ISABEL} alt="Clínica Santa Isabel" />
      <ClinicLogo src={DEL_SOL} alt="Clínica del Sol" />
      <ClinicLogo src={BAZTERRICA} alt="Clínica Bazterrica" />
      <ClinicLogo src={CENTRO_BAZTERRICA} alt="Centro Médico Bazterrica" />
      <ClinicLogo src={SANTA_RITA} alt="Centro Médico Santa Rita" />
    </ClinicLogoContainer>

    <FormContainer>
      <Formik
        onSubmit={props.handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
        render={formik => (
          <form onSubmit={formik.handleSubmit}>
            <Input
              type="email"
              name="email"
              value={formik.values.email}
              placeholder="Email"
              warning={formik.errors.email && formik.touched.email ? 1 : 0}
            />
            {formik.touched.email &&
              formik.errors.email && <ErrorField>{formik.errors.email}</ErrorField>}

            <InputPassword
              type={props.seePassWord ? 'text' : 'password'}
              name="password"
              value={formik.values.password}
              placeholder="Contraseña"
              iconName={props.seePassWord ? 'visibility' : 'visibility_off'}
              warning={formik.errors.password && formik.touched.password ? 1 : 0}
              handleSeePaswordChange={props.handleSeePaswordChange}
            />
            {formik.touched.password &&
              formik.errors.password && <ErrorField>{formik.errors.password}</ErrorField>}

            <RestorePassword href="/recuperar-cuenta">Olvidé mi contraseña</RestorePassword>
            <Button type="submit">Ingresar</Button>
            <SignUp>
              ¿Primera vez qué ingresás?
              <InlineLink to="/registro">Registrate acá</InlineLink>
            </SignUp>
          </form>
        )}
      />
      <QuickAccess>
        <strong>Accesos Rápidos</strong>
        <InvertButton href={envConfig.endpoints.GUARDIA_URL} target="_blank" disabled={features.disableClinicGuard}>
          <strong>
            <span>{features.disableClinicGuard ? DISABLED_FEATURE_MESSAGE : 'Ir a la guardia'}</span>
          </strong>
        </InvertButton>
        <InvertButton href={envConfig.endpoints.TURNERA_URL} target="_blank">
          <strong>
            <span>Pedir turno</span>
          </strong>
        </InvertButton>
      </QuickAccess>
    </FormContainer>
  </Container>
)

FormLogin.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleSeePaswordChange: PropTypes.func.isRequired
}

export default FormLogin

/*
 * Styles
 */
const Container = styled.div`
  max-width: 400px;
  text-align: center;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    height: 100%
    margin: auto;
    background-color: rgba(255, 255, 255, 0.7)
  }
`

const FormContainer = styled.div`
  max-width: 320px;
  margin: 20px auto;

  input[type='password'] {
    margin-top: 40px;
  }

  button {
    margin-top: 15px;
  }
`

const ClinicLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const ClinicLogo = styled.img`
  width: 55px;
  heigth: 55px;
  margin: 10px;
`

const RestorePassword = styled.a`
  display: inline-block;
  margin-top: 10px;
  color: ${props => props.theme.colors.green};
  font-size: 14px;
  text-decoration: underline;
`
const SignUp = styled.div`
  margin: 20px 0 10px 0;
  font-size: 14px;
  color: ${props => props.theme.colors.warmGreyTwo};
`

const QuickAccess = styled.div`
  margin: 60px 0 10px 0;
  font-size: 16px;
  color: ${props => props.theme.colors.warmGreyTwo};
`

const InlineLink = styled(Link)`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.green};
`

const EnableInvertButton = css`
  border: 1px solid ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.green};
`

const DisableInvertButton = css`
  border: 1px solid ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.grey};
  pointer-events: none;
`

const InvertButton = styled.a`  
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  height: 40px;
  margin-top: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${props => props.disabled ? DisableInvertButton : EnableInvertButton}
`