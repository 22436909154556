import axios from 'axios'
import config from '../config'
import { store } from '../store'
import {
    logout as logoutAction,
    addFlashMessage,
    showLoader,
    hideLoader
} from '../actions'

// NOTE: 🔥 Loader component
// Now with support for multiple requests
// Calling setLoadingSpinner with TRUE will increment the pendingRequest counter and show the loader
// Calling setLoadingSpinner with FALSE will decrement the counter and hide the loader if the counter got to CERO.
const setLoadingSpinner = isLoading => {
    if (isLoading) {
        pendingRequests++
        store.dispatch(showLoader())
    } else if (pendingRequests > 0) {
        pendingRequests--
        if (pendingRequests <= 0) {
            store.dispatch(hideLoader())
        }
    }
}

export const API = axios.create({
    baseURL: config.endpoints.API_URL
})

// Pendig Request counter
let pendingRequests = 0

// NOTE: Setting Authorization token in each request
API.interceptors.request.use(
    config => {
        setLoadingSpinner(true)
        const { auth } = store.getState()
        const valtoken = 'accessToken' in auth ? `Valtoken ${auth.accessToken}` : ''
        config.headers = {
            Authorization: valtoken,
            accept: '*/*'
        }
        return config
    },
    err => err
)

// NOTE: Logout when session expired
// If session expired, It doesn't need to return anything
// It prevents React error message trying to use "setState" on an unmounted component.
API.interceptors.response.use(
    res => {
        setLoadingSpinner(false)
        return res
    },
    err => {
        setLoadingSpinner(false)
        const {
            request: { status = 0 },
            message = ''
        } = err

        let errorMessage = 'Algo salió mal. Por favor, intentá más tarde'

        // Unexpected
        if (status === 0 || message.includes('Network Error')) {
            store.dispatch(logoutAction())
            store.dispatch(addFlashMessage(errorMessage))
            return Promise.reject(err)
        }

        switch (status) {
            case 401: // Unauthorized
                store.dispatch(logoutAction())
                if (err.response.statusText.includes('Session Expired')) {
                    errorMessage = 'Tu sesión expiró. Por favor, volvé a ingresar'
                }
                break

            case 403: // Forbidden
                store.dispatch(logoutAction())
                break

            case 404: // Not Found
                break

            default:
                // Others
                if (typeof err.response.data === 'string') {
                    errorMessage = err.response.data
                } else if (
                    typeof err.response.data === 'object' &&
                    typeof err.response.data.message !== 'undefined'
                ) {
                    errorMessage = err.response.data.message
                } else if (err.config.responseType === 'arraybuffer' && 'TextDecoder' in window) {
                    var encoder = new TextDecoder('utf-8')
                    var decodedError = JSON.parse(encoder.decode(err.response.data))
                    errorMessage = decodedError.message
                }
        }

        store.dispatch(addFlashMessage(errorMessage))
        return Promise.reject(err)
    }
)