import { API } from './instances'

export const getExamsResults = async data => {
    const url = `/Laboratorios/Buscar`
    return await API.post(url, data)
}

export const downloadExamResult = async data => {
    const url = `/Laboratorios/Pdf/${data.actId}`
    return await API.get(url, { params: data }, { responseType: 'arraybuffer' })
}

export const logUserDataConfirmation = async data => {
    const url = `/Laboratorios/Confirmar`
    return await API.post(url, data)
}