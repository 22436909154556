import { SET_USER_CREATION_DATA, DELETE_USER_CREATION_DATA } from '../actions'


export const userCreationDefaults = {
  isNewUser: null,
  values: null
}
export const userCreationReducer = function (state = userCreationDefaults, action) {
  switch (action.type) {
    case SET_USER_CREATION_DATA:
      return action.payload
    case DELETE_USER_CREATION_DATA:
      return userCreationDefaults
    default:
      return state
  }
}