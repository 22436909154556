export const SET_USER_CREATION_DATA = 'SET_USER_CREATION_DATA'
export function setUserCreationData(payload) {
  return {
    type: SET_USER_CREATION_DATA,
    payload
  }
}

export const DELETE_USER_CREATION_DATA = 'DELETE_USER_CREATION_DATA'
export function deleteUserCreationData(payload) {
  return {
    type: DELETE_USER_CREATION_DATA,
    payload
  }
}