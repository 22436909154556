import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import Navigation from './Navigator'
import Sidebar from './Sidebar'
import AppointmentNextAlert from './AppointmentNextAlert'
import Footer from './Footer'
import LAYOUT_BG from '../../images/layout-bg.jpg'
import { Paragraph } from '../../styled/generics'
import sizes from '../../styled/sizes'
import BreadCrumbs from './BreadCrumbs'

class Layout extends React.Component {
  state = {
    showSidebarMobile: false,
    scrollHeight: 0,
    showSidebar: true
  }

  componentDidMount() {
    this.setState({
      showSidebar: this.sidebarIsShowing()
    })
  }

  handleShowSidebar = () => {
    this.setState(
      {
        showSidebarMobile: !this.state.showSidebarMobile,
        scrollHeight: window.pageYOffset
      },
      this.handleBodyScroll
    )
  }

  sidebarIsShowing = () => {
    return !(Object.keys(this.props.auth).length === 0)
  }

  handleBodyScroll = () => (this.state.showSidebarMobile ? disableBodyScroll() : enableBodyScroll())

  render() {
    return (
      <Container>
        <HeaderContainer>
          <Navigation handleShowSidebar={this.handleShowSidebar} />
        </HeaderContainer>
        {/* 
          Se solicita que se retire por el momento
        */}
        {/* {!!this.props.auth.gruposRoles && this.props.auth.gruposRoles.some(permiso => permiso === PATIENT) && (
          <UrgencyNumber>
            <UrgencyNumberContainer>
              <MaterialIcon name="info" />
              <span>Emergencias y Urgencias: 0800 445 2356</span>
            </UrgencyNumberContainer>
          </UrgencyNumber>
        )} */}
        {this.state.showSidebar &&
          !this.props.hideSidebar && (
            <Sidebar
              showSidebarMobile={this.state.showSidebarMobile}
              scrollHeight={this.state.scrollHeight}
            />
          )}
        <PageContainer sizes={sizes} extended={!this.state.showSidebar || this.props.hideSidebar}>
          {!this.props.hideSidebar && <BreadCrumbs />}
          {this.props.renderHero && <HeroImg />}
          <Main notReduce={this.props.notReduce} extended={this.props.extended}>
            <AppointmentNextAlert />
            {(!!this.props.title || !!this.props.subTitle || !!this.props.topSpace) && (
              <Titles>
                {this.props.title && <Title>{this.props.title}</Title>}
                {this.props.subTitle && <SubTitle>{this.props.subTitle}</SubTitle>}
              </Titles>
            )}

            {this.props.children}
          </Main>
        </PageContainer>
        <Footer />
      </Container>
    )
  }
}

Layout.propTypes = {
  renderHero: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Layout.defaultProps = {
  renderHero: false,
  title: '',
  subTitle: ''
}

function mapStateToProps({ auth }) {
  return { auth }
}
export default connect(
  mapStateToProps,
  null
)(Layout)

/*
 * Styles
 */
const HeaderContainer = styled.nav`
  height: ${props => props.theme.sizes.layout.header};
  background: white;
  border-bottom: 0.25px solid #757575;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    height: ${props => props.theme.sizes.layout.smallHeader};
  }
`

const HeroImg = styled.div`
  height: ${props => props.theme.sizes.layout.heroImage};
  background: url(${LAYOUT_BG}) center / cover no-repeat;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: none;
  }
`

const Main = styled.main`
  width: 800px;
  margin: 0 auto;
  flex-grow: 1;

  ${props =>
    props.extended &&
    css`
        width: 100%;
      }
    `};

  ${props =>
    !props.notReduce &&
    css`
      @media (min-width: ${props => props.theme.sizes.breakPoints.small}) and (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
        width: 600px;
      }
    `}

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    padding: 30px;
  }
`

const Titles = styled.div`
  text-align: center;
  padding: 50px 0;
`

const Title = styled.h4`
  color: ${props => props.theme.colors.green};
  font-size: 28px;
  margin: 0;
`

const Container = styled.div`
  min-height: 100vh;
`

const PageContainer = styled.div`
  width: ${props => (props.extended ? '100%' : '80%')};
  min-height: 70vh;
  float: right;
  padding-bottom: 72px;
  @media (max-width: ${props => props.sizes.breakPoints.small}) {
    width: 100%;
  }
`

export const UL = styled.ul`
  padding: 0;
  list-style-type: none;
  > li {
    margin: 10px 0;
  }
`
const SubTitle = Paragraph.withComponent('div')

/*
const UrgencyNumber = styled.nav`
  height: 32px;
  background: white;
  border-bottom: 0.25px solid #757575;
`

const UrgencyNumberContainer = styled.div`
  color: #700210;
  width: fit-content;
  margin: auto;

  i {
    font-size: 24px;
    float: left;
    margin: 4px;
  }

  span {
    float: right;
    margin-top: 4px;
    font-weight: 600;
    font-size: 18px;
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    span {
      margin-top: 6px;
      font-size: 16px;
    }
  }
`*/
