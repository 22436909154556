import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { clearFlashMessage } from '../../actions'
import Modal from '../Modal'
import { Button, MaterialIcon } from '../../styled/generics'

const ApiErrorMessage = props =>
  props.flashMessage.message ? (
    <Modal>
      <Container>
        <MaterialIcon name="error" />
        {props.flashMessage.message}
        <ActionContainer>
          <Button outline green onClick={props.clearFlashMessage}>
            Cerrar
          </Button>
        </ActionContainer>
      </Container>
    </Modal>
  ) : null

const mapDispatchToProps = { clearFlashMessage }
function mapStateToProps({ flashMessage }) {
  return { flashMessage }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorMessage)

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  font-size: 24px;

  color: ${props => props.theme.colors.redTwo};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: ${props => props.theme.sizes.breakPoints.small};
  }

  > i {
    font-size: 60px;
    margin-bottom: 20px;
  }
`

const ActionContainer = styled.div`
  margin: 30px auto 0 auto;
  width: 50%;

  > button:first-child {
    margin-bottom: 10px;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
