import { API } from './instances'

export const getRoles = async data => {
    const url = `/Roles/Buscar`
    return await API.get(url, { params: data })
}


export const getDetailedGroupRoles = async () => {
    const url = '/GruposRoles/ObtenerDetalle'
    return await API.get(url)
}
