import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import to from 'await-catch'
import { withRouter } from 'react-router-dom'

import { getProgrammedGuardAppointmentAvailables } from '../api'
import { setProgrammedGuardSchedule } from '../actions'
import ProgrammedGuardDayTab from './ProgrammedGuardDayTab'
import { Paragraph, LinkButton, Button } from '../styled/generics'
import ProgrammedGuardScheduleGrid from './ProgrammedGuardScheduleGrid'

class ProgrammedGuardSchedule extends React.Component {
  state = {
    appointmentsAvailable: [],
    selectedDay: moment().startOf('day'),
    listTabs: [
      {
        id: moment()
          .startOf('day')
          .toDate(),
        name: moment().format('DD/MM')
      },
      {
        id: moment()
          .add(1, 'days')
          .startOf('day')
          .toDate(),
        name: moment()
          .add(1, 'days')
          .format('DD/MM')
      }
    ],
    appointmentsForDay: [],
    selectedSchedule: null
  }

  async componentDidMount() {
    this.getAppointments()
  }

  getAppointments = async () => {
    const params = {
      clinica: this.props.appointment.activeClinic.codigoEmpresaGol,
      idObraSocial: this.props.appointment.patientAtClinicDetails.idObraSocial,
      idPlan: this.props.appointment.patientAtClinicDetails.idPlan,
      idSistema: 'valtech.gol',
      idEspecialidad: this.props.smartGuard.speciality.idEspecialidad,
      fechaDesde: moment().format('YYYY-MM-DD'),
      fechaHasta: moment()
        .add(1, 'months')
        .format('YYYY-MM-DD'),
      horaDesde: moment({ hour: 0, minute: 0, second: 0 }).format('HH:mm:ss'),
      horaHasta: moment({ hour: 23, minute: 59, second: 59 }).format('HH:mm:ss')
    }

    const [err, { data } = {}] = await to(getProgrammedGuardAppointmentAvailables(params))
    if (!err) {
      const appointments = data.find(day =>
        moment(day.fecha, 'DD/MM/YYYY').isSame(this.state.selectedDay, 'day')
      )
      this.setState({
        appointmentsAvailable: data,
        appointmentsForDay: appointments ? appointments.turnos : []
      })
    }
  }

  changeDay = day => {
    const appointments = this.state.appointmentsAvailable.find(appDay =>
      moment(appDay.fecha, 'DD/MM/YYYY').isSame(day, 'day')
    )
    this.setState({
      selectedDay: moment(day),
      selectedSchedule: null,
      appointmentsForDay: appointments ? appointments.turnos : []
    })
  }

  onClickTab = date => this.changeDay(date)
  onClickSchedule = schedule => this.setState({ selectedSchedule: moment(schedule) })
  onClickArrow = forward =>
    this.changeDay(
      forward
        ? moment()
            .add(1, 'days')
            .startOf('day')
        : moment().startOf('day')
    )
  onClickNext = () => {
    this.props.setProgrammedGuardSchedule(this.state.selectedSchedule)
    this.props.history.push(`${this.props.match.url}/confirmar`)
  }

  render() {
    return (
      <Content>
        <P>Seleccioná día y hora</P>
        <TabContainer>
          <IconMobile className="material-icons" onClick={() => this.onClickArrow(false)}>
            keyboard_arrow_left
          </IconMobile>
          <ProgrammedGuardDayTab
            listTabs={this.state.listTabs}
            activeTab={this.state.selectedDay.toDate()}
            onClick={this.onClickTab}
          />
          <IconMobile className="material-icons" onClick={() => this.onClickArrow(true)}>
            keyboard_arrow_right
          </IconMobile>
        </TabContainer>

        <SchedulesContainer>
          <Icon className="material-icons" onClick={() => this.onClickArrow(false)}>
            keyboard_arrow_left
          </Icon>
          <ProgrammedGuardScheduleGrid
            schedules={this.state.appointmentsForDay}
            selectedSchedule={this.state.selectedSchedule}
            onClick={this.onClickSchedule}
          />
          <Icon className="material-icons" onClick={() => this.onClickArrow(true)}>
            keyboard_arrow_right
          </Icon>
        </SchedulesContainer>
        <ButtonsContainer>
          <LinkButton to={'/inicio/guardia-smart'}>Volver</LinkButton>
          <Button disabled={!this.state.selectedSchedule} onClick={this.onClickNext}>
            Siguiente
          </Button>
        </ButtonsContainer>
      </Content>
    )
  }
}

const mapDispatchToProps = { setProgrammedGuardSchedule }
function mapStateToProps({ appointment, smartGuard }) {
  return { appointment, smartGuard }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgrammedGuardSchedule))

/**
 * Styles
 */
const Content = styled.div`
  margin: 30px 0;
  text-align: center;
`
const P = styled(Paragraph)`
  color: ${props => props.theme.colors.green};
  font-size: 14px;
  font-weight: 600;
`
const Icon = styled.i`
  font-size: 42px;
  color: ${props => props.theme.colors.green};
  cursor: pointer;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: none;
  }
`
const IconMobile = styled.i`
  display: none;
  font-size: 32px;
  color: ${props => props.theme.colors.green};
  cursor: pointer;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;
  }
`
const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const SchedulesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`
