import React from 'react'
import styled from 'styled-components'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

class Modal extends React.Component {
  componentDidMount() {
    disableBodyScroll()
  }
  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    return (
      <Container>
        <Content>{this.props.children}</Content>
      </Container>
    )
  }
}

export default Modal

/*
 * Styles
 */
const Container = styled.div`
  height: 100vh;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  left: 0;
  top: 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(4, 112, 94, 0.7);
  border-radius: 4px;
`

const Content = styled.div`
  background: white;
  border-radius: 4px;
  overflow: hidden;
`
