import React from 'react'
import to from 'await-catch'

import { getMedicMegoli } from '../api'
import Layout from './shared/Layout'

class MegoliMedic extends React.Component {
  async componentDidMount() {
    const [err, { data } = {}] = await to(getMedicMegoli())
    if (!err) {
      window.open(data, '_blank')
    }
    this.props.history.push('/')
  }

  render() {
    return <Layout topSpace title="Videoconsulta" />
  }
}
export default MegoliMedic

/*
* Styles
*/
