import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Select, ErrorField } from '../styled/generics'
import { PEDIATRIC_CLINIC_ID } from '../constants'
import { PediatricPatientSearch } from './PediatricPatientSearch'
import moment from 'moment'

const initialValues = {
  specialityId: '',
  // pediatric
  documentType: '',
  documentNumber: '',
  name: '',
  lastName: '',
  email: '',
  repeatEmail: '',
  gender: '',
  birthDate: '',
  credentialNumber: '',
  healthInsurance: '',
  plan: ''
}

class FormSpeciality extends React.Component {
  state = {
    pediatric: false,
    newPediatricPatient: false,
    patientSearched: false,
    patientError: false,
    patientResult: null
  }

  handleSelectPediatric = id => {
    if (PEDIATRIC_CLINIC_ID === parseInt(id)) {
      this.setState({
        pediatric: true
      })
    } else {
      this.setState({
        pediatric: false
      })
    }
  }

  handleSubmit = values => {
    this.props.handleSubmit({
      formValues: values,
      searchedValues: this.state.patientResult,
      pediatric: this.state.pediatric,
      newPediatricPatient:
        this.state.pediatric &&
        this.state.patientSearched &&
        !this.state.patientResult &&
        !this.state.patientError
    })
  }

  handlePediatricChanged = props => {
    this.setState({
      ...props,
      newPediatricPatient:
        this.state.pediatric && props.patientSearched && !props.patientResult && !props.patientError
    })
  }

  handlePediatricSubmit = props => {
    this.setState({
      ...props,
      newPediatricPatient:
        this.state.pediatric && props.patientSearched && !props.patientResult && !props.patientError
    })
  }

  canConnect = formValidation => {
    return !(this.state.pediatric
      ? //check if the patient exists || check if is a new patient and the register form is valid
        (this.state.patientSearched && !this.state.patientError && !!this.state.patientResult) ||
        (this.state.newPediatricPatient && formValidation.isValid)
      : formValidation.isValid)
  }

  render() {
    const validations = yup.object().shape({
      specialityId: yup.string().required('Campo requerido'),
      documentType: this.state.newPediatricPatient
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired(),
      documentNumber: this.state.newPediatricPatient
        ? yup
            .string()
            .matches(/^[0-9]*$/, 'El campo debe ser numérico')
            .test(
              'len',
              'El campo debe tener a lo sumo 9 dígitos',
              val => !val || val.toString().length <= 9
            )
            .notOneOf(['0'], 'El campo no puede ser 0')
            .required('Campo requerido')
        : yup.string().notRequired(),
      name: this.state.newPediatricPatient
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired(),
      lastName: this.state.newPediatricPatient
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired(),
      email: this.state.newPediatricPatient
        ? yup
            .string()
            .email('Email no válido')
            .required('Campo requerido')
        : yup.string().notRequired(),
      repeatEmail: this.state.newPediatricPatient
        ? yup
            .string()
            .email('Email no válido')
            .oneOf([yup.ref('email'), null], 'Los emails deben coincidir')
            .required('Campo requerido')
        : yup.string().notRequired(),
      gender: this.state.newPediatricPatient
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired(),
      birthDate: this.state.newPediatricPatient
        ? yup
            .date()
            .typeError('Fecha de nacimiento no válida')
            .min(
              moment()
                .subtract(18, 'years')
                .startOf('day')
                .toDate(),
              'El paciente debe ser menor de edad'
            )
            .max(
              moment()
                .endOf('day')
                .toDate(),
              'Fecha de nacimiento no válida'
            )
            .required('Campo requerido')
        : yup.date().notRequired(),
      credentialNumber: this.state.newPediatricPatient
        ? yup
            .string()
            .matches(/^[a-zA-Z0-9]*$/, 'El campo solo puede contener letras y numeros')
            .test(
              'len',
              'El campo puede tener hasta 30 caracteres',
              val => !val || val.toString().length <= 30
            )
            .required('Campo requerido')
        : yup.string().notRequired(),
      healthInsurance: this.state.newPediatricPatient
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired(),
      plan: this.state.newPediatricPatient
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired()
    })

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
        render={renderProps => (
          <Form onSubmit={renderProps.handleSubmit}>
            <SizedSelect
              component="select"
              name="specialityId"
              warning={renderProps.errors.specialityId && renderProps.touched.specialityId ? 1 : 0}
              onChange={e => {
                renderProps.handleChange(e)
                this.handleSelectPediatric(e.currentTarget.value)
              }}
            >
              <option value="" disabled hidden>
                Elegí una especialidad
              </option>
              {this.props.specialities.map((speciality, idx) => (
                <option value={speciality.id} key={idx}>
                  {speciality.name}
                </option>
              ))}
            </SizedSelect>

            {renderProps.touched.specialityId &&
              renderProps.errors.specialityId && (
                <ErrorField>{renderProps.errors.specialityId}</ErrorField>
              )}

            {renderProps.values.specialityId === '97' && (
              <Message>
                Te recordamos que la guardia Otorrinolaringológica funciona de 9 a 21hs
              </Message>
            )}

            {this.state.pediatric && (
              /* BUSQUEDA DE PACIENTE PEDIATRIA*/
              <PediatricPatientSearch
                formikRenderProps={renderProps}
                onSearchChanged={this.handlePediatricChanged}
                onSearchPediatricPatient={this.handlePediatricSubmit}
                width={'520px'}
                celWidth={'100%'}
                inputWidth={'100%'}
                patientError={this.state.patientError}
              />
            )}

            <SizedButton type="submit" disabled={this.canConnect(renderProps)}>
              Continuar
            </SizedButton>
          </Form>
        )}
      />
    )
  }
}

FormSpeciality.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  specialities: PropTypes.array.isRequired
}

export default FormSpeciality

/**
 * Styles
 */
const Form = styled.form`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`

const SizedSelect = styled(Select)`
  width: 520px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
  margin: auto;
`

const SizedButton = styled(Button)`
  margin: 40px auto 0;
  width: 320px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`

const Message = styled.div`
  margin-top: 15px;
  text-align: center;
  color: ${props => props.theme.colors.greyFour};
`
