import React from 'react'
import to from 'await-catch'
import Layout from './shared/Layout'
import withUser from '../hoc/withUser'
import styled, { css } from 'styled-components'
import { Button, Hr, MaterialIcon } from '../styled/generics'
// import NEW_ATTACHED from '../images/nuevoAdjunto.svg'
import { Link } from 'react-router-dom'
import { getVoucherAttachments } from '../api'

class InvoiceBondDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: null,
      bond: {
        comprobante: null,
        servicio: null,
        fechaAtencion: null,
        fechaHoraAtencion: null,
        historiaClinicaPaciente: null,
        nombrePaciente: null,
        edad: null,
        celular: null,
        idObraSocial: null,
        obraSocial: null,
        plan: null,
        numeroAfiliado: null,
        condicionIva: null,
        tieneAdjuntos: false,
        citaID: null
      },
      attachments: []
    }
  }

  async componentDidMount() {
    if (
      !this.props.match.params.id ||
      !this.props.match.params.bondId ||
      !this.props.location.invoice ||
      !this.props.location.data
    ) {
      this.props.history.push('/inicio/facturacion')
    } else {
      this.setState(
        {
          invoice: {...this.props.location.invoice},
          bond: {
            ...this.props.location.data
          }
        },
        this.checkAndGetAttachments
      )
    }
  }

  async checkAndGetAttachments() {
    if (this.state.bond.tieneAdjuntos) {
      const [err, { data: attachments } = {}] = await to(
        getVoucherAttachments(this.state.bond.citaID)
      )
      if (!err) {
        this.setState({ attachments })
      }
    }
  }

  render() {
    return (
      <>
        <Layout hideSidebar extended notReduce>
          <DetailContainer>
            <A
              to={{
                pathname: `/inicio/facturacion/${this.props.match.params.id}`,
                data: this.state.invoice
              }}
            >
              <BackButton outline green>
                <MaterialIcon name="arrow_back_ios" />
                Volver a detalle &nbsp;
                <b>{this.props.match.params.id}</b>
              </BackButton>
            </A>
            <Title>Comprobante</Title>
            {!!this.state.bond.comprobante && <SubTitle>{this.state.bond.comprobante}</SubTitle>}
            <ItemsContainer>
              <Items>
                {!!this.state.bond.servicio && (
                  <>
                    <InfoTitle>
                      Servicio:&nbsp;
                      <InfoContent>{this.state.bond.servicio}</InfoContent>
                    </InfoTitle>
                  </>
                )}
                {!!this.state.bond.fechaHoraAtencion && (
                  <>
                    <InfoTitle>
                      Fecha y hora de atención:&nbsp;
                      <InfoContent>{this.state.bond.fechaHoraAtencion}</InfoContent>
                    </InfoTitle>
                  </>
                )}
                {!!this.state.bond.historiaClinicaPaciente && (
                  <>
                    <InfoTitle>
                      HC:&nbsp;
                      <InfoContent>{this.state.bond.historiaClinicaPaciente}</InfoContent>
                    </InfoTitle>
                  </>
                )}
              </Items>
              <Items>
                {!!this.state.bond.nombrePaciente && (
                  <>
                    <InfoTitle>
                      Paciente:&nbsp;
                      <InfoContent>{this.state.bond.nombrePaciente}</InfoContent>
                    </InfoTitle>
                  </>
                )}
                {!!this.state.bond.edadPaciente && (
                  <>
                    <InfoTitle>
                      Edad:&nbsp;
                      <InfoContent>{this.state.bond.edadPaciente}</InfoContent>
                    </InfoTitle>
                  </>
                )}
                {!!this.state.bond.telefonoPaciente && (
                  <>
                    <InfoTitle>
                      Teléfono:&nbsp;
                      <InfoContent>{this.state.bond.telefonoPaciente}</InfoContent>
                    </InfoTitle>
                  </>
                )}
              </Items>
              <Items>
                {!!this.state.bond.idObraSocial &&
                  !!this.state.bond.obraSocial && (
                    <>
                      <InfoTitle>
                        Cobertura:&nbsp;
                        <InfoContent>
                          {this.state.bond.idObraSocial} {this.state.bond.obraSocial}
                        </InfoContent>
                      </InfoTitle>
                    </>
                  )}
                {!!this.state.bond.plan && (
                  <>
                    <InfoTitle>
                      Plan:&nbsp;
                      <InfoContent>{this.state.bond.plan}</InfoContent>
                    </InfoTitle>
                  </>
                )}
                {!!this.state.bond.numeroAfiliado && (
                  <>
                    <InfoTitle>
                      Afiliado nro:&nbsp;
                      <InfoContent>{this.state.bond.numeroAfiliado}</InfoContent>
                    </InfoTitle>
                  </>
                )}
                {!!this.state.bond.condicionIva && (
                  <>
                    <InfoTitle>
                      IVA:&nbsp;
                      <InfoContent>{this.state.bond.condicionIva}</InfoContent>
                    </InfoTitle>
                  </>
                )}
              </Items>
            </ItemsContainer>
          </DetailContainer>
          <Hr width="95" />
          {this.state.bond.tieneAdjuntos && (
            <CardsContainer>
              {this.state.attachments.map((attachment, index) => (
                <Card key={index}>
                  <CardText>
                    Nombre archivo:
                    <InfoContent> {attachment.fileName}</InfoContent>
                  </CardText>
                  <Hr marginTop={16} marginBottom={16} />
                  <ImageContainer>
                    {attachment.contentType.includes('pdf') ? (
                      <PDF src={`data:application/pdf;base64,${attachment.data}`} type="application/pdf"/>
                    ) : (
                      <Image src={`data:image/png;base64, ${attachment.data}`} alt="Imagen" />
                    )}
                  </ImageContainer>
                </Card>
              ))}
              {/**
               * It is removed for the moment
               */
              /* <Card grey>
              <NewAttachedImage src={NEW_ATTACHED} alt="Imagen"/>
            </Card> */}
            </CardsContainer>
          )}
        </Layout>
      </>
    )
  }
}

export default withUser(InvoiceBondDetail)

const DetailContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 12px;
  padding-bottom: 16px;
`

const BackButton = styled(Button)`
  border: 0px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  margin-left: 40px;

  i {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
  }

  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.greyTwo};
  }
`

const A = styled(Link)`
  text-decoration: none;
`

const p = css`
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  ${p} margin-top: 16px;
  font-weight: normal;
  font-size: 12px;
`

const SubTitle = styled.div`
  ${p} font-weight: 600;
  margin-bottom: 16px;
  font-size: 24px;
  color: ${props => props.theme.colors.green};
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
    flex-direction: column;
  }
`

const Items = styled.div`
  width: 33%;
  display: block;

  @media (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
    width: 100%;
  }
`

const InfoTitle = styled.div`
  width: 100%;
  padding-left: 80px;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.greyThree};

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding-left: 24px;
  }
`

const InfoContent = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.warmGreyTwo};
`

const CardsContainer = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 352px);
  grid-gap: 40px;
  margin: auto;
  margin-top: 40px;
  justify-content: start;
`

const Card = styled.div`
  width: 100%;
  padding: 26px;
  border: 0.25px solid ${props => props.theme.colors.grey};
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  ${props =>
    props.grey &&
    css`
      background: ${props => props.theme.colors.grey};
    `};
`

const CardText = styled(InfoTitle)`
  padding-left: 0px;
`

const ImageContainer = styled.div`
  width: fit-content;
  margin: auto;
`

const Image = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 363px;
  border-radius: 4px;
  background: ${props => props.theme.colors.greyFive};
`

const PDF = styled.embed`
  width: 300px;
  height: 363px;
  border-radius: 4px;
`

/**
 * It is removed for the moment
 */
// const NewAttachedImage = styled.img`
//   width: 146px
//   margin-top: 90%;
//   margin-left: 25%;
// `
