import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import to from 'await-catch'

import { getPatientAppointments, getCancelReasons, cancelAppointment } from '../api'
import FormCancelAppointment from './FormCancelAppointment'
import { Cell, TableContainer } from '../styled/tables'

const columns = [
  { title: 'Número de solicitud', size: '0.5fr' },
  { title: 'Profesional', size: '1fr' },
  { title: 'Fecha y hora', size: '1fr' },
  { title: '', size: 'auto' }
]

function isLessThan24hs(date1) {
  const fecha = new Date();
  const dateToMilli = new Date(date1);
  const difference = dateToMilli.getTime() - fecha.getTime();
  return !(difference < 86400000);
}

class TableAppointments extends React.Component {
  state = { listOfAppointments: [], searchedAndNoData: false, cancelReasons: [] }

  async componentDidMount() {
    const [err, [{ data: appointments } = {}, { data: reasons } = {}]] = await to([
      getPatientAppointments(
        this.props.patient.idPaciente,
        this.props.appointment.activeClinic.codigoEmpresa
      ),
      getCancelReasons(this.props.appointment.activeClinic.codigoEmpresa)
    ])
    if (!err) {
      this.setState({
        cancelReasons: reasons,
        searchedAndNoData: appointments.length <= 0,
        listOfAppointments: appointments.map((appointment, index) => {
          return {
            ...appointment,
            showPanelCancel: false,
            cancelled: false
          }
        })
      })
    }
  }

  renderCancelIcon = (index, selected) => (
    <CancelButton
      warning={selected}
      className="material-icons"
      onClick={() => this.openCancelPanel(index)}
    >
      delete
    </CancelButton>
  )

  renderPanelCancel = index => (
    <FormCancelAppointment
      onCancel={() => this.openCancelPanel(index)}
      onSubmit={this.handleCancelAppointment}
      cancelReasons={this.state.cancelReasons}
      appointment={index}
    />
  )

  openCancelPanel = index => {
    const appointments = this.state.listOfAppointments
    appointments[index].showPanelCancel = !appointments[index].showPanelCancel
    this.setState({ listOfAppointments: appointments })
  }

  handleCancelAppointment = async (values, index) => {
    const appointments = this.state.listOfAppointments
    const [err] = await to(
      cancelAppointment(
        appointments[index].idTurno,
        this.props.appointment.activeClinic.codigoEmpresa,
        values.reason,
        appointments[index].fechaTurnoDateTime
      )
    )
    if (!err) {
      appointments[index].cancelled = true
      this.setState({ listOfAppointments: appointments })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.searchedAndNoData ? (
          <NoData>No tenés turnos programados.</NoData>
        ) : (
          <TableContainer columns={columns.map(({ size }) => size).join(' ')}>
            {columns.map(({ title }, index) => (
              <Cell key={index} header>
                {title}
              </Cell>
            ))}

            {this.state.listOfAppointments.map(
              (appointment, index) => appointment.cancelled ? (
                <CancelledCell>Tu turno ha sido cancelado</CancelledCell>
              ) : (
                <React.Fragment key={index}>
                  <Cell red={appointment.showPanelCancel}>{appointment.idTurno}</Cell>
                  <Cell red={appointment.showPanelCancel}>{appointment.nombreProfesional}</Cell>
                  <Cell red={appointment.showPanelCancel}>{appointment.fechaTurno}</Cell>
                  <Cell red={appointment.showPanelCancel}>
                    {isLessThan24hs(appointment.fechaTurnoDateTime) ? this.renderCancelIcon(index, appointment.showPanelCancel) : null}
                  </Cell>
                  {appointment.showPanelCancel && (
                    <PanelCell>{this.renderPanelCancel(index)}</PanelCell>
                  )}
                </React.Fragment>
              )
            )}
          </TableContainer>
        )}

      </React.Fragment>
    )
  }
}

function mapStateToProps({ appointment, patient }) {
  return { appointment, patient }
}
export default connect(mapStateToProps, null)(TableAppointments)

/*
 * Styles
 */
const CancelButton = styled.i`
  font-size: 20px;
  color: ${props => props.theme.colors.redThree};
  cursor: pointer;
  :hover {
    color: #7F0000;
  }
  ${props =>
    props.warning &&
    css`
      color: ${props => props.theme.colors.redTwo};
    `};
`
const NoData = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  grid-column: 1/-1;
  color: ${props => props.theme.colors.redTwo};
  padding: 36px 0;
  margin-top: 5px;
  background: ${props => props.theme.colors.red};
`
const CancelledCell = styled(Cell)`
  color: ${props => props.theme.colors.redTwo};
  font-weight: 600;
  font-size: 16px;
  padding: 13px 0;
  grid-column: 1/-1;
  justify-self: center;
`
const PanelCell = styled(Cell)`
  padding: 31px 0 40px 0;
  height: auto;
  grid-column: 1/-1;
`
