import React from 'react'
import styled, { css } from 'styled-components'

import { APPOINTMENT_STAGE_LIST } from '../constants';
import { LinkButton } from '../styled/generics'
import withPatient from '../hoc/withPatient'
import AppointmentHeader from './AppointmentHeader'
import SectionHeader from './SectionHeader'
import TableMegoliAppointments from './TableMegoliAppointments'

class ListMegoliAppointments extends React.Component {

  componentDidMount() {
    this.props.setAppointmentStage(APPOINTMENT_STAGE_LIST);
  }

  render() {
    return (
        <React.Fragment>
        <Content>
          <SectionHeader
            title={'Accedé a tu videoconsulta'}
          />

          <TableMegoliAppointments />
        </Content>

        <Content>
          <AppointmentHeader
            fullName={this.props.patient.nombreApellido}
            healthInsurance={this.props.patient.coberturaMedica}
            memberNumber={this.props.patient.nroAfiliado}
          />

          <ButtonContent>
            <LinkButton to="/inicio/videoconsulta/turnos/nuevo">Nuevo turno</LinkButton>
          </ButtonContent>
        </Content>
      </React.Fragment>
    )
  }
}

export default withPatient(ListMegoliAppointments)

/*
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`

const ButtonContent = styled.div`
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
  > a {
    width: 320px;
  }
`
