import React from 'react'
import styled from 'styled-components'

import { MaterialIcon } from '../styled/generics'

const DashboardButtonMessage = props => (
  <React.Fragment>
    <Message>{props.message}</Message>
    <IconContainer>
      <MaterialIcon name="error" />
    </IconContainer>
  </React.Fragment>
)

export default DashboardButtonMessage

/*
 * Styles
 */
const Message = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  background: white;
  font-size: 15px;
  width: auto;
  display: flex;
  align-items: center;
  height: auto;
  padding: 5px;
  border: 1px solid ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -6px;
    right: 27px;
    width: 10px;
    height: 10px;
    background: #ffffff;
    border-right: 1px solid ${props => props.theme.colors.green};
    border-bottom: 1px solid ${props => props.theme.colors.green};
    transform: rotate(45deg);
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      right: 22px;
    }
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  color: white;
  > i.material-icons {
    font-size: 38px;
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      font-size: 36px;
    }
  }
`
