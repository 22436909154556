import React from 'react'
import to from 'await-catch'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'

import Layout from './shared/Layout'
import { Button } from '../styled/generics'
import ModalConfirmation from './ModalConfirmation'
import { createUser, updateUser } from '../api'
import { deleteUserCreationData } from '../actions'

class UsersNewConfirmData extends React.Component {
  state = { showModal: false }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  cancel = () => {
    this.props.history.push('/inicio/usuarios')
  }

  confirmUserData = async () => {
    if (this.props.userCreation.isNewUser){
      await to(createUser(this.props.userCreation.values))
    }
    else
    {
      await to(updateUser(this.props.userCreation.values))
    }

    this.props.history.push('/inicio/usuarios')
  }

  componentWillUnmount() {
    this.props.deleteUserCreationData()
  }

  getMetadataDisplayValue = (opciones, valor) => {
    const selected = opciones.find(e => e.id === valor)
    return selected ? selected.valueText : valor
  }

  render() {
    const action = this.props.userCreation.isNewUser ? "Alta" : "Modificacón";
    if (!this.props.userCreation.values) {
      return <Redirect to="/inicio" />
    }
    return (
      <React.Fragment>
        <Layout topSpace title={`Confirmación de ${action} de Usuario`}>
          <Container>
            <Details>
              <Span>Nombre:</Span>
              <Value>{this.props.userCreation.values.nombre}</Value>
              <Span>Apellido:</Span>
              <Value>{this.props.userCreation.values.apellido}</Value>
              <Span>Email:</Span>
              <Value>{this.props.userCreation.values.email}</Value>
              <Span>Roles Asignados:</Span>
              <Value>
                {this.props.userCreation.values.roles.map(role => role.nombre).join(', ')}
              </Value>
              {this.props.userCreation.values.metadatoValores.map(({ metadato, valor }, index) => {
                return (
                  <React.Fragment key={index}>
                    <Span>{metadato.nombre}:</Span>
                    <Value>{this.getMetadataDisplayValue(metadato.opciones, valor)}</Value>
                  </React.Fragment>
                )
              })}
            </Details>
          </Container>
          <ButtonsContainer>
            <Button onClick={this.toggleModal}>Cancelar</Button>
            <Button onClick={this.confirmUserData}>Confirmar</Button>
          </ButtonsContainer>
        </Layout>
        {this.state.showModal && (
          <ModalConfirmation
            iconCode="warning"
            message="Si cancela perderá los cambios realizados"
            buttonCancel={{action: this.toggleModal, label: "Volver"}}
            buttonConfirm={{action: this.cancel, label: "Sí, cancelar"}}
          />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps({ userCreation }) {
  return { userCreation }
}
const mapDispatchToProps = { deleteUserCreationData }
export default connect(mapStateToProps, mapDispatchToProps)(UsersNewConfirmData)

/*
 * Styles
 */

const Container = styled.div`
  background: ${props => props.theme.colors.greyFive};
  padding: 40px 0;
`
const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  width: 100%;
  font-size: 16px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-gap: 11.5px;
    font-size: 14px;
  }
`
const Span = styled.span`
  justify-self: end;
  color: ${props => props.theme.colors.warmGreyTwo};
`
const Value = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 680px;
  margin: 30px auto 0 auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 11.5px;
    width: 100%;
  }
`