import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'

import { Button, Input, LinkButton, Select, ErrorField, Checkbox } from '../styled/generics'
import { ADMINISTRATOR } from '../constants'

class FormUpdateUser extends React.Component {
  constructor(props) {
    super(props)

    // Mapeo roles del usuario
    const userRoles = this.props.user.roles.map(rg => rg.clave).reduce((a, b) => a.concat(b), [])

    // Mapeo opciones para cargar en metadatos del usuario
    const metaOptions = this.props.roleGroups
      .map(rg => rg.metadatos)
      .reduce((a, b) => a.concat(b), [])

    // Mapeo metadatos, valores y opciones
    const userMetadata = this.props.user.metadatoValores.map(mv => {
      mv.metadato.valor = mv.valor
      mv.metadato.opciones =
        mv.metadato.tipo === 'Dropdown'
          ? metaOptions.find(mo => mo.idMetadato === mv.metadato.idMetadato).opciones
          : []
      return mv.metadato
    })

    // check userGroups for unassigned userMetadatas
    this.props.user.gruposRoles.forEach(group => {
      const foundGroup = this.props.roleGroups.find(gr => gr.idGrupoRol === group.idGrupoRol)
      if (foundGroup) {
        foundGroup.metadatos.forEach(metadata => {
          const foundMetadata = userMetadata.find(m => m.idMetadato === metadata.idMetadato)
          if (!foundMetadata) {
            const metadataToAdd = { ...metadata }
            metadataToAdd.valor = ''
            userMetadata.push(metadataToAdd)
          }
        })
      }
    })

    // Mapeo initial values para metadatos
    const metadataInitialValues = userMetadata.reduce((obj, { clave, valor }) => {
      return {
        ...obj,
        [clave]: valor
      }
    }, {})

    this.state = {
      metadatas: userMetadata,
      initialValues: {
        roles: userRoles,
        name: this.props.user.nombre,
        lastName: this.props.user.apellido,
        email: this.props.user.email,
        ...metadataInitialValues
      }
    }
  }

  renderPermits = rolesSelected => {
    const roles = this.props.roles.filter(role =>
      rolesSelected.some(roleSelected => roleSelected === role.clave)
    )
    const permits = roles
      .map(role => role.permisos)
      .reduce((a, b) => a.concat(b), [])
      .filter(
        (permit, index, self) => index === self.findIndex(p => p.idPermiso === permit.idPermiso)
      )

    return permits.map((permit, index) => <Permit key={index}>{permit.nombre}</Permit>)
  }

  renderMetadataFields = formikProps =>
    this.state.metadatas.map((metadata, index) => this.getField(metadata, index, formikProps))

  getField = (item, index, formikProps) => {
    // CASO DROPDOWN
    if (item.tipo === 'Dropdown') {
      return (
        <InputContainer key={index}>
          <Label htmlFor={item.clave}>{item.nombre}</Label>
          <Select
            component="select"
            name={item.clave}
            warning={formikProps.errors[item.clave] && formikProps.touched[item.clave] ? 1 : 0}
          >
            <option value="" hidden>
              {' '}
            </option>
            {item.opciones.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.valueText}
                </option>
              )
            })}
          </Select>
          {formikProps.errors[item.clave] &&
            formikProps.touched[item.clave] && (
              <ErrorField>{formikProps.errors[item.clave]}</ErrorField>
            )}
        </InputContainer>
      )
    }

    // CASO DEFAULT - INPUT
    const fieldType = item.tipo === 'Integer' ? 'number' : 'text'
    return (
      <InputContainer key={index}>
        <Label htmlFor={item.clave}>{item.nombre}</Label>
        <Input
          type={fieldType}
          name={item.clave}
          // placeholder={item.nombre}
          value={formikProps.values[item.clave]}
          white={1}
          border={1}
          warning={formikProps.errors[item.clave] && formikProps.touched[item.clave] ? 1 : 0}
        />
        {formikProps.errors[item.clave] &&
          formikProps.touched[item.clave] && (
            <ErrorField>{formikProps.errors[item.clave]}</ErrorField>
          )}
      </InputContainer>
    )
  }

  updateMetadatas = (rolesSelected, formikProps) => {
    const roles = this.props.roles.filter(role =>
      rolesSelected.some(roleSelected => roleSelected === role.clave)
    )

    const roleGroupsSelected = this.props.roleGroups.filter(roleGroup =>
      roles.map(role => role.grupoRol.clave).some(r => r === roleGroup.clave)
    )

    const metadatas = roleGroupsSelected
      .map(roleGroup => roleGroup.metadatos)
      .reduce((a, b) => a.concat(b), [])
      .filter(
        (metadata, index, self) =>
          index === self.findIndex(m => m.idMetadato === metadata.idMetadato) &&
          (metadata.visible || !metadata.visible)
      )

    metadatas.forEach(
      metadata =>
        formikProps.values[metadata.clave] === undefined
          ? formikProps.setFieldValue(metadata.clave, '')
          : null
    )

    this.setState({ metadatas: metadatas })
  }

  initialValues = () => {
    return this.state.initialValues
  }

  validations = () => {
    const metadatasSchema = this.state.metadatas.map(metadata => {
      // check validation type
      var validation = metadata.visible
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired()
      var obj = { key: metadata.clave, value: validation }
      return obj
    })

    return yup.object().shape({
      roles: yup.array().required(),
      name: yup.string().required('Campo requerido'),
      lastName: yup.string().required('Campo requerido'),
      email: yup
        .string()
        .email('Email no válido')
        .required('Campo requerido'),
      ...metadatasSchema.reduce((obj, item) => {
        obj[item.key] = item.value
        return obj
      }, {})
    })
  }

  render() {
    const initialValues = this.initialValues(this.state.initialValues)
    return (
      <Formik
        enableReinitialize
        onSubmit={values => this.props.onSubmit(values, this.state.metadatas)}
        initialValues={initialValues}
        validationSchema={this.validations}
        render={renderProps => (
          <form onSubmit={renderProps.handleSubmit}>
            {this.props.canEditRoles && (
              <FullWidthContainer>
                <CheckboxesContainer>
                  {
                    // TODO: remover filtrado si alguna vez se quieren poder crear usuarios pacientes mediante update de uno existente
                    // tener en cuenta que muchos de los datos necesarios para crear pacientes del lado de mindware NO SON metadatos de portal (por ejemplo, dob, cobertura, plan)
                    // lo que implicaria cambiar el metodo de generacion de fields, quizas agregando una seccion aparte para los datos de MW?
                  }
                  {this.props.roles.filter(r => r.clave !== 'Paciente').map(item => (
                    <Checkbox
                      key={item.clave}
                      groupName="roles"
                      item={{ key: item.clave, name: item.nombre }}
                      onChange={this.updateMetadatas}
                    />
                  ))}
                </CheckboxesContainer>
              </FullWidthContainer>
            )}
            {renderProps.values.roles.includes(ADMINISTRATOR) && (
              <Alert>
                Tené en cuenta que el usuario Administrador tendrá todos los permisos de Portal
              </Alert>
            )}
            <InputsContainer>
              <InputContainer>
                <Label htmlFor="name">Nombre</Label>
                <Input
                  type="text"
                  name="name"
                  value={renderProps.values.name}
                  // placeholder="Nombre"
                  white={1}
                  border={1}
                  warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
                />
                {renderProps.errors.name &&
                  renderProps.touched.name && <ErrorField>{renderProps.errors.name}</ErrorField>}
              </InputContainer>
              <InputContainer>
                <Label htmlFor="lastName">Apellido</Label>
                <Input
                  type="text"
                  name="lastName"
                  value={renderProps.values.lastName}
                  // placeholder="Apellido"
                  white={1}
                  border={1}
                  warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
                />
                {renderProps.errors.lastName &&
                  renderProps.touched.lastName && (
                    <ErrorField>{renderProps.errors.lastName}</ErrorField>
                  )}
              </InputContainer>
              {/* Email input disabled due to missing functionality */}
              <InputContainer>
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  disabled
                  value={renderProps.values.email}
                  // placeholder="Email"
                  white={1}
                  border={1}
                  warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
                />
                {renderProps.errors.email &&
                  renderProps.touched.email && <ErrorField>{renderProps.errors.email}</ErrorField>}
              </InputContainer>

              {this.state.metadatas.length > 0 && this.renderMetadataFields(renderProps)}
            </InputsContainer>
            {renderProps.values.roles.length > 0 && (
              <PermitsContainer>
                <Title>El usuario podrá ver</Title>
                {this.renderPermits(renderProps.values.roles)}
              </PermitsContainer>
            )}

            <ButtonsContainer>
              <LinkButton to="/inicio/usuarios">Cancelar</LinkButton>
              <Button type="submit">Aceptar</Button>
            </ButtonsContainer>
          </form>
        )}
      />
    )
  }
}

export default FormUpdateUser

/**
 * Styles
 */
const FullWidthContainer = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.greyFive};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    height: auto;
  }
`
const PermitsContainer = styled.div`
  width: 540px;
  margin: 0 auto 40px auto;
  background: ${props => props.theme.colors.greyFive};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
    margin-top: 30px;
    height: auto;
  }
`
const InputsContainer = styled.div`
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: column;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  margin-bottom: 31px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    margin-bottom: 0;
    &:not(:first-child) {
      margin-top: 31px;
    }
  }
`
const Alert = styled.span`
  color: ${props => props.theme.colors.redTwo};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: block;
  margin: 15px auto 0 auto;
`
const ButtonsContainer = styled.div`
  width: 540px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 11px;
    margin-top: 27px;
  }
`
const Title = styled.p`
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
`
const Permit = styled.span`
  font-size: 16px;
  line-height: 27px;
  color: ${props => props.theme.colors.warmGreyTwo};
`

const Label = styled.label`
  padding-bottom: 5px;
  color: ${props => props.theme.colors.green};
`

const CheckboxesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    flex-direction: column;
    padding: 15px 10px;
    align-items: flex-start;
  }
`
