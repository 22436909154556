import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Modal from './Modal'
import { Button } from '../styled/generics'

const MOBILE_WIDTH = 800

const deviceIsIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

const MegoliRequirementsModal = props => {
  const renderMessage = () => {
    if (deviceIsIOS()) {
      return (
        <h3>
          Le informamos que por el momento<br/>
          el servicio de videoconsultas puede no ser compatible con dispositivos iOS.
        </h3>
      )
    } else if (window.innerWidth <= MOBILE_WIDTH) {
      return (
        <h3>
          Para acceder desde tu celular, recordá tener habilitadas las ventanas emergentes desde la
          configuración del navegador.
        </h3>
      )
    } else {
      return (
        <React.Fragment>
          <h3>
            Para acceder a la videoconsulta desde una computadora, es necesario que cuentes con:
          </h3>
          <UL>
            <li>. Sistema operativo Windows 7 (o posterior).</li>
            <li>. Micrófono y Cámara Web.</li>
            <li>. Conexión a Internet con banda ancha.</li>
            <li>. Google Chrome, Mozilla Firefox o Internet Explorer 10-11 (Requiere Plug-In).</li>
          </UL>

          <h4>
            Recordá también tener habilitadas las ventanas emergentes desde la configuración del navegador
          </h4>
        </React.Fragment>
      )
    }
  }

  const renderHours = () => {
    if (props.renderSpecHours) {
      return <h3>El horario de atención es {props.renderSpecHours()}.</h3>
    }
  }

  return (
    <Modal>
      <ModalContainer>
        {renderMessage()}
        {renderHours()}
        <ActionContainer>
          <Button onClick={props.action}>Aceptar</Button>
        </ActionContainer>
      </ModalContainer>
    </Modal>
  )
}

MegoliRequirementsModal.propTypes = {
  action: PropTypes.func.isRequired,
  renderSpecHours: PropTypes.func
}

export default MegoliRequirementsModal

/*
 * Styles
 */
const ModalContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  color: ${props => props.theme.colors.green};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: ${props => props.theme.sizes.breakPoints.small};
  }
`

const ActionContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  > button:first-child {
    margin-bottom: 10px;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
`

const UL = styled.ul`
  list-style-type: none;
  padding: 0;
  > li {
    margin: 10px 0;
  }
`
