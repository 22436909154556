import React from 'react'
import to from 'await-catch'
import moment from 'moment'
import Layout from './shared/Layout'
import withUser from '../hoc/withUser'
import styled, { css } from 'styled-components'
import { Button, MaterialIcon } from '../styled/generics'
import Table from './Table'
import { PAGINATION_PARAMETERS_EXTENDED } from '../constants'
import FIRM_CHECK from '../images/firmCheck.svg'
import { Link } from 'react-router-dom'
import FirmModal from './FirmModal'
import {
  getClinicsDetailed,
  getInvoiceVouchers,
  getVoucherSignature,
  generateInvoicePackage,
  getDownloadLink
} from '../api'

class InvoiceDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      invoice: {},
      clinicsData: [],
      request: {
        items: [],
        centroFactura: null,
        numeroComprobante: null,
        tipoComprobante: null,
        tipoFactura: null,
        pagina: PAGINATION_PARAMETERS_EXTENDED.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS_EXTENDED.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS_EXTENDED.totalPaginas
      },
      modal: {
        show: false,
        number: null,
        patient: null,
        signature: null,
        image: null
      },
      searchedAndNoData: false
    }
  }

  async componentDidMount() {
    if (!this.props.match.params.id || !this.props.location.data) {
      this.goBack()
    } else {
      const {
        tipoComprobante,
        tipoFactura,
        centroFactura,
        numeroComprobante,
        idClinica,
        idFinanciador,
        fecha,
        pagina,
        origen,
        nrsequencia
      } = this.props.location.data
      this.setState({
        invoice: this.props.location.data,
        request: {
          ...this.state.request,
          items: [],
          idClinica,
          idObraSocial: idFinanciador,
          fecha,
          tipoComprobante,
          tipoFactura,
          centroFactura,
          pagina: pagina || this.state.request.pagina,
          numeroComprobante,
          origen,
          nrsequencia
        }
      })

      const [err, { data: clinicsData } = {}] = await to(getClinicsDetailed())
      if (!err) {
        this.setState({ clinicsData })
      }
      this.handleSubmit()
    }
  }

  goBack = () => {
    this.props.history.push('/inicio/facturacion')
  }

  handleSubmit = async (formValues = undefined) => {
    const values = {
      ...this.state.request
    }

    const [err, { data: resp } = {}] = await to(getInvoiceVouchers(values))

    if (!err) {
      if (resp.hasOwnProperty('items')) {
        let data = resp
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        } else {
          data.items = data.items.map(item => ({
            ...item,
            nombrePaciente: !!item.nombrePaciente ? item.nombrePaciente : '',
            numeroAfiliado: !!item.numeroAfiliado ? item.numeroAfiliado : '',
            fechaAtencion: !!item.fechaAtencion
              ? moment(item.fechaAtencion).format('DD/MM/YYYY')
              : '',
            fechaHoraAtencion: !!item.fechaAtencion
              ? moment(item.fechaAtencion).format('DD/MM/YYYY HH:mm')
              : '',
            importe: !!item.importe ? item.importe : '',
            comprobante: !!item.comprobante ? item.comprobante : '',
            codigo: !!item.codigo ? item.codigo : '',
            cantidad: !!item.cantidad ? item.cantidad : '',
            descripcion: !!item.descripcion ? item.descripcion : '',
            nombreProfesional: !!item.nombreProfesional ? item.nombreProfesional : ''
          }))
          this.setState({
            request: { ...values, ...data },
            searchedAndNoData: false
          })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleModal = async (number, patient, signature, image) => {
    let base64Image

    if (!!image) {
      base64Image = image
    } else {
      const [err, { data } = {}] = await to(getVoucherSignature(signature))
      if (!err) {
        base64Image = data
      }
    }

    this.setState({
      modal: {
        show: true,
        number,
        patient,
        image: base64Image
      }
    })
  }

  downloadInvoicePackage = async (e, row) => {
    const request = {
      centroFactura: row.centroFactura,
      modalidad: row.modalidad,
      idClinica: row.idClinica,
      numeroComprobante: row.numeroComprobante,
      tipoComprobante: row.tipoComprobante,
      tipoFactura: row.tipoFactura,
      idObraSocial: row.idFinanciador,
      cuit: row.cuit,
      fecha: row.fecha,
      origen: row.origen,
      nrsequencia: row.nrsequencia
    }

    const [err, { data } = {}] = await to(generateInvoicePackage(request))
    if (!err) {
      var downloadUrl = getDownloadLink(data.token, data.file)
      const link = document.createElement('a')
      link.href = downloadUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  render() {
    //Start of Table Definition
    var columns = [
      { title: 'Paciente', index: 'nombrePaciente', size: 'minmax(150px, 1fr)' },
      { title: 'Afiliado', index: 'numeroAfiliado', size: 'minmax(150px, 1fr)' },
      { title: 'Fecha', index: 'fechaAtencion', size: 'minmax(110px, 1fr)' },
      { title: 'Importe', index: 'importe', size: 'minmax(100px, 1fr)' },
      {
        title: 'Comprobante',
        index: 'comprobante',
        size: 'minmax(120px, 110px)'
      },
      { title: 'Código', index: 'codigo', size: 'minmax(80px, 1fr)' },
      { title: 'Cant', index: 'cantidad', size: 'minmax(80px, 1fr)' },
      { title: 'Descripción', index: 'descripcion', size: 'minmax(150px, 1fr)' },
      {
        title: 'Firma',
        index: 'tieneFirma',
        render: row => {
          return (
            <React.Fragment>
              {row.tieneFirma ? (
                <div
                  onClick={() =>
                    this.handleModal(
                      row.numeroAfiliado,
                      row.nombrePaciente,
                      row.citaID,
                      row.firmaBase64
                    )
                  }
                >
                  <FirmCheck src={FIRM_CHECK} alt="Firm Check" />
                </div>
              ) : (
                '-'
              )}
            </React.Fragment>
          )
        },
        size: 'minmax(60px, 1fr)'
      },
      { title: 'Médico', index: 'nombreProfesional', size: 'minmax(150px, 1fr)' },
      {
        title: 'Ver',
        index: '',
        render: row => {
          return (
            <React.Fragment>
              <A
                to={{
                  pathname: `/inicio/facturacion/${this.props.match.params.id}/${row.comprobante}`,
                  invoice: {...this.state.invoice, pagina: this.state.request.pagina},
                  data: row
                }}
              >
                Ver más
              </A>
            </React.Fragment>
          )
        },
        size: 'minmax(130px, 1fr)'
      }
    ]
    //End of Table Definition

    return (
      <>
        <Layout hideSidebar extended notReduce>
          <DetailContainer>
            <ABackButton
              to={{
                pathname: `/inicio/facturacion`,
                data:
                  !!this.props.location.data &&
                  !!this.props.location.data.invoicePage &&
                  !!this.props.location.data.searchInvoiceInputs
                    ? {
                        invoicePage: this.props.location.data.invoicePage,
                        searchInvoiceInputs: this.props.location.data.searchInvoiceInputs
                      }
                    : null
              }}
            >
              <BackButton outline green>
                <MaterialIcon name="arrow_back_ios" />
                Volver a Búsqueda
              </BackButton>
            </ABackButton>
            <Title>Factura</Title>
            {!!this.state.invoice.numeroFactura && (
              <SubTitle>{this.state.invoice.numeroFactura}</SubTitle>
            )}
            {this.state.clinicsData.length > 0 &&
              this.state.clinicsData.some(item => item.id === this.state.invoice.idClinica) && (
                <InfoTitle>
                  Clínica/Centro Médico:
                  <InfoContent>
                    {
                      this.state.clinicsData.find(item => item.id === this.state.invoice.idClinica)
                        .nombre
                    }
                  </InfoContent>
                </InfoTitle>
              )}
            {!!this.state.invoice.financiador && (
              <InfoTitle>
                Institución: <InfoContent>{this.state.invoice.financiador}</InfoContent>
              </InfoTitle>
            )}
            {!!this.state.invoice.modalidad && (
              <InfoTitle>
                Modalidad: <InfoContent>{this.state.invoice.modalidad}</InfoContent>
              </InfoTitle>
            )}
            {!!this.state.invoice.condicionIVA && (
              <InfoTitle>
                Condición IVA:
                <InfoContent>{this.state.invoice.condicionIVA}</InfoContent>
              </InfoTitle>
            )}
          </DetailContainer>
          <TableContainer>
            <Table
              green
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
            >
              <ButtonsContainer>
                <SizedButton
                  outline
                  green
                  onClick={e => this.downloadInvoicePackage(e, this.state.invoice)}
                >
                  <MaterialIcon name="get_app" />
                  Descargar
                </SizedButton>
                {/*
                 * It is removed for the moment
                 *
                <SizedButton outline green>
                  <MaterialIcon name="print" />
                  Imprimir selección
                </SizedButton> 
                */}
              </ButtonsContainer>
            </Table>
          </TableContainer>
        </Layout>
        {this.state.modal.show && (
          <FirmModal
            number={this.state.modal.number}
            patient={this.state.modal.patient}
            signature={this.state.modal.signature}
            image={this.state.modal.image}
            closeModal={() => {
              this.setState({
                modal: {
                  show: false,
                  number: null,
                  patient: null,
                  signature: null,
                  image: null
                }
              })
            }}
          />
        )}
      </>
    )
  }
}

export default withUser(InvoiceDetail)

const DetailContainer = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 32px;
  background: ${props => props.theme.colors.greyFive};
`

const ABackButton = styled(Link)`
  text-decoration: none;
`

const BackButton = styled(Button)`
  border: 0px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  margin-left: 40px;

  i {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
  }

  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.greyTwo};
  }
`

const p = css`
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  ${p} margin-top: 16px;
`

const SubTitle = styled.div`
  ${p} font-weight: 600;
  margin-bottom: 48px;
  font-size: 24px;
  color: ${props => props.theme.colors.green};
`

const InfoTitle = styled.div`
  padding-left: 80px;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.greyThree};
`

const InfoContent = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.greyTwo};
  padding-left: 4px;
`

const TableContainer = styled.div`
  margin-bottom: 24px;
  width: 90%;
  margin: auto;
`

const ButtonsContainer = styled.div`
  width: fit-content;
  display: inherit;
`

const SizedButton = styled(Button)`
  width: 120px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 32px;
  i {
    font-size: 14px;
    font-weight: 500;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 142px;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${props => props.theme.colors.greyTwo};
      &:hover {
        color: ${props => props.theme.colors.greyTwo};
      }
    `};
`

const A = styled(Link)`
  font-weight: 300;
  font-size: 12px;
  color: ${props => props.theme.colors.green};
`

const FirmCheck = styled.img`
  width: 24px;
  cursor: pointer;
`
