import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

import { Button, MaterialIcon, CustomSingleDatePicker } from '../styled/generics'

const initialValues = {
  startDate: ''
}

const validations = yup.object().shape({
  startDate: yup.date().required()
})

class FormFilterDuties extends React.Component {
  state = { onFocus: false, showFilters: true }
  render() {
    return (
      <FormContainer>
        <FiltersMobile onClick={() => this.setState({ showFilters: !this.state.showFilters })}>
          <span>Filtros</span>
          <MaterialIcon
            name={this.state.showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          />
        </FiltersMobile>
        <Formik
          onSubmit={values => {
            this.setState({ showFilters: false })
            this.props.onSubmit(values)
          }}
          initialValues={initialValues}
          validationSchema={validations}
          render={renderProps => (
            <Form onSubmit={renderProps.handleSubmit} showFilters={this.state.showFilters}>
              <CustomSingleDatePicker
                warning={renderProps.errors.startDate && renderProps.touched.startDate ? 1 : 0}
                contentAlignment='center'
              >
                <SingleDatePicker
                  date={
                    moment(renderProps.values.startDate).isValid()
                      ? moment(renderProps.values.startDate)
                      : null
                  }
                  id="startDate"
                  onDateChange={date => {
                    renderProps.setFieldValue('startDate', date)
                  }}
                  placeholder="Fecha de inicio"
                  focused={this.state.onFocus}
                  onFocusChange={({ focused }) => {
                    this.setState({ onFocus: focused })
                    if (!focused) renderProps.setFieldTouched('startDate', true)
                  }}
                  hideKeyboardShortcutsPanel={true}
                  firstDayOfWeek={0}
                  numberOfMonths={1}
                  isOutsideRange={date => date.isAfter(moment(), 'day')}
                  displayFormat="DD - MM - YYYY"
                />
              </CustomSingleDatePicker>

              <SizedButton type="submit">Consultar</SizedButton>
            </Form>
          )}
        />
      </FormContainer>
    )
  }
}

export default FormFilterDuties

/**
 * Styles
 */
const FormContainer = styled.div``
const FiltersMobile = styled.div`
  display: none;
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
  padding: 12px 30px 18px 30px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: ${props => (props.showFilters ? 'block' : 'none')};
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    > * {
      margin-bottom: 20px;
    }
  }
`
const SizedButton = styled(Button)`
  width: 320px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`