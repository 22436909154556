import { API } from './instances'

export const getQuestions = async () => {
    const url = '/Megoli/ObtenerPreguntas'
    return await API.get(url)
}

export const getPatientMegoli = async params => {
    const url = '/Megoli/GetPaciente'
    return await API.post(url, params)
}

export const getMedicMegoli = async () => {
    const url = '/Megoli/GetMedico'
    return await API.get(url)
}

export const getMOLIHours = async data => {
    const url = '/Megoli/ObtenerHorarios'
    return await API.get(url, { params: data })
}

export const getMOLIAppointment = async patientErid => {
    const url = `/Megoli/ObtenerProximoTurno?pacienteErid=${patientErid}`
    return await API.get(url)
}

export const getMOLIAppointments = async patientErid => {
    const url = `/Megoli/ObtenerProximosTurnos?pacienteErid=${patientErid}`
    return await API.get(url)
}

export const connectToAppointment = async params => {
    const url = '/Megoli/Turno'
    return await API.post(url, params)
}