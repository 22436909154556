import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'
import to from 'await-catch'

import { MaterialIcon } from '../../styled/generics'
import { toggleAppointmentMessage, toggleMegoliAppointmentMessage } from '../../actions'
import ModalConfirmation from '../ModalConfirmation'
import { PATIENT } from '../../constants'
import {
  getFutureAppointmentGol,
  cancelAppointment,
  getMOLIAppointment,
  connectToAppointment
} from '../../api'

class AppointmentNextAlert extends React.Component {
  state = {
    showConfirmModal: false,
    appointmentDetails: null,
    megoliAppointmentDetails: null
  }

  async componentDidMount() {
    const isPatient =
      this.props.auth.hasOwnProperty('roles') && this.props.auth.roles.some(rol => rol === PATIENT)
    const pacienteERID =
      isPatient && this.props.patient.pacienteERID ? this.props.patient.pacienteERID : 0

    // Proximo Turno Gol
    if (!this.props.appointmentMessageDismissed && pacienteERID) {
      const [err, { data } = {}] = await to(getFutureAppointmentGol(pacienteERID))
      if (!err && data) {
        this.setState({ appointmentDetails: data })
      }
    }

    // Proximo Turno Megoli
    if (!this.props.megoliAppointmentMessageDismissed && pacienteERID) {
      const [err, { data } = {}] = await to(getMOLIAppointment(pacienteERID))
      if (!err && data) {
        this.setState({ megoliAppointmentDetails: data })
      }
    }
  }

  dismiss = () => this.props.toggleAppointmentMessage()

  dismissMoli = () => this.props.toggleMegoliAppointmentMessage()

  toggleModal = () => this.setState({ showConfirmModal: !this.state.showConfirmModal })

  confirmCancelAppointment = async () => {
    const { idTurno, codigoEmpresa } = this.state.appointmentDetails
    const [err] = await to(cancelAppointment(idTurno, codigoEmpresa, 1))

    if (!err) {
      this.toggleModal()
      this.dismiss()
    }
  }

  connectToMoli = async () => {
    var params = {
      phone: this.props.patient.telefono ? this.props.patient.telefono : '',
      idTurno: this.state.megoliAppointmentDetails.idTurno
    }

    const [err, { data } = {}] = await to(connectToAppointment(params))
    if (!err) {
      this.dismissMoli()
      window.open(data, '_blank')
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Turno Gol Render */}
        {!this.props.appointmentMessageDismissed &&
          this.state.appointmentDetails && (
            <React.Fragment>
              <Container>
                <DesktopWidth>
                  <span>
                    Recordá que <b>tenés un TURNO</b> en la guardia de{' '}
                    {this.state.appointmentDetails.clinica},
                    <b>
                      {' '}
                      hoy a las{' '}
                      {moment(this.state.appointmentDetails.fechaTurnoDateTime).format('HH:mm')}
                      hs
                    </b>
                    .
                  </span>
                  <ClearButton onClick={this.dismiss}>
                    <MaterialIcon name="clear" />
                  </ClearButton>
                  <Button onClick={this.toggleModal}>CANCELAR TURNO</Button>
                </DesktopWidth>
              </Container>
              {this.state.showConfirmModal && (
                <ModalConfirmation
                  iconCode="help"
                  message="¿Estás seguro que querés cancelar tu turno?"
                  buttonCancel={{ action: this.toggleModal, label: 'No' }}
                  buttonConfirm={{ action: this.confirmCancelAppointment, label: 'Sí' }}
                />
              )}
            </React.Fragment>
          )}

        {/* Turno Megoli */}
        {!this.props.megoliAppointmentMessageDismissed &&
          this.state.megoliAppointmentDetails &&
          this.state.megoliAppointmentDetails.habilitado && (
            <React.Fragment>
              <Container>
                <DesktopWidth>
                  <span>
                    Tu <b>turno de videoconsulta</b> comienza a las
                    <b>
                      {' '}
                      {moment(this.state.megoliAppointmentDetails.fechaTurnoDateTime).format(
                        'HH:mm'
                      )}
                    </b>
                  </span>
                  <ClearButton onClick={this.dismissMoli}>
                    <MaterialIcon name="clear" />
                  </ClearButton>
                  <Button onClick={this.connectToMoli}>INGRESAR</Button>
                </DesktopWidth>
              </Container>
            </React.Fragment>
          )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = { toggleAppointmentMessage, toggleMegoliAppointmentMessage }
function mapStateToProps({
  auth,
  patient,
  appointment: { appointmentMessageDismissed, megoliAppointmentMessageDismissed }
}) {
  return { auth, patient, appointmentMessageDismissed, megoliAppointmentMessageDismissed }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentNextAlert)

/* 
 * Styles
 */
const Container = styled.div`
  background: ${props => props.theme.colors.redTwo};
  position: absolute;
  left: 0;
  top: 102px;
  padding: 10px 60px;
  color: white;
  font-size: 13px;
  text-align: center;
  width: 100%;

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: row;
    top: 112px;
  }
`

const DesktopWidth = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: row;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
`

const ClearButton = styled.div`
  position: absolute;
  right: 5px;
  top: 10px;

  :hover {
    cursor: pointer;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    top: 5px;
  }
`

const Button = styled.button`
  border: none;
  background: none;
  color: white;
  text-decoration: underline;
  font-size: 13px;
  margin-top: 5px;

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-top: 0;
  }
`
