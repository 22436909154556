import React from 'react'
import { connect } from 'react-redux'
import { setAppointmentStage } from '../actions'

const withPatient = WrappedComponent => {
  const WithPatient = props => <WrappedComponent {...props} />
  const mapDispatchToProps = { setAppointmentStage }
  WithPatient.displayName = `WithPatient(${getDisplayName(WrappedComponent)})`
  return connect(mapStateToProps, mapDispatchToProps)(WithPatient)
}

function mapStateToProps({ patient }) {
  return { patient }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withPatient
