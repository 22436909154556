import React from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { WEEK_DAYS, WEEK_DAYS_SHORT, APPOINTMENT_DATE_FORMAT } from '../constants'

const Calendar = props => {
  const html = []
  const today = moment()
  let day = moment(props.selectedMonth)
    .startOf('month')
    .startOf('week')
    .subtract(1, 'days')
  let grid, firstIteration
  const findAppointmentForToday = day => {
    return props.appointments.find(appointment =>
      day.isSame(moment(appointment.fecha, APPOINTMENT_DATE_FORMAT), 'days')
    )
  }

  grid = firstIteration = 42
  while (grid) {
    let dayProps = { disabled: true }
    let content = null

    if (grid !== firstIteration) day = day.add(1, 'days')

    const currentMonth = day.isSame(props.selectedMonth, 'months')
    if (currentMonth) {
      dayProps.disabled = false

      if (day.isBefore(today, 'days')) dayProps.before = true
      else {
        // Check availability
        dayProps.noAvailability = true
        const appointment = findAppointmentForToday(day)
        if (appointment) {
          content = [
            <Appointment key="dinamicText">
              {appointment.cantidad} {appointment.cantidad > 1 ? 'turnos' : 'turno'}
            </Appointment>,
            <Appointment key="staticText">
              {appointment.cantidad > 1 ? 'disponibles' : 'disponible'}
            </Appointment>
          ]
          dayProps.noAvailability = false
          dayProps.selected = day.isSame(props.selectedDay, 'days')
        } else {
          content = [<Appointment key="staticText">Sin turnos</Appointment>]
        }
      }
    }

    const dayClone = moment(day)

    html.push(
      <Day
        {...dayProps}
        key={grid}
        onClick={() =>
          !dayProps.disabled && !dayProps.noAvailability && !dayProps.before && !dayProps.selected
            ? props.onClickDay(dayClone)
            : null
        }
      >
        <Date>{dayClone.format('D')}</Date>
        {content}
      </Day>
    )
    grid--
  }

  return (
    <Container>
      {WEEK_DAYS.map((name, index) => {
        return <WeekDay key={index}>{name}</WeekDay>
      })}
      {WEEK_DAYS_SHORT.map((name, index) => {
        return <WeekDayMobile key={index}>{name}</WeekDayMobile>
      })}
      {html}
      <Reference>
        <Square />Sin turnos disponibles
      </Reference>
    </Container>
  )
}

export default Calendar

/**
 * Styles
 */

export const Container = styled.div`
  font-family: sans-serif;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(7, minmax(35px, 1fr));
  grid-gap: 4px;
  grid-template-rows: auto;
  grid-auto-rows: minmax(60px, 1fr);
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-gap: 4.2px 3.6px;
    grid-auto-rows: minmax(35px, 1fr);
  }
`

const Day = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 13px;
  background: white;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.green};

  ${props =>
    props.before &&
    css`
      color: white;
      background: ${props => props.theme.colors.whiteTwo};
    `};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.warmGrey};
      background: transparent;
    `};

  ${props =>
    props.noAvailability &&
    css`
      color: white;
      background: ${props => props.theme.colors.warmGreyTwo};
    `};

  ${props =>
    !props.before &&
    !props.disabled &&
    !props.noAvailability &&
    css`
      &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.greenFour};
      }
    `};

  ${props =>
    props.selected &&
    css`
      font-weight: 600;
      background: ${props => props.theme.colors.greenFour};
      border: 2px solid ${props => props.theme.colors.greenThree};
    `};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 14px;
  }
`
const Date = styled.span`
  position: absolute;
  right: 5px;
  top: 4px;
`
const Appointment = styled.div`
  text-align: center;
  font-size: 11px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: none;
  }
`
export const FullWidth = styled.div`
  grid-column: span 7;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`
const WeekDay = styled.p`
  font-size: 12px;
  color: ${props => props.theme.colors.green};
  text-align: center;
  margin-bottom: 5px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: none;
  }
`
const WeekDayMobile = styled.p`
  display: none;
  font-size: 13px;
  color: ${props => props.theme.colors.warmGreyTwo};
  text-align: center;
  margin-bottom: 3px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;
  }
`
const Reference = styled.div`
  display: none;
  grid-column: span 7;
  font-size: 13px;
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    align-items: center;
  }
`
const Square = styled.div`
  display: none;
  height: 9px;
  width: 9px;
  background: ${props => props.theme.colors.warmGreyTwo};
  margin-right: 4.2px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;
  }
`
