import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Modal from './Modal'
import { Button } from '../styled/generics'
import FIRM_CHECK from '../images/firmCheck.svg'

const FirmModal = props =>
  !!props.image ? (
    <Modal>
      <Container>
        <TitleContainer>
          <FirmCheck src={FIRM_CHECK} alt="Firm Check" />
          <span>Firma del Paciente</span>
        </TitleContainer>
        {!!props.patient && !!props.number && <SubTitle>{props.number+' '+props.patient}</SubTitle>}
        {props.image !== "0" ? (
          <FirmImage src={'data:image/png;base64, '+props.image} alt="Firm Check" />
        ):(
          <FirmText>El/La paciente firmó conforme.</FirmText>
        )}
        <ActionContainer>
          <Button outline green onClick={props.closeModal}>
            Volver
          </Button>
        </ActionContainer>
      </Container>
    </Modal>
  ) : null

FirmModal.propTypes = {
  closeModal: PropTypes.func.isRequired
}

export default FirmModal

const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  font-size: 24px;
  width: 538px;
  background-color: ${props => props.theme.colors.greenFour};

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }

  > i {
    font-size: 60px;
    margin-bottom: 20px;
  }
`

const p = css`
  width: 100%;
  text-align: center;
`

const FirmCheck = styled.img`
  margin-right: 4px;
  width: 24px;
`

const TitleContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-top: 16px;
  font-weight: 500;
  font-size: 20px;
  margin: auto;
  color: ${props => props.theme.colors.green};
`

const SubTitle = styled.div`
  ${p} 
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
  color: ${props => props.theme.colors.greyFour};
`

const ActionContainer = styled.div`
  margin: 0 auto 0 auto;
  width: 40%;

  > button:first-child {
    margin-bottom: 10px;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`

const FirmText = styled(SubTitle)`
  font-size: 24px;
  margin-bottom: 16px;
`

const FirmImage = styled.img`
  max-width: 358px;
  max-height: 186px;
  width: 70%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 4px;
  background: ${props => props.theme.colors.greyFive};
  border: 1px solid ${props => props.theme.colors.greyFour};
`
