import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { Input, Button } from '../styled/generics'

const validations = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  lastName: yup.string().required()
})

const FormPerfil = props => {
  const initialValues = {
    email: props.defaults.email,
    name: props.defaults.nombre,
    lastName: props.defaults.apellido
  }

  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      render={renderProps => (
        <Form onSubmit={renderProps.handleSubmit}>
          <Input
            type="text"
            //disabled
            name="name"
            value={renderProps.values.name}
            placeholder="Nombre"
            white={1}
            border={1}
            warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
          />
          <Input
            //disabled
            type="text"
            name="lastName"
            value={renderProps.values.lastName}
            placeholder="Apellido"
            white={1}
            border={1}
            warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
          />
          <Input
            disabled
            type="email"
            name="email"
            value={renderProps.values.email}
            placeholder="Email"
            white={1}
            border={1}
            warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
          />
          <div>
            <Button 
              type="submit" 
              disabled={
                props.isSameEmail(renderProps.values.email) && 
                props.isSameName(renderProps.values.name, renderProps.values.lastName)} 
            >
              Guardar Cambios
            </Button>
          </div>
        </Form>
      )}
    />
  )
}

export default FormPerfil

const Form = styled.form`
  input {
    margin: 20px 0;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    input {
      width: 370px;
      margin: 0 auto 20px;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 320px;
      }
    }
  }
`
