import { ADD_FLASH_MESSAGE, CLEAR_FLASH_MESSAGE } from '../actions'


const flashMessageDefaults = {
  message: ''
}
export const flashMessageReducer = function (state = flashMessageDefaults, action) {
  switch (action.type) {
    case ADD_FLASH_MESSAGE:
      return { message: action.payload }
    case CLEAR_FLASH_MESSAGE:
      return flashMessageDefaults
    default:
      return state
  }
}