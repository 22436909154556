import { API } from './instances'
import { store } from '../store'
import { setLoaderProgress } from '../actions'

export const getInvoiceList = async data => {
    const url = `/Facturacion/Buscar`
    return await API.post(url, data)
}

export const getInstitutionsList = async () => {
    const url = '/Facturacion/Financiadores'
    return await API.get(url)
}

export const getInvoiceVouchers = async data => {
    const url = '/Facturacion/Ambulatorios'
    return API.get(url, { params: data })
}

export const getVoucherSignature = async id => {
    const url = `/Facturacion/Ambulatorios/Firma?citaId=${id}`
    return API.get(url)
}

export const getVoucherAttachments = async id => {
    const url = `/Facturacion/Ambulatorios/Adjuntos?citaId=${id}`
    return API.get(url)
}

export const sendInvoice = async data => {
    const url = `/Facturacion/Enviar`
    return await API.post(url, data)
}

export const cancelInvoiceSending = async data => {
    const url = `/Facturacion/CancelarEnvio`
    return await API.post(url, data)
}

export const downloadInvoiceList = async data => {
    const url = '/Facturacion/Exportar'
    return await API.post(url, data)
}

export const generateInvoicePackage = async data => {
    const url = '/Facturacion/GenerarDescargable'
    const downloadStatus = { status: 'Generando documentación' }
    store.dispatch(setLoaderProgress(downloadStatus))

    return await API.post(url, data)
}