import styled, { css } from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.columns};
  font-size: 14px;
  color: ${props => props.theme.colors.black};
  overflow-x: scroll;
`
export const Cell = styled.div`
  min-height: 38px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding: 10px;
  font-weight: 300;
  ${props =>
    props.header &&
    css`
      font-size: 16px;
      font-weight: 500;
      color: ${props => props.theme.colors.black};
      border-bottom: 0;

      ${props =>
        // at the moment this function was not specified so I put the same color
        props.ordered &&
        css`
          color: ${props => props.theme.colors.black};
        `};
    `};

  ${props =>
    props.red &&
    css`
      color: ${props => props.theme.colors.redTwo};
    `};

  ${props =>
    props.green &&
    css`
      color: ${props => props.theme.colors.green};
    `};

  ${props =>
    props.autoHeight &&
    css`
      height: auto;
    `};

  .material-icons {
    font-size: 20px;
  }
`
