import featureToggleApi from 'feature-toggle-api'

const api = featureToggleApi()
api.visibility('disable-guard', false)
api.visibility('enable-new-specialties', true)
api.visibility('disable-virtual-consultation', true)


const disableClinicGuard = api.isVisible('disable-guard')
const enableNewSpecialties = api.isVisible('enable-new-specialties')
const disableVirtualConsultation = api.isVisible('disable-virtual-consultation')

export default {
    disableClinicGuard,
    enableNewSpecialties,
    disableVirtualConsultation
}