import React from 'react'
import to from 'await-catch'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logout } from '../api'
import { logout as logoutAction, setPatientDetails } from '../actions'
import { patientDefaults } from '../reducers'

// NOTE: Styles depends on you 😡
const Logout = props => {
  const logoutHandler = async () => {
    const [err] = await to(logout())

    if (!err) {
      props.setPatientDetails(patientDefaults)
      props.logoutAction()
      props.history.push('/')
    }
  }

  return <button onClick={logoutHandler}>Salir</button>
}

const mapDispatchToProps = { logoutAction, setPatientDetails }
export default withRouter(connect(null, mapDispatchToProps)(Logout))
