import React from 'react'
import styled from 'styled-components'

import { MaterialIcon } from '../styled/generics'

const TabClinicsMessage = props => (
  <Container>
    <Message>{props.message}</Message>
    <MaterialIcon name="error" />
  </Container>
)

export default TabClinicsMessage

const Container = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  text-align: center;

  i {
    color: ${props => props.theme.colors.green};
  }
`

const Message = styled.div`
  color: white;
  background: ${props => props.theme.colors.green};
  position: absolute;
  font-size: 15px;
  width: 140px;
  left: -5px;
  display: flex;
  align-items: center;
  height: auto;
  padding: 5px;
  top: -45px;
  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 7px;
    border: solid;
    border-color: ${props => props.theme.colors.green} transparent;
    border-width: 10px 10px 0 10px;
  }
`
