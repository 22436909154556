export const SET_ACTIVE_CLINIC = 'SET_ACTIVE_CLINIC'
export function setActiveClinic(payload) {
  return {
    type: SET_ACTIVE_CLINIC,
    payload
  }
}

export const SET_PATIENT_AT_CLINIC_DETAILS = 'SET_PATIENT_AT_CLINIC_DETAILS'
export function setPatientDetailsAtClinic(payload) {
  return {
    type: SET_PATIENT_AT_CLINIC_DETAILS,
    payload
  }
}

export const SET_APPOINTMENT_DETAILS = 'SET_APPOINTMENT_DETAILS'
export function setAppointmentDetails(payload) {
  return {
    type: SET_APPOINTMENT_DETAILS,
    payload
  }
}

export const TOGGLE_APPOINTMENT_MESSAGE = 'TOGGLE_APPOINTMENT_MESSAGE'
export function toggleAppointmentMessage() {
  return {
    type: TOGGLE_APPOINTMENT_MESSAGE
  }
}

export const TOGGLE_MEGOLI_APPOINTMENT_MESSAGE = 'TOGGLE_MEGOLI_APPOINTMENT_MESSAGE'
export function toggleMegoliAppointmentMessage() {
  return {
    type: TOGGLE_MEGOLI_APPOINTMENT_MESSAGE
  }
}

export const CLEAR_APPOINTMENT_STATE = 'CLEAR_APPOINTMENT_STATE'
export function clearAppointmentState() {
  return {
    type: CLEAR_APPOINTMENT_STATE
  }
}

export const SET_APPOINTMENT_STAGE = 'SET_APPOINTMENT_STAGE'
export function setAppointmentStage(payload) {
  return {
    type: SET_APPOINTMENT_STAGE,
    payload
  }
}

export const SET_APPOINTMENT_IS_ONLINE = 'SET_APPOINTMENT_IS_ONLINE'
export function setAppointmentIsOnline(payload) {
  return {
    type: SET_APPOINTMENT_IS_ONLINE,
    payload
  }
}