import React from 'react'
import styled from 'styled-components'
import to from 'await-catch'

import Layout from './shared/Layout'
import FormContact from './FormContact'
import ModalMessage from './ModalMessage'
import { getClinicsDetailed, contact } from '../api'

class Contact extends React.Component {
  state = { listOfClinics: [], showModal: false }

  async componentDidMount() {
    const [err, { data: listOfClinics } = {}] = await to(getClinicsDetailed())

    if (!err) {
      this.setState({ listOfClinics })
    }
  }

  onSubmit = async values => {
    const parsedValues = {
      nombre: `${values.name} ${values.lastName}`,
      telefono: values.phone,
      cuerpo: values.message,
      destino: values.clinic,
      roles: values.roles,
      email: values.email
    }
  
    const [err] = await to(contact(parsedValues))

    if (!err) {
      this.toggleModal()
    }

  }

  toggleModal = () => this.setState({ showModal: !this.state.showModal })

  render() {
    return (
      <Layout topSpace title="Contacto">
        {this.state.showModal && (
          <ModalMessage
            message="Su consulta se ha enviado con éxito"
            button={{
              label: "Volver",
              action: () => {
                this.toggleModal()
                this.props.history.push('/inicio')
              }
            }}
          />
        )}
        <Container>
          {this.state.listOfClinics && (
            <FormContact onSubmit={this.onSubmit} listOfClinics={this.state.listOfClinics} />
          )}
        </Container>
      </Layout>
    )
  }
}

export default Contact

/*
 * Styles
 */
const Container = styled.div`
  padding: 20px;
  background: ${props => props.theme.colors.cloudyBlueThree};
`
