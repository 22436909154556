import React from 'react'
import to from 'await-catch'
import { connect } from 'react-redux'

import Layout from './shared/Layout'
import FormNewUser from './FormNewUser'
import { getDetailedGroupRoles } from '../api'
import { AUTOMANAGEMENTFUNDER_GROUP, FUNDER_GROUP, PATIENT_GROUP } from '../constants'
import { setUserCreationData } from '../actions'

class UsersNew extends React.Component {
  state = { roleGroups: [], roles: [] }

  async componentDidMount() {
    //TODO: could be consumed from redux if prev. loaded in UsersList.js
    const [err, { data } = {}] = await to(getDetailedGroupRoles())
    if (!err) {
      // Remuevo opcion de crear usuarios paciente
      let roleGroups = data.filter(rg => rg.clave !== PATIENT_GROUP)

      // Autogestores solo pueden crear usuarios dentro del mismo grupo
      if (this.props.auth.gruposRoles.find(gr => gr === AUTOMANAGEMENTFUNDER_GROUP)) {
        roleGroups = roleGroups.filter(rg => rg.clave === FUNDER_GROUP)
      }

      // Asigno GruposRoles y Roles a state
      const roles = roleGroups.map(rg => rg.roles).reduce((a, b) => a.concat(b), [])
      this.setState({ roleGroups: roleGroups, roles: roles })
    }
  }

  handleSubmit = (formValues, metadatas) => {
    const values = {
      nombre: formValues.name,
      apellido: formValues.lastName,
      email: formValues.email,
      roles: this.state.roles.filter(role => formValues.roles.some(r => r === role.clave)),
      metadatoValores: metadatas.map(metadata => ({
        metadato: metadata,
        valor: formValues[metadata.clave]
      }))
    }

    this.props.setUserCreationData({
      isNewUser: true,
      values: values
    })

    this.props.history.push(`${this.props.match.path}/confirmar`)
  }

  render() {
    return (
      <Layout topSpace title="Nuevo usuario" subTitle="Asigne roles al usuario">
        <FormNewUser
          roleGroups={this.state.roleGroups}
          roles={this.state.roles}
          onSubmit={this.handleSubmit}
        />
      </Layout>
    )
  }
}
function mapStateToProps({ userCreation, auth }) {
  return { userCreation, auth }
}
const mapDispatchToProps = { setUserCreationData }
export default connect(mapStateToProps, mapDispatchToProps)(UsersNew)
