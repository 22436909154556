import React from 'react'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'
import { 
  ErrorField,
  CustomSingleDatePicker,
  SmallCalendarSelect 
} from '../styled/generics'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

/*
  Custom datepicker created to integrate seamlessly 
  with Formik and existing styled components
*/
export class PortalDatePicker extends React.Component{
  constructor (props) {
    super(props);
    this.state = {
      onFocus: false
    };

    PortalDatePicker.propTypes = {
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tabIndex: PropTypes.string,
      placeholder: PropTypes.string.isRequired,
      renderProps: PropTypes.shape().isRequired,
    }
  }

  render () {
    var {warning, renderProps, name, tabIndex, ...inputProps} = this.props;
    return (
      <CustomSingleDatePicker
          formHeight={true}
          contentAlignment='left'
          warning={warning}
      >
        <SingleDatePicker
            displayFormat="DD/MM/YYYY"
            firstDayOfWeek={0}
            numberOfMonths={1}
            block={true}
            readOnly={false}
            hideKeyboardShortcutsPanel={true}
            {...inputProps}

            date={ moment(renderProps.values[name]).isValid() ? moment(renderProps.values[name]) : null }
            onDateChange={date => { renderProps.setFieldValue(name, date) }}

            focused={this.state.onFocus}
            onFocusChange={({ focused }) => {
                this.setState({ onFocus: focused })
                if (!focused) renderProps.setFieldTouched(name, true)
            }}

            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
              <CalendarMonthContainer>
                  <div>
                    <SmallCalendarSelect
                        value={month.month()}
                        onChange={(e) => { onMonthSelect(month, e.target.value); }}
                    >
                        {moment.months().map((label, value) => (
                        <option key={value} value={value}>
                            {label[0].toUpperCase() + label.slice(1)}
                        </option>
                        ))}
                    </SmallCalendarSelect>
                  </div>
                  <div>
                    <SmallCalendarSelect
                        value={month.year()}
                        onChange={(e) => { onYearSelect(month, e.target.value); }}
                    >
                        {[...Array(150).keys()].map((label, value) => (
                        <option key={value} value={moment().year() - value}>
                            {moment().year() - value}
                        </option>
                        ))}
                    </SmallCalendarSelect>
                  </div>
              </CalendarMonthContainer>
            )}
        />
        <ErrorMessage name={name} component={ErrorField} />
      </CustomSingleDatePicker>
    )
  }
}

/*
 * Styles
 */
const CalendarMonthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
