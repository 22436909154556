import React from 'react'
import styled, { css } from 'styled-components'

import { LinkButton, Paragraph } from '../styled/generics'

const ClinicGuardCheckInCancelled = () => (
  <Container>
    <P green bold>
      Saliste de la fila para la guardia
    </P>
    <P bold>Si querés volver a ingresar, seleccioná la clínica a la que querés ir.</P>
    <SizedButton to="/inicio/guardia-smart">Volver</SizedButton>
  </Container>
)

export default ClinicGuardCheckInCancelled

/**
 * Styles
 */
const Container = styled.div`
  margin-top: 40px;
  text-align: center;
`
const P = styled(Paragraph)`
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};

  ${props =>
    props.green &&
    css`
      color: ${props => props.theme.colors.green};
      font-size: 32px;
      margin-bottom: 20px;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        font-size: 24px;
      }
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
`
const SizedButton = styled(LinkButton)`
  width: 320px;
  margin: 47px auto 0 auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
