export const SET_CLINICS_TIMES_WITH_SPECIALITY = 'SET_CLINICS_TIMES_WITH_SPECIALITY'
export function setClinicsTimesWithSpeciality(payload) {
  return {
    type: SET_CLINICS_TIMES_WITH_SPECIALITY,
    payload
  }
}

export const SET_CHECK_IN_INFO = 'SET_CHECK_IN_INFO'
export function setCheckInInfo(payload) {
  return {
    type: SET_CHECK_IN_INFO,
    payload
  }
}

export const SET_PROGRAMMED_GUARD_SCHEDULE = 'SET_PROGRAMMED_GUARD_SCHEDULE'
export function setProgrammedGuardSchedule(payload) {
  return {
    type: SET_PROGRAMMED_GUARD_SCHEDULE,
    payload
  }
}

export const SET_PROGRAMMED_GUARD_ID = 'SET_PROGRAMMED_GUARD_ID'
export function setProgrammedGuardId(payload) {
  return {
    type: SET_PROGRAMMED_GUARD_ID,
    payload
  }
}

export const CLEAR_SMART_GUARD_STATE = 'CLEAR_SMART_GUARD_STATE'
export function clearSmartGuardState() {
  return {
    type: CLEAR_SMART_GUARD_STATE
  }
}

export const SET_GOL_ENABLED_SPECS = 'SET_GOL_ENABLED_SPECS'
export const setGolEnabledSpecs = payload => ({
  type: SET_GOL_ENABLED_SPECS,
  payload
})