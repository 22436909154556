import React from 'react'
import { connect } from 'react-redux'
import to from 'await-catch'
import { Switch } from 'react-router-dom'

import { getClinicsDetailed } from '../api'
import {
  APPOINTMENT_STAGE_LIST,
  APPOINTMENT_STAGE_CONFIRMED,
  SPECIALTIES_CLINICS
} from '../constants'
import {
  setActiveClinic,
  setPatientDetails,
  clearAppointmentState
} from '../actions'
import Layout from './shared/Layout'
import TabClinics from './TabClinics'
import PrivateRoute from './PrivateRoute'
import ConfirmPatientData from './ConfirmPatientData'
import ListAppointments from './ListAppointments'
import NewAppointment from './NewAppointment'
import ConfirmedAppointment from './ConfirmedAppointment'
import CancelledAppointment from './CancelledAppointment'

class Appointment extends React.Component {
  state = { listOfClinics: [], activeClinics: [] }

  async componentDidMount() {
    this.props.clearAppointmentState()
    if (!this.props.appointment.activeClinic) {
      this.props.history.replace(`${this.props.match.path.replace(':attention', this.props.match.params.attention)}`)
    }
    const [err, { data: clinicsData } = {}] = await to(getClinicsDetailed())

    if (!err) {
      const updatedActiveClinics = this.getActivesClinicsBySpecialties(clinicsData)

      this.setState({
        listOfClinics: clinicsData,
        activeClinics: updatedActiveClinics
      });
    }
  }

  componentDidUpdate(prevProps) {
    const actualAttention = this.props.match.params.attention
    const prevAttention = prevProps.match.params.attention

    if (
      this.props.location.pathname === this.props.match.path.replace(':attention', actualAttention) &&
      prevProps.location.pathname === `${this.props.match.path.replace(':attention', actualAttention)}/datos-paciente`
    ) {
      this.props.clearAppointmentState()
    }

    if (actualAttention !== prevAttention) {
      const updatedClinics = this.getActivesClinicsBySpecialties(this.state.listOfClinics)
      this.setState({ activeClinics: updatedClinics })
      this.props.clearAppointmentState()
    }
  }

  getActivesClinicsBySpecialties(clinics) {
    const attention = this.props.match.params.attention
    const clinicsId = SPECIALTIES_CLINICS[attention]
    const filteredClinics = clinics.filter(clinic => clinicsId && clinicsId.includes(clinic.id))

    return filteredClinics.length ? filteredClinics : clinics
  }

  onClickTab = async activeTab => {
    this.props.setActiveClinic(
      this.state.activeClinics.find(clinic => clinic.codigoEmpresa === activeTab)
    )

    this.props.history.push(`${this.props.match.path.replace(':attention', this.props.match.params.attention)}/datos-paciente`)
  }

  onClickBack = () => {
    this.props.clearAppointmentState()
    this.props.history.push(`${this.props.match.path.replace(':attention', this.props.match.params.attention)}`)
  }

  render() {
    const subtitle =
      this.props.appointment.appointmentStage < APPOINTMENT_STAGE_LIST
        ? 'Pedí un turno presencial'
        : ''
    return (
      <Layout topSpace subTitle={subtitle}>
        {this.props.appointment.appointmentStage < APPOINTMENT_STAGE_CONFIRMED && (
          <TabClinics
            listTabs={this.state.activeClinics}
            onClickTab={this.onClickTab}
            activeTab={
              this.props.appointment.activeClinic
                ? this.props.appointment.activeClinic.codigoEmpresa
                : null
            }
            onClickBack={this.onClickBack}
          />
        )}

        {this.props.appointment.activeClinic && (
          <Switch>
            <PrivateRoute
              exact
              path={`${this.props.match.path.replace(':attention', this.props.match.params.attention)}/datos-paciente`}
              component={ConfirmPatientData}
            />
            <PrivateRoute
              exact
              path={`${this.props.match.path.replace(':attention', this.props.match.params.attention)}/listado`}
              component={ListAppointments}
            />
            <PrivateRoute
              exact
              path={`${this.props.match.path.replace(':attention', this.props.match.params.attention)}/nuevo`}
              component={NewAppointment}
            />
            <PrivateRoute
              exact
              path={`${this.props.match.path.replace(':attention', this.props.match.params.attention)}/confirmacion`}
              component={ConfirmedAppointment}
            />
            <PrivateRoute
              exact
              path={`${this.props.match.path.replace(':attention', this.props.match.params.attention)}/cancelado`}
              component={CancelledAppointment}
            />
          </Switch>
        )}
      </Layout>
    )
  }
}
const mapDispatchToProps = { setActiveClinic, setPatientDetails, clearAppointmentState }
function mapStateToProps({ appointment, auth }) {
  return { appointment, auth }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Appointment)
