import React from 'react'
import styled from 'styled-components'
import to from 'await-catch'

import { Paragraph } from '../styled/generics'
import { getCancelReasons, cancelAppointment } from '../api'
import FormCancelAppointment from './FormCancelAppointment'

class PanelConfirmation extends React.Component {
  state = { cancelReasons: [], cancelPanel: false }

  async componentDidMount() {
    const [err, { data } = {}] = await to(getCancelReasons(this.props.clinic))
    if (!err) {
      this.setState({
        cancelReasons: data
      })
    }
  }

  toggleCancelForm = state => this.setState({ cancelPanel: state })

  handleConfirmCancel = async (values, id) => {
    const [err] = await to(cancelAppointment(id, this.props.clinic, values.reason))
    if (!err) {
      this.props.handleAppointmentCancelled()
    }
  }
  
  render() {
    return (
      <Container>
        <Icon className="material-icons">check</Icon>
        <Title>{this.state.cancelPanel ? "Estás por cancelar tu turno" : "Turno confirmado"}</Title>

        {this.props.isOnline ? (
          <SmallParagraph>
            Para acceder a la consulta, ingresá al portal 5 minutos antes y elegí la opción{' '}  
            <strong>videoconsulta programada</strong>.
          </SmallParagraph>
        ) : (
          <SmallParagraph>
            Por favor, presentate 15 minutos antes del horario del turno, junto con el DNI y
            credencial de tu prepaga u obra social.
          </SmallParagraph>
        )}

        {!this.state.cancelPanel && (
          <CancelParagraph>
            Si necesitás cancelarlo, ingresá{' '}
            <InlineButton onClick={() => this.toggleCancelForm(true)}>acá</InlineButton>.
          </CancelParagraph>
        )}

        {this.state.cancelPanel && (
          <FormCancelAppointment
            cancelReasons={this.state.cancelReasons}
            onCancel={() => this.toggleCancelForm(false)}
            onSubmit={this.handleConfirmCancel}
            appointment={this.props.id}
          />
        )}
      </Container>
    )
  }
}

export default PanelConfirmation

/*
 * Styles
 */
const Container = styled.div`
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  width: 578px;
  margin: auto auto 25px auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
const Icon = styled.i`
  margin-top: 25px;
  font-size: 120px;
  color: ${props => props.theme.colors.greenThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 90px;
  }
`
const Title = styled(Paragraph)`
  font-size: 36px;
  color: ${props => props.theme.colors.green};
  margin-bottom: 25px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 28px;
  }
`
const InlineButton = styled.span`
  color: ${props => props.theme.colors.redTwo};
  text-decoration: underline;
  cursor: pointer;
`
const CancelParagraph = styled(Paragraph)`
  margin-bottom: 10px;
`

const SmallParagraph = styled(Paragraph)`
  font-weight: normal;
  margin-bottom: 25px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-top: 5px;
  }
`
