import React from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import Layout from './shared/Layout'
import FormSignUp from './FormSignUp'
import { Paragraph } from '../styled/generics'
import {
  getGenders,
  getDocumentTypes,
  getHealthInsurances,
  getHealthInsurancePlans,
  signUp
} from '../api'
import { LinkButton } from '../styled/generics'

class SignUp extends React.Component {
  state = { genders: [], documentTypes: [], healthInsurances: [], plans: [], accountCreated: false }
  async componentDidMount() {
    const [
      err,
      [{ data: genders } = {}, { data: documentTypes } = {}, { data: healthInsurances } = {}]
    ] = await to([getGenders(), getDocumentTypes(), getHealthInsurances()])
    if (!err) {
      this.setState({ genders, documentTypes, healthInsurances })
    }
  }

  handleHealthInsuranceChange = async value => {
    const [err, { data } = {}] = await to(getHealthInsurancePlans(value))
    if (!err) {
      this.setState({ plans: data })
    }
  }

  handleSubmit = async values => {
    const params = {
      mail: values.email,
      nombres: values.name,
      apellidos: values.lastName,
      tipoDocumento: values.documentType,
      documento: values.documentNumber,
      sexo: values.gender,
      dob: values.birthDate,
      nroAfiliado: values.credentialNumber,
      obraSocial: values.healthInsurance,
      plan: values.plan
    }
    const [err] = await to(signUp(params))
    if (!err) {
      this.setState({ accountCreated: true })
    }
  }

  render() {
    return (
      <Layout topSpace title="Registro" subTitle="Completá tus datos">
        <Content>
          {!this.state.accountCreated ? (
            <FormSignUp
              genders={this.state.genders}
              documentTypes={this.state.documentTypes}
              healthInsurances={this.state.healthInsurances}
              plans={this.state.plans}
              onHealthInsuranceChange={this.handleHealthInsuranceChange}
              onSubmit={this.handleSubmit}
            />
          ) : (
            <React.Fragment>
              <SuccessMessage>
                Te enviamos un mail para que puedas generar tu contraseña
              </SuccessMessage>
              <ButtonContainer>
                <LinkButton to="/">Volver</LinkButton>
              </ButtonContainer>
            </React.Fragment>
          )}
        </Content>
      </Layout>
    )
  }
}

export default SignUp

/**
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 30px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 30px 20px 20px;
  }
`
const SuccessMessage = styled(Paragraph)`
  text-align: center;
`
const ButtonContainer = styled.div`
  width: 320px;
  margin: 20px auto;
`
