import { API } from './instances'

export const getGuardSpecialities = async () => {
    const url = '/Guardias/Especialidades'
    return await API.get(url)
}

export const getWaitingTimes = async specialty => {
    const url = 'Guardias/ObtenerEsperas?especialidad=' + specialty
    return await API.get(url)
}

export const getProgrammedGuardAppointmentAvailables = data => {
    const url = 'Guardias/TurnosDisponibles'
    return API.get(url, { params: data })
}

export const confirmProgrammedGuardAppointment = data => {
    const url = '/Guardias/Confirmar'
    return API.post(url, data)
}

export const getCheckInAppointment = async params => {
    const url = `FilaEstandar/ObtenerTurno`
    return await API.post(url, params)
}

export const recoverOldAppointmentData = async params => {
    const url = `/FilaEstandar/ObtenerTurnoViejo?clinica=${params.clinic}&turno=${params.appointment}`
    return await API.get(url)
}

export const getLinePosition = async (clinic, appointment) => {
    const url = `/FilaEstandar/ObtenerPosicion?clinica=${clinic}&turno=${appointment}`
    return await API.get(url)
}

export const cancelCheckInAppointment = async params => {
    const url = '/FilaEstandar/CancelarTurno'
    return await API.post(url, params)
}

export const getGolEnabledSpecs = () => {
    const url = '/Guardias/EspecialidadesGOL'
    return API.get(url)
}