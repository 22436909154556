import React from 'react'
import to from 'await-catch'
import styled from 'styled-components'

import withUser from '../hoc/withUser'
import {
  PATIENT_GROUP,
  FUNDER_GROUP,
  ADMINISTRATOR_GROUP,
  AUTOMANAGEMENTFUNDER_GROUP,
  DUTIES,
  ROUTES_BY_ROLE_GROUP,
  MEDIC_GROUP
} from '../constants'
import DashboardTab from './DashboardTab'
import { getNotification } from '../api'
import Layout from './shared/Layout'
import DashboardButton from './DashboardButton'
import AppointmentsCancelledModal from './AppointmentsCancelledModal'

const layoutMessages = {
  [PATIENT_GROUP]: {
    title: 'Bienvenido/a al portal de las clínicas',
    subTitle: '¿Qué necesitás?'
  },
  [ADMINISTRATOR_GROUP]: {
    title: 'Bienvenido/a al portal de las clínicas',
    subTitle: 'Desde aquí podrás gestionar usuarios y roles.'
  },
  [FUNDER_GROUP]: {
    title: 'Bienvenido/a al portal de las clínicas',
    subTitle: 'Desde aquí podrás gestionar Aranceles y Pacientes Internados.'
  },
  [AUTOMANAGEMENTFUNDER_GROUP]: {
    title: 'Bienvenido/a al portal de las clínicas',
    subTitle: 'Desde aquí podrás gestionar usuarios.'
  },
  [MEDIC_GROUP]: {
    title: 'Bienvenido/a al portal de las clínicas',
    subTitle: 'Desde aquí podrás ingresar a Videoconsulta.'
  }
}

class Dashboard extends React.Component {
  state = {
    notification: null,
    activeTab: 0,
    cancelledAppointments: null,
    showModal: false
  }

  async componentDidMount() {
    if (this.props.auth.permisos.some(permiso => permiso.clave === DUTIES)) {
      const params = {
        email: this.props.auth.email,
        funcionalidad: 'Aranceles'
      }
      const [err, { data } = {}] = await to(getNotification(params))
      if (!err) {
        this.setState({ notification: data })
      }
    }

    // REMOVED FOR NOW
    // if (this.props.auth.gruposRoles.includes(PATIENT_GROUP)) {
    //   const [err, { data } = {}] = await to(getPatientCancelledAppointments())
    //   if (!err) {
    //     let visited = sessionStorage["cancelledAppointmentModalViewed"];
    //     if (data && data.length > 0 && !visited){
    //       sessionStorage["cancelledAppointmentModalViewed"] = true;
    //       this.setState({ cancelledAppointments: data, showModal: true })
    //     }
    //   }
    // }
  }

  closeModal = () => this.setState({ showModal: false })

  changeTab = id => this.setState({ activeTab: id })

  getRoutesAvailable = roleGroup => {
    return ROUTES_BY_ROLE_GROUP[roleGroup].filter(routes =>
      this.props.auth.permisos.some(p => routes.permit === p.clave)
    )
  }

  getButtonNotification = buttonData => {
    if (buttonData.notification) {
      return buttonData.notification
    }
    if (
      buttonData.name === 'Aranceles' &&
      this.state.notification !== 'Usted no tiene nuevos Aranceles'
    ) {
      return this.state.notification
    }
    return null
  }

  render() {
    const listTabs = this.props.auth.gruposRoles.map((gr, index) => ({ id: index, name: gr }))

    return (
      <React.Fragment>
        <Layout topSpace {...layoutMessages[this.props.auth.gruposRoles[this.state.activeTab]]}>
          {listTabs.length > 1 && (
            <DashboardTab
              listTabs={listTabs}
              activeTab={this.state.activeTab}
              onClick={this.changeTab}
            />
          )}
          <Container>
            {this.getRoutesAvailable(this.props.auth.gruposRoles[this.state.activeTab]).map(
              (route, index) => {
                let queryParams = ''
                if (route.isPregnancyAdmin || route.isPregnant) {
                  let token = this.props.auth.metadatos.find(
                    metaObject => metaObject.metadato.clave === 'TokenPregnancy'
                  )
                  if (token) {
                    token = token.valor
                  }
                  queryParams = route.isPregnancyAdmin
                    ? `?token=${token}&isAdmin=true`
                    : `?token=${token}`
                }
                return (
                  <DashboardButton
                    isExternalLink={route.isExternalLink}
                    image={route.buttonImage}
                    title={route.name}
                    cta={route.cta}
                    link={`${route.pathName}${queryParams}`}
                    key={index}
                    notification={this.getButtonNotification(route)}
                    disable={route.disable}
                    show={route.show}
                  />
                )
              }
            )}
          </Container>
        </Layout>
        {this.state.showModal &&
          <AppointmentsCancelledModal action={this.closeModal} cancelledAppointments={this.state.cancelledAppointments} />}
      </React.Fragment>
    )
  }
}

export default withUser(Dashboard)

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 5%;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: column;
  }
`