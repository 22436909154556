import React from 'react'
import styled from 'styled-components'
import { Input } from '../styled/generics'

export class InvoiceInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onFocus: false
    }
  }

  render() {
    const {
      tipoComprobante,
      tipoFactura,
      centroFactura,
      numeroComprobante,
      errors,
      touched,
      disabled
    } = this.props

    return (
      <Container>
        <InputText
          width={40}
          type="text"
          disabled={disabled}
          name="tipoComprobante"
          value={tipoComprobante}
          placeholder="FC"
          maxLength={2}
          white={1}
          border={1}
          warning={errors.tipoComprobante && touched.tipoComprobante ? 1 : 0}
        />
        <InputText
          width={32}
          type="text"
          disabled={disabled}
          name="tipoFactura"
          value={tipoFactura}
          placeholder="A"
          maxLength={1}
          white={1}
          border={1}
          warning={errors.modalidad && touched.modalidad ? 1 : 0}
        />
        <InputText
          width={56}
          type="text"
          disabled={disabled}
          name="centroFactura"
          value={centroFactura}
          placeholder="0000"
          maxLength={4}
          white={1}
          border={1}
          warning={errors.centroFactura && touched.centroFactura ? 1 : 0}
        />
        <InputText
          width={88}
          type="text"
          disabled={disabled}
          name="numeroComprobante"
          value={numeroComprobante}
          placeholder="00000000"
          maxLength={8}
          white={1}
          border={1}
          warning={errors.numeroComprobante && touched.numeroComprobante ? 1 : 0}
        />
      </Container>
    )
  }
}

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  align-items: center;
`

const InputText = styled(Input)`
  margin-right: 16px;
  width: ${props => (props.width ? props.width : 100)}px;
`
