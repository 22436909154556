import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, MaterialIcon } from '../styled/generics'
import Modal from './Modal'

const ModalConfirmation = props => (
    <Modal>
        <Container>
            {(props.iconCode) && ( <MaterialIcon name={props.iconCode} /> )}
            {props.message}
            <ActionContainer>
            <Button onClick={props.buttonCancel.action}>{props.buttonCancel.label}</Button>
            <Button onClick={props.buttonConfirm.action}>{props.buttonConfirm.label}</Button>
            </ActionContainer>
        </Container>
    </Modal>
)

ModalConfirmation.propTypes = {
    iconCode: PropTypes.string,
    message: PropTypes.string.isRequired,
    buttonCancel: PropTypes.shape({
      action: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired
    }),
    buttonConfirm: PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired
    })
}

export default ModalConfirmation

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  font-size: 20px;

  color: ${props => props.theme.colors.green};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: ${props => props.theme.sizes.breakPoints.small};
  }

  > i {
    font-size: 45px;
    margin-bottom: 20px;
  }
`

const ActionContainer = styled.div`
  margin: 30px auto 0 auto;
  width: 75%;
  align-items: center;
  justify-content: center;

  > button:first-child {
    margin-bottom: 10px;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;

    > button {
      :first-child {
        margin: 0 20px 0 0;
      }

      :last-child {
        margin: 0 0 0 20px;
      }
    }
  }
`