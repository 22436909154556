import React from 'react'
import styled from 'styled-components'
import { Formik, ErrorMessage } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import { Input, Button, Select, ErrorField } from '../styled/generics'
import { AutocompleteText } from '../form-components/autocomplete'
import { PortalDatePicker } from '../form-components/datePicker'

const initialValues = {
  name: '',
  lastName: '',
  email: '',
  repeatEmail: '',
  documentType: '',
  documentNumber: '',
  gender: '',
  birthDate: '',
  credentialNumber: '',
  healthInsurance: '',
  plan: ''
}

class FormSignUp extends React.Component {
  state = { onFocus: false }

  getValidationSchema = () => {
    return yup.object().shape({
      name: yup.string().required('Campo requerido'),
      lastName: yup.string().required('Campo requerido'),
      email: yup
        .string()
        .email('Email no válido')
        .required('Campo requerido'),
      repeatEmail: yup
        .string()
        .email('Email no válido')
        .oneOf([yup.ref('email'), null], 'Los emails deben coincidir')
        .required('Campo requerido'),
      documentType: yup.string().required('Campo requerido'),
      documentNumber: yup
        .string().matches(/^[0-9]*$/, 'El campo debe ser numérico')
        .test('len', 'El campo debe tener a lo sumo 9 dígitos', val => !val || val.toString().length <= 9)
        .notOneOf(['0'], 'El campo no puede ser 0')
        .required('Campo requerido'),
      gender: yup.string().required('Campo requerido'),
      birthDate: yup
        .date().typeError("Fecha de nacimiento no válida")
        .max(moment().subtract(18, 'years').endOf('day').toDate(), "Necesitas tener al menos 18 años")
        .required('Campo requerido'),


        //------------------------------- campo credencial

      credentialNumber: yup
        .string().matches(/^[a-zA-Z0-9]*$/, 'El campo solo puede contener letras y numeros')        
        .test('len', 'El campo puede tener hasta 30 caracteres', val => !val || val.toString().length <= 30)
        .required('Campo requerido'),
      healthInsurance: yup.string().required('Campo requerido'),
      plan: this.props.plans.length
        ? yup.string().required('Campo requerido')
        : yup.string().notRequired()
    })
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  render() {
    return (
      <FormContainer>
        <Formik
          onSubmit={this.props.onSubmit}
          initialValues={initialValues}
          validationSchema={this.getValidationSchema}
          render={renderProps => (
            <Form onSubmit={renderProps.handleSubmit}>
              <div>
                <Input
                  type="text"
                  name="name"
                  tabIndex ="0"
                  value={renderProps.values.name}
                  placeholder="Nombre"
                  white={1}
                  border={1}
                  warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
                />
                <ErrorMessage name="name" component={ErrorField} />
              </div>

              <div>
                <Input
                  type="text"
                  name="lastName"
                  tabIndex ="0"
                  value={renderProps.values.lastName}
                  placeholder="Apellido"
                  white={1}
                  border={1}
                  warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
                />
                <ErrorMessage name="lastName" component={ErrorField} />
              </div>

              <div>
                <Input
                  type="email"
                  name="email"
                  value={renderProps.values.email}
                  placeholder="Email"
                  tabIndex ="0"
                  white={1}
                  border={1}
                  warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
                />
                <ErrorMessage name="email" component={ErrorField} />
              </div>

              <div>
                <Input
                  type="email"
                  name="repeatEmail"
                  tabIndex ="0"
                  value={renderProps.values.repeatEmail}
                  placeholder="Confirmación de email"
                  white={1}
                  border={1}
                  warning={renderProps.errors.repeatEmail && renderProps.touched.repeatEmail ? 1 : 0}
                />
                <ErrorMessage name="repeatEmail" component={ErrorField} />
              </div>

              <div>
                <Select
                  component="select"
                  name="documentType"
                  tabIndex ="0"
                  warning={renderProps.errors.documentType && renderProps.touched.documentType ? 1 : 0}
                >
                  <option value="" hidden disabled>
                    Tipo de Documento
                  </option>
                  {this.props.documentTypes.map((dt, index) => {
                    return (
                      <option value={dt.id} key={index}>
                        {dt.valueText}
                      </option>
                    )
                  })}
                </Select>
                <ErrorMessage name="documentType" component={ErrorField} />
              </div>

              <div>
                <Input
                  type="text"
                  name="documentNumber"
                  tabIndex ="0"
                  value={renderProps.values.documentNumber}
                  placeholder="Número de Documento"
                  white={1}
                  border={1}
                  warning={renderProps.errors.documentNumber && renderProps.touched.documentNumber ? 1 : 0}
                />
                <ErrorMessage name="documentNumber" component={ErrorField} />
              </div>

              <div>
                <Select
                  component="select"
                  name="gender"
                  tabIndex ="0"
                  warning={renderProps.errors.gender && renderProps.touched.gender ? 1 : 0}
                >
                  <option value="" hidden disabled>
                    Sexo
                  </option>
                  {this.props.genders.map((gender, index) => {
                    return (
                      <option value={gender.id} key={index}>
                        {gender.valueText}
                      </option>
                    )
                  })}
                </Select>
                <ErrorMessage name="gender" component={ErrorField} />
              </div>

              <div>
                <PortalDatePicker
                  id="birthDate"
                  name="birthDate"
                  tabIndex ="0"
                  placeholder="Fecha de Nacimiento"
                  initialVisibleMonth={ () => moment().subtract(18, 'years')}
                  isOutsideRange={date => date.isAfter(moment().subtract(18, 'years').startOf('day').toDate(), 'day')}
                  renderProps={renderProps}
                  warning={renderProps.errors.birthDate && renderProps.touched.birthDate ? 1 : 0}
                />
              </div>

              <div>
                <Input
                  type="text"
                  name="credentialNumber"
                  tabIndex ="0"
                  value={renderProps.values.credentialNumber}
                  placeholder="Número de credencial"
                  white={1}
                  border={1}
                  warning={renderProps.errors.credentialNumber && renderProps.touched.credentialNumber ? 1 : 0}
                />
              <ErrorMessage name="credentialNumber" component={ErrorField} />
              </div>

              <div>
                <AutocompleteText 
                  name="healthInsurance"
                  id="healthInsurance"
                  tabIndex ="0"
                  placeholder="Obra Social/Prepaga"
                  options={this.props.healthInsurances}
                  warning={renderProps.errors.healthInsurance && renderProps.touched.healthInsurance ? 1 : 0}
                  white={1}
                  border={1}
                  assignFieldValue={(value) => {
                    renderProps.setFieldValue('healthInsurance', value)
                    renderProps.setFieldValue('plan', '')
                    this.props.onHealthInsuranceChange(value)
                  }}
                />
              </div>

              {this.props.plans.length !== 0 && (
                <CenterDiv>
                  <Select
                    component="select"
                    name="plan"
                    tabIndex ="0"
                    warning={renderProps.errors.plan && renderProps.touched.plan ? 1 : 0}
                  >
                    <option value="" hidden disabled>
                      Plan
                    </option>
                    {this.props.plans.map((plan, index) => {
                      return (
                        <option value={plan.codigo} key={index}>
                          {plan.descripcion}
                        </option>
                      )
                    })}
                  </Select>
                  <ErrorMessage name="plan" component={ErrorField} />
                </CenterDiv>
              )}
              <Button type="submit">Aceptar</Button>
            </Form>
          )}
        />
      </FormContainer>
    )
  }
}

export default FormSignUp

/**
 * Styles
 */
const FormContainer = styled.div``
const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 60px;
  margin: 0 auto;
  > button {
    grid-column: span 2;
    width: 320px;
    margin: auto;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    max-width: 320px;
    > button {
      grid-column: auto;
      width: 100%;
    }
  }
`
const CenterDiv = styled.div`
  grid-column: span 2;
  width: 330px;
  margin: auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-column: auto;
    width: 100%;
  }
`