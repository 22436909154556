import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'

import { Button, Input, LinkButton, Select, ErrorField, Checkbox } from '../styled/generics'
import { ADMINISTRATOR } from '../constants'

const initialValues = {
  roles: [],
  name: '',
  lastName: '',
  email: ''
}

class FormNewUser extends React.Component {
  state = { metadatas: [] }

  renderPermits = rolesSelected => {
    const roles = this.props.roles.filter(role =>
      rolesSelected.some(roleSelected => roleSelected === role.clave)
    )
    const permits = roles
      .map(role => role.permisos)
      .reduce((a, b) => a.concat(b), [])
      .filter(
        (permit, index, self) => index === self.findIndex(p => p.idPermiso === permit.idPermiso)
      )

    return permits.map((permit, index) => <Permit key={index}>{permit.nombre}</Permit>)
  }

  renderMetadataFields = formikProps =>
    this.state.metadatas.map((metadata, index) => this.getField(metadata, index, formikProps))

  getField = (item, index, formikProps) => {
    if (item.tipo === 'Dropdown') {
      return (
        <InputContainer key={index}>
          <Select
            component="select"
            name={item.clave}
            warning={formikProps.errors[item.clave] && formikProps.touched[item.clave] ? 1 : 0}
          >
            <option value="" hidden disabled>
              {item.nombre}
            </option>
            {item.opciones.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.valueText}
                </option>
              )
            })}
          </Select>
          {formikProps.errors[item.clave] &&
            formikProps.touched[item.clave] && (
              <ErrorField>{formikProps.errors[item.clave]}</ErrorField>
            )}
        </InputContainer>
      )
    }
    return (
      <InputContainer key={index}>
        <Input
          type="text"
          name={item.clave}
          placeholder={item.nombre}
          value={formikProps.values[item.clave]}
          white={1}
          border={1}
          warning={formikProps.errors[item.clave] && formikProps.touched[item.clave] ? 1 : 0}
        />
        {formikProps.errors[item.clave] &&
          formikProps.touched[item.clave] && (
            <ErrorField>{formikProps.errors[item.clave]}</ErrorField>
          )}
      </InputContainer>
    )
  }

  updateMetadatas = (rolesSelected, formikProps) => {
    const roles = this.props.roles.filter(role =>
      rolesSelected.some(roleSelected => roleSelected === role.clave)
    )

    const roleGroupsSelected = this.props.roleGroups.filter(roleGroup =>
      roles.map(role => role.grupoRol.clave).some(r => r === roleGroup.clave)
    )

    const metadatas = roleGroupsSelected
      .map(roleGroup => roleGroup.metadatos)
      .reduce((a, b) => a.concat(b), [])
      .filter(
        (metadata, index, self) =>
          index === self.findIndex(m => m.idMetadato === metadata.idMetadato) && metadata.visible
      )

    metadatas.forEach(
      metadata =>
        formikProps.values[metadata.clave] === undefined
          ? formikProps.setFieldValue(metadata.clave, '')
          : null
    )

    this.setState({ metadatas: metadatas })
  }

  validations = () => {
    const metadatasSchema = this.state.metadatas.map(metadata => {
      var obj
      //TODO: Check validation yup type
      obj = { key: metadata.clave, value: yup.string().required('Campo requerido') }
      return obj
    })

    return yup.object().shape({
      roles: yup.array().required(),
      name: yup.string().required('Campo requerido'),
      lastName: yup.string().required('Campo requerido'),
      email: yup
        .string()
        .email('Email no válido')
        .required('Campo requerido'),
      ...metadatasSchema.reduce((obj, item) => {
        obj[item.key] = item.value
        return obj
      }, {})
    })
  }

  render() {
    return (
      <Formik
        onSubmit={values => this.props.onSubmit(values, this.state.metadatas)}
        initialValues={initialValues}
        validationSchema={this.validations}
        render={renderProps => (
          <form onSubmit={renderProps.handleSubmit}>
            <FullWidthContainer>
              <CheckboxesContainer>
                {this.props.roles.map(item => (
                  <Checkbox
                    key={item.clave}
                    groupName="roles"
                    item={{ key: item.clave, name: item.nombre }}
                    onChange={this.updateMetadatas}
                  />
                ))}
              </CheckboxesContainer>
            </FullWidthContainer>
            {renderProps.values.roles.includes(ADMINISTRATOR) && (
              <Alert>
                Tené en cuenta que el usuario Administrador tendrá todos los permisos de Portal
              </Alert>
            )}
            <InputsContainer>
              <InputContainer>
                <Input
                  type="text"
                  name="name"
                  value={renderProps.values.name}
                  placeholder="Nombre"
                  white={1}
                  border={1}
                  warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
                />
                {renderProps.errors.name &&
                  renderProps.touched.name && <ErrorField>{renderProps.errors.name}</ErrorField>}
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  name="lastName"
                  value={renderProps.values.lastName}
                  placeholder="Apellido"
                  white={1}
                  border={1}
                  warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
                />
                {renderProps.errors.lastName &&
                  renderProps.touched.lastName && (
                    <ErrorField>{renderProps.errors.lastName}</ErrorField>
                  )}
              </InputContainer>
              <InputContainer>
                <Input
                  type="email"
                  name="email"
                  value={renderProps.values.email}
                  placeholder="Email"
                  white={1}
                  border={1}
                  warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
                />
                {renderProps.errors.email &&
                  renderProps.touched.email && <ErrorField>{renderProps.errors.email}</ErrorField>}
              </InputContainer>
              {this.state.metadatas.length > 0 && this.renderMetadataFields(renderProps)}
            </InputsContainer>
            {renderProps.values.roles.length > 0 && (
              <PermitsContainer>
                <Title>El usuario podrá ver</Title>
                {this.renderPermits(renderProps.values.roles)}
              </PermitsContainer>
            )}

            <ButtonsContainer>
              <LinkButton to="/inicio/usuarios">Cancelar</LinkButton>
              <Button type="submit">Aceptar</Button>
            </ButtonsContainer>
          </form>
        )}
      />
    )
  }
}

export default FormNewUser

/**
 * Styles
 */
const FullWidthContainer = styled.div`
  width: 100%;
  padding: 16px
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.greyFive};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    height: auto;
  }
`
const PermitsContainer = styled.div`
  width: 540px;
  margin: 0 auto 40px auto;
  background: ${props => props.theme.colors.greyFive};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
    margin-top: 30px;
    height: auto;
  }
`
const InputsContainer = styled.div`
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: column;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  margin-bottom: 31px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    margin-bottom: 0;
    &:not(:first-child) {
      margin-top: 31px;
    }
  }
`
const Alert = styled.span`
  color: ${props => props.theme.colors.redTwo};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: block;
  margin: 15px auto 0 auto;
`
const ButtonsContainer = styled.div`
  width: 540px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 11px;
    margin-top: 27px;
  }
`
const Title = styled.p`
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
`
const Permit = styled.span`
  font-size: 16px;
  line-height: 27px;
  color: ${props => props.theme.colors.warmGreyTwo};
`
const CheckboxesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 800px;
  height: 100%;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    flex-direction: column;
    padding: 15px 10px;
    align-items: flex-start;
  }
`
