import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'
import to from 'await-catch'
import moment from 'moment'
import { AutocompleteText } from '../form-components/autocomplete'
import { Button, ErrorField, Input, Select } from '../styled/generics'
import PatientDetails from './PatientDetails'
import { PortalDatePicker } from '../form-components/datePicker'
import {
  getGenders,
  getDocumentTypes,
  getPediatricPatientData,
  getHealthInsurances,
  getHealthInsurancePlans
} from '../api'

/**
 *
 * Component in case it is required to enter as pediatrics
 * This component will search for the minor's document and in the case of not finding it
 * will register it
 *
 * This component must be called within Formik or within a form that provides the same values
 *
 * @param {object} formikRenderProps property, required, a formik render object
 * @param {function} onSearchChanged function in case you need to see when the search input changes
 * @param {function} onSearchPediatricPatient function that will be triggered when searching for a document
 * @returns
 */
export class PediatricPatientSearch extends React.Component {
  state = {
    controlResetForm: true,
    patientSearched: false,
    patientError: false,
    patientResult: null,
    genders: [],
    documentTypes: [],
    healthInsurances: [],
    plans: []
  }

  async componentDidMount() {
    const [
      err,
      [{ data: genders } = {}, { data: documentTypes } = {}, { data: healthInsurances } = {}]
    ] = await to([getGenders(), getDocumentTypes(), getHealthInsurances()])

    if (!err) {
      this.setState({
        documentTypes,
        genders,
        healthInsurances
      })
    }
  }

  componentDidUpdate() {
    if (this.props.patientError && this.state.controlResetForm && !!this.props.resetSignUpFields && this.props.formikRenderProps) {
      const documentType = this.props.formikRenderProps.values.documentType
      const documentNumber = this.props.formikRenderProps.values.documentNumber
      
      this.props.resetSignUpFields(this.props.formikRenderProps)
      this.handleSubmitSearch(documentType, documentNumber)
      this.setState({
        controlResetForm: false
      })
    }
  }

  handleSubmitSearch = async (tipoDoc, nroDoc) => {
    this.setState({
      controlResetForm: true
    })
    if (tipoDoc && nroDoc) {
      const [err, { data: patientResult } = {}] = await to(
        getPediatricPatientData(tipoDoc, nroDoc, 0)
      )
      if (!err) {
        
        if (patientResult) {
          patientResult.numeroDocumento = nroDoc
        }
        this.setState({ patientResult, patientSearched: true, patientError: false })
        this.props.onSearchPediatricPatient({
          patientResult,
          patientSearched: true,
          patientError: false
        })
      } else {
        this.setState({ patientSearched: true, patientError: true })
        this.props.onSearchPediatricPatient({ patientSearched: true, patientError: true })
      }
    }
  }

  handleHealthInsuranceChange = async value => {
    const [err, { data } = {}] = await to(getHealthInsurancePlans(value))
    if (!err) {
      this.setState({ plans: data })
    }
  }

  handleSearchChanged = () => {
    this.setState({
      patientSearched: false,
      patientResult: null,
      patientError: false
    })

    if (!!this.props.onSearchChanged) {
      this.props.onSearchChanged({
        patientSearched: false,
        patientResult: null,
        patientError: false
      })
    }
  }

  isNewPatient = () => {
    return this.state.patientSearched && !this.state.patientResult && !this.state.patientError
  }

  /**
   * Register a pediatric patient
   *
   * @param {object} renderProps  property, required, a formik render object
   * @returns
   */
  renderPediatricPatientSignUpFields = renderProps => {
    return (
      <React.Fragment>
        <SignUpTitle>Por favor, completá los datos del paciente que va a atenderse</SignUpTitle>
        <SignUpContainer inputWidth={this.props.inputWidth}>
          <div>
            <Input
              type="text"
              name="name"
              tabIndex="0"
              value={renderProps.values.name}
              placeholder="Nombre"
              white={1}
              border={1}
              warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
            />
            <ErrorMessage name="name" component={ErrorField} />
          </div>

          <div>
            <Input
              type="text"
              name="lastName"
              tabIndex="0"
              value={renderProps.values.lastName}
              placeholder="Apellido"
              white={1}
              border={1}
              warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
            />
            <ErrorMessage name="lastName" component={ErrorField} />
          </div>

          <div>
            <Input
              type="email"
              name="email"
              value={renderProps.values.email}
              placeholder="Email"
              tabIndex="0"
              white={1}
              border={1}
              warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
            />
            <ErrorMessage name="email" component={ErrorField} />
          </div>

          <div>
            <Input
              type="email"
              name="repeatEmail"
              tabIndex="0"
              value={renderProps.values.repeatEmail}
              placeholder="Reconfirmación de email"
              white={1}
              border={1}
              warning={renderProps.errors.repeatEmail && renderProps.touched.repeatEmail ? 1 : 0}
            />
            <ErrorMessage name="repeatEmail" component={ErrorField} />
          </div>

          <div>
            <Select
              component="select"
              name="gender"
              tabIndex="0"
              warning={renderProps.errors.gender && renderProps.touched.gender ? 1 : 0}
            >
              <option value="" hidden disabled>
                Sexo
              </option>
              {this.state.genders.map((gender, index) => {
                return (
                  <option value={gender.id} key={index}>
                    {gender.valueText}
                  </option>
                )
              })}
            </Select>
            <ErrorMessage name="gender" component={ErrorField} />
          </div>

          <div>
            <PortalDatePicker
              id="birthDate"
              name="birthDate"
              tabIndex="0"
              placeholder="Fecha de Nacimiento"
              initialVisibleMonth={() => moment().subtract(18, 'years')}
              isOutsideRange={date =>
                date.isBefore(
                  moment()
                    .subtract(18, 'years')
                    .startOf('day')
                    .toDate(),
                  'day'
                ) ||
                date.isAfter(
                  moment()
                    .endOf('day')
                    .toDate(),
                  'day'
                )
              }
              warning={renderProps.errors.birthDate && renderProps.touched.birthDate ? 1 : 0}
              renderProps={renderProps}
            />
          </div>

          <div>
            <Input
              type="text"
              name="credentialNumber"
              tabIndex="0"
              value={renderProps.values.credentialNumber}
              placeholder="Número de credencial"
              white={1}
              border={1}
              warning={
                renderProps.errors.credentialNumber && renderProps.touched.credentialNumber ? 1 : 0
              }
            />
            <ErrorMessage name="credentialNumber" component={ErrorField} />
          </div>

          <div>
            <AutocompleteText
              name="healthInsurance"
              id="healthInsurance"
              tabIndex="0"
              placeholder="Obra Social/Prepaga"
              options={this.state.healthInsurances}
              warning={
                renderProps.errors.healthInsurance && renderProps.touched.healthInsurance ? 1 : 0
              }
              white={1}
              border={1}
              assignFieldValue={value => {
                renderProps.setFieldValue('healthInsurance', value)
                renderProps.setFieldValue('plan', '')
                this.handleHealthInsuranceChange(value)
              }}
            />
          </div>

          {this.state.plans.length !== 0 && (
            <CenterDiv>
              <Select
                component="select"
                name="plan"
                tabIndex="0"
                warning={renderProps.errors.plan && renderProps.touched.plan ? 1 : 0}
              >
                <option value="" hidden disabled>
                  Plan
                </option>
                {this.state.plans.map((plan, index) => {
                  return (
                    <option value={plan.codigo} key={index}>
                      {plan.descripcion}
                    </option>
                  )
                })}
              </Select>
              <ErrorMessage name="plan" component={ErrorField} />
            </CenterDiv>
          )}
        </SignUpContainer>
      </React.Fragment>
    )
  }

  /**
   * Show pediatric patient data or call renderPediatricPatientSignUpFields (),
   * who is in charge of registering a pediatric patient
   *
   * @param {object} renderProps  property, required, a formik render object
   * @returns
   */
  renderPatientSection = renderProps => {
    if (this.isNewPatient()) {
      return this.renderPediatricPatientSignUpFields(renderProps)
    } else if (!!this.state.patientResult) {
      return (
        <React.Fragment>
          <P>Por favor, verificá los datos antes de continuar</P>
          <PatientDetails patient={this.state.patientResult} showButtons={false} />
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <React.Fragment>
        <P>Ingresá el tipo y número de documento del paciente</P>
        <SearchContainer width={this.props.width} celWidth={this.props.celWidth}>
          <div>
            <Select
              type="documentType"
              component="select"
              name="documentType"
              tabIndex="0"
              warning={
                this.props.formikRenderProps.errors.documentType &&
                this.props.formikRenderProps.touched.documentType
                  ? 1
                  : 0
              }
              onChange={e => {
                this.props.formikRenderProps.handleChange(e)
                this.handleSearchChanged()
              }}
            >
              <option value="" hidden disabled>
                Tipo
              </option>
              {this.state.documentTypes.map((dt, index) => {
                return (
                  <option value={dt.id} key={index}>
                    {dt.valueText}
                  </option>
                )
              })}
            </Select>
            <ErrorMessage name="documentType" component={ErrorField} />
          </div>

          <div>
            <Input
              type="text"
              name="documentNumber"
              tabIndex="0"
              value={this.props.formikRenderProps.values.documentNumber}
              placeholder="Número de Documento"
              white={1}
              border={1}
              warning={
                this.props.formikRenderProps.errors.documentNumber &&
                this.props.formikRenderProps.touched.documentNumber
                  ? 1
                  : 0
              }
              onChange={e => {
                this.props.formikRenderProps.handleChange(e)
                this.handleSearchChanged()
              }}
            />
            <ErrorMessage name="documentNumber" component={ErrorField} />
          </div>

          <div>
            <Button
              type="button"
              disabled={
                (!this.props.formikRenderProps.touched.documentType &&
                  !this.props.formikRenderProps.touched.documentNumber) ||
                (this.props.formikRenderProps.errors.documentType ||
                  this.props.formikRenderProps.errors.documentNumber)
              }
              onClick={() =>
                this.handleSubmitSearch(
                  this.props.formikRenderProps.values.documentType,
                  this.props.formikRenderProps.values.documentNumber
                )
              }
            >
              Buscar
            </Button>
          </div>
        </SearchContainer>

        {this.state.patientSearched &&
          !this.props.patientError &&
          this.renderPatientSection(this.props.formikRenderProps)}
      </React.Fragment>
    )
  }
}
PediatricPatientSearch.propTypes = {
  formikRenderProps: PropTypes.object.isRequired
}

const SearchContainer = styled.div`
  width: ${props => (!!props.width ? props.width : '50%')};
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 30px 30px;
  margin: 0 auto;

  select {
    width: 180;
    margin: 15px 0 5px 0;
  }
  input {
    margin: 15px 0 5px 0;
  }
  button {
    width: 180px;
    margin: 15px 0 5px 0;
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    max-width: ${props => (!!props.celWidth ? props.celWidth : '320px')};
    > button {
      grid-column: auto;
      width: 100%;
    }
  }
`

const P = styled.p`
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
  text-align: center;
`

const CenterDiv = styled.div`
  grid-column: span 2;
  width: 330px;
  margin: auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-column: auto;
    width: 100%;
  }
`

const SignUpContainer = styled.div`
  width: ${props => (!!props.inputWidth ? props.inputWidth : '50%')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 30px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    max-width: 320px;
  }
`

const SignUpTitle = styled.p`
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 10;
  text-align: center;
`
