export const DUTIES = 'Aranceles'
export const HOSPITALIZED_PATIENTS = 'PacientesInternados'
export const FUTURE_APPOINTMENTS = 'TurnosFuturos'
export const LABORATORIES = 'Laboratorios'
export const USERS = 'Usuarios'
export const ROLES = 'Roles'
export const MEGOLI = 'Megoli'
export const PREGNANCYPLANNER_USER_PERMIT = 'PregnancyPlannerUser'
export const PREGNANCYPLANNER_ADMIN_PERMIT = 'PregnancyPlannerAdmin'
export const BILLING_PERMIT = 'Facturacion'
export const REPORT_PERMIT = 'Reportes'
export const LOGS = 'Logs'