import React from 'react'
import styled, { css } from 'styled-components'
import { Field } from 'formik'
import { Link } from 'react-router-dom'
import AddToCalendar from 'react-add-to-calendar'

const baseComponent = css`
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  height: ${props => props.theme.sizes.forms.height};
`
const baseButton = css`
  background: ${props => props.theme.colors.green};
  border: 1px solid ${props => props.theme.colors.green};
  ${baseComponent} color: white;
  &:hover {
    background: ${props => props.theme.colors.greenThree};
  }
  ${props =>
    props.outline &&
    css`
      border: 1px solid ${props => (props.green ? props.theme.colors.green : `white`)};
      color: ${props => (props.green ? props.theme.colors.green : `white`)};
      background: transparent;
      &:hover {
        background: ${props => props.theme.colors.greenThree};
        color: white;
      }
    `};
  ${props =>
    props.warning &&
    css`
      background: ${props => props.theme.colors.redTwo};
      border-color: ${props => props.theme.colors.redTwo};
      &:hover {
        background: ${props => props.theme.colors.redTwo};
      }
    `};
  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.grey};
      border-color: ${props => props.theme.colors.grey};
      &:hover {
        background: ${props => props.theme.colors.grey};
        cursor: not-allowed;
      }
    `};
`

export const ErrorField = styled.div`
  color: ${props => props.theme.colors.redTwo};
  text-align: left;
  padding-left: 10px;
`

export const ErrorFieldLabel = styled.div`
  display: block;
  color: ${props => props.theme.colors.redTwo};
  text-align: left;
  margin: 5px 0 0 5px;
  font-size: 14px;
`

export const Textarea = styled(Field)`
  ${baseComponent};
  padding: 10px;
  height: 260px;
  resize: none;
  border: none;

  ${props =>
    props.border &&
    css`
      border: 1px solid ${props => props.theme.colors.green};
    `};

    ${props =>
      props.warning &&
      css`
        border: 1px solid ${props => props.theme.colors.redTwo};
        color: ${props => props.theme.colors.redTwo};
      `};
`

export const Input = styled(Field)`
  background: ${props => props.theme.colors.cloudyBlueThree};
  color: ${props => props.theme.colors.warmGreyTwo};
  ${baseComponent} border: none;

  &:focus::placeholder {
    color: transparent;
    font-weight: lighter;
  }

  ${props =>
    props.white &&
    css`
      background: white;
    `};

  ${props =>
    props.border &&
    css`
      border: 1px solid ${props => props.theme.colors.green};
    `};

  ${props =>
    props.warning &&
    css`
      border: 1px solid ${props => props.theme.colors.redTwo};
      color: ${props => props.theme.colors.redTwo};
    `};
`

const ContainerPasswordInput = styled.div`

  color: ${props => props.theme.colors.warmGreyTwo};
  &:focus::placeholder {
    color: transparent;
    font-weight: lighter;
  }
  max-height: 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  ${props =>
    props.white &&
    css`
      background: white;
    `};
  ${props =>
    props.border &&
    css`
      border: 1px solid ${props => props.theme.colors.green};
    `};
  ${props =>
    props.warning &&
    css`
      border: 1px solid ${props => props.theme.colors.redTwo};
      color: ${props => props.theme.colors.redTwo};
    `};
  margin-top: 40px;
`;

const TextInputPassword = styled(Field)`
  flex: 1 0;
  min-width: 50px;
  min-height: 25px;
  font-size: inherit;
  background-color: transparent;
  padding-left: 5px;
  border: 0;
  &:focus {
    outline: none;
  }
  margin-top: 0px !important;
`;

const IconInputPassword = styled.div`
  margin-top: 5px;
  cursor: pointer;
`;

export const InputPassword = (props) => {
  const {type, name, value, placeholder, iconName, warning, handleSeePaswordChange, white, border, theme} = props;
  return (
    <ContainerPasswordInput white={white} border={border} theme={theme}>
      <TextInputPassword
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        warning={warning}
      />
      <IconInputPassword>
        <MaterialIcon 
          name={iconName}
          onClick={() => {
            handleSeePaswordChange()
          }}
        />
      </IconInputPassword>
    </ContainerPasswordInput>
  );
};

//Fill colors in svg are hardcoded because of deprecation warnings for unescaped # in data URI body
export const Select = styled(Field)`
 color: ${props => (props.warning ? props.theme.colors.redTwo : props.theme.colors.warmGreyTwo)};
  border: 1px solid ${props =>
    props.warning ? props.theme.colors.redTwo : props.theme.colors.green};
  ${baseComponent};
  border-radius: 0; 
  appearance: none;
  background: white url("data:image/svg+xml;utf8,<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' enable-background='new 0 0 24 24' xml:space='preserve'> <path d='M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z'
  fill='${props =>
    props.warning ? '%23700210' : '%2304705e'}'/><path fill='none' d='M0,0h24v24H0V0z'/> </svg>")
    100% 7px no-repeat;
  padding-right: 5px;
  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.grey};
      color: ${props => props.theme.colors.greyThree};
      border: 1px solid ${props => props.theme.colors.greyThree};
    `}
`

export const SmallCalendarSelect = styled.select`
  color: ${props => props.theme.colors.green};
  border: 0;
  background: white;
  font-size: 13px;
  padding: 0 10px;
`

export const Button = styled.button`
  ${baseButton};
`
export const BaseLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LinkButton = styled(Link)`
  ${baseButton} text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Paragraph = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};
  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `};
`

export const MaterialIcon = props => (
  <i className={`material-icons`} {...props}>
    {props.name}
  </i>
)

export const Checkbox = props => (
  <Field name={props.groupName}>
    {({ field, form }) => (
      <CheckboxContainer warning={form.errors[props.groupName] ? 1 : 0}>
        <input
          type="checkbox"
          checked={field.value.includes(props.item.key)}
          id={props.item.key}
          onChange={() => {
            const nextValues = field.value.includes(props.item.key)
              ? field.value.filter(value => value !== props.item.key)
              : field.value.concat(props.item.key)
            form.setFieldValue(props.groupName, nextValues)
            if (typeof props.onChange === 'function') {
              props.onChange(nextValues, form)
            }
          }}
        />
        <CheckboxLabel htmlFor={props.item.key}>{props.item.name}</CheckboxLabel>
      </CheckboxContainer>
    )}
  </Field>
)

export const CheckboxLabel = styled.label`
  position: relative;
  padding-left: 24px;
  display: inline-block;
  color: ${props => props.theme.colors.warmGreyTwo};
  ::before {
    height: 15px;
    width: 15px;

    border: 1px solid ${props => props.theme.colors.green};
    background-color: white;
    left: 0;
    top: 2px;
  }
  ::after {
    height: 6px;
    width: 9px;
    border-left: 2px solid ${props => props.theme.colors.green};
    border-bottom: 2px solid ${props => props.theme.colors.green};

    transform: rotate(-45deg);

    left: 3px;
    top: 5px;
  }
  ::before,
  ::after {
    position: absolute;
    content: '';
    display: inline-block;
  }
  &:hover::before {
    background-color: ${props => props.theme.colors.grey};
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  > input {
    opacity: 0;
  }
  & > input[type='checkbox'] + label::after {
    content: none;
  }
  & > input[type='checkbox']:checked + label {
    font-weight: 600;
    color: ${props => props.theme.colors.green};
  }
  & > input[type='checkbox']:checked + label::after {
    content: '';
  }
  & > input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
  ${props =>
    props.warning &&
    css`
      & > input[type='checkbox'] + label::before {
        border: 1px solid ${props => props.theme.colors.redTwo};
        background-color: ${props => props.theme.colors.red};
      }
    `};
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props => props.marginTop};
    `};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-bottom: 10px;
  }
`

export const MobileFullWidth = styled.div`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  input[type='radio'] {
    opacity: 0;
  }

  input[type='radio'] + label::after {
    content: none;
  }
  input[type='radio']:checked + label::after {
    content: '';
  }
  :hover > label::before {
    background: ${props => props.theme.colors.greenFour};
  }
  ${props =>
    props.right &&
    css`
      margin-left: 100px;
    `};
`

export const RadioLabel = styled.label`
  margin-left: 5px;
  color: ${props => props.theme.colors.warmGreyTwo};
  position: relative;
  ::before {
    position: absolute;
    top: 3px;
    left: -22px;
    content: '';
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.green};
    background: white;
  }
  ::after {
    left: -18px;
    top: 7px;
    position: absolute;
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${props => props.theme.colors.greenThree};
  }
`

export const Hr = styled.hr`
  width: ${props => !!props.width ? props.width : 100}%;
  margin: auto;
  margin-top: ${props => !!props.marginTop ? props.marginTop : 0}px;
  margin-bottom: ${props => !!props.marginBottom ? props.marginBottom : 0}px;
  background: ${props => !!props.color ? props.color : props.theme.colors.grey};
`

export const AddToCalendarButton = props => (
  <AddToCalendarButtonWrapper {...props}>
    <AddToCalendar event={props.event} buttonLabel="Agregar a mi calendario" />
  </AddToCalendarButtonWrapper>
)

const AddToCalendarButtonWrapper = styled.div`
  .react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: block;
    width: 320px;
    margin-top: 40px;
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      width: 100%;
      margin-top: 20px;
    }

    &__wrapper {
      zoom: 1;
      cursor: pointer;
    }

    &__button {
      ${baseButton};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      &--right {
        padding-left: 5px;
      }

      &--left {
        padding-right: 5px;
      }
    }

    &__dropdown {
      position: absolute;
      width: 100%;
      padding: 5px 0 5px 8px;
      box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid ${props => props.theme.colors.green};
      background-color: #fff;
      text-align: left;

      ul {
        list-style: none;
        margin: 0;
        padding-left: 63px;
        li {
          display: flex;
          height: 25px;
          align-items: center;
          a {
            color: #000;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            i {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
`
export const CustomSingleDatePicker = styled.div`
  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput {
    border: 0;
    background: transparent !important;
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      display: flex;
      align-items: center;
    }
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${props => props.theme.colors.green};
  }

  .DateInput {
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      flex: 1;
    }
  }

  .DateInput_input {
    ${props => props.formHeight &&
      css`
        height: 40px !important;
    `};
    border: 1px solid ${props => props.theme.colors.green};
    font-size: 16px;
    text-align: ${props => props.contentAlignment};
    color: ${props => props.theme.colors.green};
    ${props =>
      props.warning &&
      css`
        border: 1px solid ${props => props.theme.colors.redTwo};
        color: ${props => props.theme.colors.redTwo};
      `};
  }

  .DayPickerNavigation_button {
    border: 0;
    background: white;
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${props => props.theme.colors.green};
  }

  .CalendarMonth_caption {
    color: ${props => props.theme.colors.green};
  }

  .DayPicker_weekHeader {
    color: ${props => props.theme.colors.greySix};
    text-transform: capitalize;
  }

  .CalendarDay__default {
    border: 1.3px solid white;
    &:hover {
      border: 1.3px solid white;
    }
  }

  .CalendarDay__blocked_out_of_range {
    &:hover {
      background: transparent;
      border: 0;
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected_span {
    background: ${props => props.theme.colors.green};
    font-weight: bold;
  }
`

export const CustomDateRangePicker = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    border: 0;
    background: transparent !important;
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      display: flex;
      align-items: center;
    }
  }
  .DateRangePickerInput_arrow {
    padding: 0 3px;
  }
  .DateRangePickerInput_arrow_svg {
    fill: ${props => props.theme.colors.greySix};
  }
  .DateInput {
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      flex: 1;
    }
  }
  .DateInput_input {
    border: 1px solid ${props => props.theme.colors.greySix};
    font-size: 16px;
    text-align: center;
    color: ${props => props.theme.colors.greyThree};
    border-radius: 4px;
    ${props =>
      props.warning &&
      css`
        border: 1px solid ${props => props.theme.colors.redTwo};
        color: ${props => props.theme.colors.redTwo};
      `};
  }
  .DayPickerNavigation_button {
    border: 0;
    background: white;
  }
  .DayPickerNavigation_svg__horizontal {
    fill: ${props => props.theme.colors.greySix};
  }
  .CalendarMonth_caption {
    color: ${props => props.theme.colors.greySix};
  }
  .DayPicker_weekHeader {
    color: ${props => props.theme.colors.greySix};
    text-transform: capitalize;
  }
  .CalendarDay__default {
    border: 1.3px solid white;
    &:hover {
      border: 1.3px solid white;
    }
  }
  .CalendarDay__blocked_out_of_range {
    &:hover {
      background: transparent;
      border: 0;
    }
  }
  .CalendarDay__selected,
  .CalendarDay__selected_span {
    background: ${props => props.theme.colors.greySix};
    font-weight: bold;
  }
`