import React from 'react'
import to from 'await-catch'

import { getPatientMegoli, getClinicsDetailed, getMOLIHours, signUpMW } from '../api'

import { CLINICS_ID, MEGOLI_PEDIATRIA } from '../constants'

import Layout from './shared/Layout'
import FormMegoliQuestions from './FormMegoliQuestions'
import MegoliRequirementsModal from './MegoliRequirementsModal'
import withPatient from '../hoc/withPatient'

class MegoliQuestions extends React.Component {
  state = {
    // Megoli
    clinics: [],
    selectableClinics: [],
    selectedSpec: null,
    megoliSpecHours: null,
    showModal: false,
    dataFetched: false,

    // Sign Up
    patientSearched: false,
    patientResult: null,
    patientError: false
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    const [err, [{ data: clinicsData } = {}, { data: megoliSpecHours }]] = await to([
      getClinicsDetailed(),
      getMOLIHours()
    ])
    if (!err) {
      this.setState(
        {
          clinics: clinicsData.filter(
            clinic => clinic.id !== CLINICS_ID.CentroBazte && clinic.id !== CLINICS_ID.CentroRita
          ),
          megoliSpecHours: megoliSpecHours
        },
        () => {
          // Busco si hay alguna disponible y la asigno como default
          var available = megoliSpecHours.find(spec => spec.disponible)
          if (available) {
            this.setState({
              selectableClinics: this.getFilteredClinicsBySpec(available),
              selectedSpec: available,
              showModal: true,
              dataFetched: true
            })
          } else {
            this.setState({
              dataFetched: true
            })
          }
        }
      )
    }
  }

  handleSearchChanged = props => {
    this.setState({ ...props })
  }

  isPediatria = () => {
    return this.state.selectedSpec && this.state.selectedSpec.idEspecialidad === MEGOLI_PEDIATRIA
    //&& this.state.selectedSpec.disponible
  }

  canConnect = () => {
    return !(this.isPediatria() && !this.state.patientSearched) && !this.state.patientError
  }

  isNewPatient = () => {
    return (
      this.isPediatria() &&
      this.state.patientSearched &&
      !this.state.patientResult &&
      !this.state.patientError
    )
  }

  handlePediatricPatientSearch = props => {
    this.setState({ ...props })
  }

  handleNeedResetPediatricPatientForm = () => {
    this.setState({
      resetPediatricForm: true
    })
  }

  getFilteredClinicsBySpec = spec => {
    //TODO: change logic if more specialities/restrictions are added
    return spec.idEspecialidad === MEGOLI_PEDIATRIA
      ? this.state.clinics.filter(clinic => clinic.id !== CLINICS_ID.DelSol)
      : this.state.clinics
  }

  handleSpecialitySelected = value => {
    const selected = this.state.megoliSpecHours.find(s => s.idEspecialidad === value)
    const filteredClinics = this.getFilteredClinicsBySpec(selected)

    this.setState({
      selectedSpec: selected,
      selectableClinics: filteredClinics,
      patientSearched: false,
      patientResult: null,
      patientError: false
    })
  }

  renderSpecHours = () => {
    return (
      <React.Fragment>
        {this.state.megoliSpecHours.map((specHour, index) => {
          const separatorBegin = index && index < this.state.megoliSpecHours.length - 1 ? ', ' : ''
          const separatorEnd = index === this.state.megoliSpecHours.length - 2 ? ' y ' : ''
          const textSpec = `para ${specHour.especialidad.toLowerCase()} `
          const textSpecHours = `de ${specHour.descripcionHorario.toLowerCase()}`
          const displayText = `${separatorBegin} ${textSpec} ${textSpecHours}${separatorEnd}`

          return <span key={index}>{displayText}</span>
        })}
      </React.Fragment>
    )
  }

  submitQuestions = async values => {
    if (this.isPediatria()) {
      values.withUser = true
      if (this.isNewPatient()) {
        let newErid = await this.createMindwareUser(values)
        if (newErid > 0) {
          // load the new erid code
          values.erid = newErid
        } else {
          return
        }
      } else {
        // load previously found user
        values.email = this.state.patientResult.mail
        values.name = this.state.patientResult.nombres
        values.lastName = this.state.patientResult.apellidos
        values.erid = this.state.patientResult.pacienteERID
      }
    }

    // Finally, connect to MOLI
    const [err, { data } = {}] = await to(getPatientMegoli(values))
    if (!err) {
      window.open(data, '_blank')
      this.props.history.push('/')
    } else {
      // if conexion fails but the user was created, reload user
      if (this.isPediatria() && this.isNewPatient()) {
        this.setState({ patientError: true })
      }
    }
  }

  createMindwareUser = async values => {
    const paramsMW = {
      mail: values.email,
      nombres: values.name,
      apellidos: values.lastName,
      tipoDocumento: values.documentType,
      documento: values.documentNumber,
      sexo: values.gender,
      dob: values.birthDate,
      nroAfiliado: values.credentialNumber,
      obraSocial: values.healthInsurance,
      plan: values.plan
    }

    const [err, { data } = {}] = await to(signUpMW(paramsMW))
    if (!err && !!data) {
      return data.pacienteERID
    } else {
      return -1
    }
  }

  handleReturn = () => {
    this.props.history.push('/')
  }

  closeModal = () => this.setState({ showModal: false })

  render() {
    return (
      <React.Fragment>
        <Layout topSpace title="Videoconsulta de guardia">
          {this.state.dataFetched && (
            <FormMegoliQuestions
              clinics={this.state.selectableClinics}
              phone={this.props.patient.telefono ? this.props.patient.telefono : ''}
              specHours={this.state.megoliSpecHours}
              selectedSpec={this.state.selectedSpec}
              renderSpecHours={this.renderSpecHours}
              onSpecialitySelected={this.handleSpecialitySelected}
              canConnect={this.canConnect}
              isPediatria={this.isPediatria}
              onSearchPediatricPatient={this.handlePediatricPatientSearch}
              onSearchChanged={this.handleSearchChanged}
              isNewPatient={this.isNewPatient}
              onSubmit={this.submitQuestions}
              onReturn={this.handleReturn}
              patientError={this.state.patientError}
            />
          )}
        </Layout>
        {this.state.showModal && (
          <MegoliRequirementsModal
            action={this.closeModal}
            renderSpecHours={this.renderSpecHours}
          />
        )}
      </React.Fragment>
    )
  }
}
export default withPatient(MegoliQuestions)
