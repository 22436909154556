import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import to from 'await-catch'

import { Input, Button, ErrorField } from '../styled/generics'
import Layout from './shared/Layout'
import { restoreAccount } from '../api'
import ModalMessage from './ModalMessage'

const initialValues = { email: '' }
const validations = yup.object().shape({
  email: yup
    .string()
    .email('Email no válido')
    .required('Campo requerido')
})

class FormForgotPassword extends React.Component {
  state = { modalOpen: false }
  submitForm = async ({ email }) => {
    const [err] = await to(restoreAccount(email))
    if (!err) {
      this.toggleModal()
    }
  }
  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen })
  closeModal = () => this.props.history.push('/')
  render() {
    return (
      <React.Fragment>
        <Layout topSpace title="Recuperar contraseña" subTitle="Ingresá tu mail para recibir las indicaciones.">
          <Container>
            <Formik
              onSubmit={this.submitForm}
              initialValues={initialValues}
              validationSchema={validations}
              render={renderProps => (
                <Form onSubmit={renderProps.handleSubmit}>
                  <Input
                    type="email"
                    name="email"
                    value={renderProps.values.email}
                    placeholder="Email"
                    warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
                    white={1}
                    border={1}
                  />
                  {renderProps.touched.email &&
                    renderProps.errors.email && <ErrorField>{renderProps.errors.email}</ErrorField>}
                  <Button type="submit">Recuperar</Button>
                </Form>
              )}
            />
          </Container>
        </Layout>
        {this.state.modalOpen && (
          <ModalMessage
            iconCode="check_circle"
            message="Te enviamos un mail. Si no lo recibís, revisá el correo no deseado."
            button={{ action: this.closeModal, label: "Aceptar" }}
          />
        )}
      </React.Fragment>
    )
  }
}

export default FormForgotPassword

/*
 * Styles
 */
const Container = styled.div`
  padding: 20px;
  background: ${props => props.theme.colors.cloudyBlueThree};
`
const Form = styled.form`
  button[type='submit'] {
    margin-top: 20px;
  }
  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 370px;
    margin: auto;
    button[type='submit'] {
      width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
