import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import to from 'await-catch'
import {withRouter} from 'react-router-dom';


import { InputPassword, Button, ErrorField } from '../styled/generics'
import Layout from './shared/Layout'
import { changePassword, changeExistingPassword } from '../api'
import ModalMessage from './ModalMessage'

const initialValues = { password: '', repeatPassword: '' }
const validations = yup.object().shape({
  password: yup
    .string()
    .min(6, 'El campo debe contener al menos 6 caracteres')
    .required('Campo requerido'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Campo requerido')
})

class FormResetPassword extends React.Component {
  state = { 
    modalOpen: false,
    seePassWord: false,
    seeRepeatPassword: false
  }

  submitForm = async ({ password }) => {
    if (this.props.idUsuario){
      // contraseña cambiada desde portal
      const [err] = await to(changeExistingPassword(this.props.idUsuario, password))
      if (!err) {
        this.toggleModal()
      }
    }
    else
    {
      // constraseña cambiada desde email
      const hash = this.props.location.search.slice(this.props.location.search.indexOf('=') + 1)
      const [err] = await to(changePassword(hash, password))
      if (!err) {
        this.toggleModal()
      }
    }
  }

  toggleModal = () => this.setState({
    modalOpen: !this.state.modalOpen 
  })
  closeModal = () => 
  {
    if (this.props.idUsuario){
      this.props.history.push('/inicio')
    }
    else
    {
      this.props.history.push('/')
    }
  }

  handleSeePaswordChange = (value) => {
    if(value === "password"){
      this.setState({
        seePassWord: !this.state.seePassWord 
      })
    } else if(value === "repeatPassword"){
      this.setState({
        seeRepeatPassword: !this.state.seeRepeatPassword 
      })
    }
  } 

  render() {
    return (
      <React.Fragment>
        <Layout topSpace title="Cambiar contraseña" subTitle="Ingrese una nueva contraseña">
          <Container>
            <Formik
              onSubmit={this.submitForm}
              initialValues={initialValues}
              validationSchema={validations}
              render={renderProps => (
                <Form onSubmit={renderProps.handleSubmit}>
                  <InputPassword
                    type={this.state.seePassWord ? 'text' : 'password'}
                    name="password"
                    value={renderProps.values.password}
                    placeholder="Contraseña"
                    iconName={this.state.seePassWord ? 'visibility' : 'visibility_off'}
                    warning={renderProps.errors.password && renderProps.touched.password ? 1 : 0}
                    handleSeePaswordChange={()=>{this.handleSeePaswordChange("password")}}
                    white={1}
                    border={1}
                  />
                  {renderProps.touched.password &&
                    renderProps.errors.password && (
                      <ErrorField>{renderProps.errors.password}</ErrorField>
                    )}
                  <InputPassword
                    type={this.state.seeRepeatPassword ? 'text' : 'password'}
                    name="repeatPassword"
                    value={renderProps.values.repeatPassword}
                    placeholder="Repetir contraseña"
                    iconName={this.state.seeRepeatPassword ? 'visibility' : 'visibility_off'}
                    warning={
                      renderProps.errors.repeatPassword && renderProps.touched.repeatPassword
                        ? 1
                        : 0
                    }
                    handleSeePaswordChange={()=>{this.handleSeePaswordChange("repeatPassword")}}
                    white={1}
                    border={1}
                  />
                  {renderProps.touched.repeatPassword &&
                    renderProps.errors.repeatPassword && (
                      <ErrorField>{renderProps.errors.repeatPassword}</ErrorField>
                    )}
                  <Button type="submit">Continuar</Button>
                </Form>
              )}
            />
          </Container>
        </Layout>
        {this.state.modalOpen && (
          <ModalMessage
            iconCode="check_circle"
            message="Su contraseña se ha cambiado correctamente"
            button={{ action: this.closeModal, label: "Aceptar" }}
          />
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(FormResetPassword)

/*
 * Styles
 */
const Container = styled.div`
  padding: 20px;
  background: ${props => props.theme.colors.cloudyBlueThree};
`
const Form = styled.form`
  button[type='submit'] {
    margin-top: 20px;
  }
  input:not(:first-child) {
    margin-top: 20px;
  }
  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 370px;
    margin: auto;
    button[type='submit'] {
      width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
