import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Paragraph } from '../styled/generics'

const ProgrammedGuardScheduleGrid = props => (
  <Appointments rows={Math.max(Math.ceil(props.schedules.length / 4), 6)}>
    {props.schedules.map((s, index) => (
      <Schedule
        key={index}
        active={
          props.selectedSchedule
            ? props.selectedSchedule.isSame(moment(s.fechaTurnoDateTime), 'minute')
            : false
        }
        onClick={() => props.onClick(moment(s.fechaTurnoDateTime))}
      >
        {moment(s.fechaTurnoDateTime).format('HH:mm')}hs
      </Schedule>
    ))}
    {!props.schedules.length && <P>No hay turnos disponibles</P>}
  </Appointments>
)

export default ProgrammedGuardScheduleGrid

/**
 * Styles
 */

const Appointments = styled.div`
  display: grid;
  grid-gap: 4px 6px;
  grid-template-rows: repeat(${props => props.rows}, 60px);
  grid-template-columns: repeat(4, 94px);
  grid-auto-flow: column;
  justify-content: center;
  margin: 13px 0 60px 0;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    grid-template-rows: repeat(${props => props.rows}, 60px);
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4px;
    margin-bottom: 26px;
  }
`
const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 12px;
  color: ${props => props.theme.colors.green};
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.greenFour};
  }
  ${props =>
    props.active &&
    css`
      background: ${props => props.theme.colors.greenFour};
      font-weight: 600;
      font-size: 13px;
      box-shadow: inset 3px 3px ${props => props.theme.colors.green},
        inset -3px -3px ${props => props.theme.colors.green};
    `};
`
const P = styled(Paragraph)`
  font-size: 24px;
  align-self: center;
  color: ${props => props.theme.colors.warmGrey};
  grid-row: 3 / span 2;
  grid-column: 1 / -1;
`
