import React from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'

import Layout from './shared/Layout'
import { clearAppointmentState, setAppointmentIsOnline, setActiveClinic } from '../actions'
import PrivateRoute from './PrivateRoute'
import ListMegoliAppointments from './ListMegoliAppointments'
import NewAppointment from './NewAppointment'
import ConfirmedAppointment from './ConfirmedAppointment'
import CancelledAppointment from './CancelledAppointment'
import MegoliRequirementsModal from './MegoliRequirementsModal'

class MegoliAppointment extends React.Component {
  state = { showModal: true }

  async componentDidMount() {
    this.props.clearAppointmentState()

    // seteo modo videoconsulta
    this.props.setAppointmentIsOnline(true)

    // seteo clinica 0 para el resto del flujo
    this.props.setActiveClinic({
      id: 0,
      codigoEmpresa: 0,
      codigoEmpresaGol: 0,
      nombre: 'Videoconsulta',
      mail: '',
      direccion: ''
    })

    this.props.history.replace(`${this.props.match.path}/listado`)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname === this.props.match.path &&
      prevProps.location.pathname === `${this.props.match.path}/listado`
    ) {
      this.props.clearAppointmentState()
    }
  }

  closeModal = () => this.setState({ showModal: false })

  render() {
    return (
      <React.Fragment>
        <Layout topSpace title="Videoconsulta programada">
          {this.props.appointment.activeClinic && (
            <Switch>
              <PrivateRoute
                exact
                path={`${this.props.match.path}/listado`}
                component={ListMegoliAppointments}
              />
              <PrivateRoute
                exact
                path={`${this.props.match.path}/nuevo`}
                component={NewAppointment}
              />
              <PrivateRoute
                exact
                path={`${this.props.match.path}/confirmacion`}
                component={ConfirmedAppointment}
              />
              <PrivateRoute
                exact
                path={`${this.props.match.path}/cancelado`}
                component={CancelledAppointment}
              />
            </Switch>
          )}
        </Layout>
        {this.state.showModal && (
          <MegoliRequirementsModal
            action={this.closeModal}
            renderSpecHours={this.renderSpecHours}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  clearAppointmentState,
  setAppointmentIsOnline,
  setActiveClinic
}
function mapStateToProps({ auth, appointment }) {
  return { auth, appointment }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MegoliAppointment)
