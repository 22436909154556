import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

class TabNewAppointment extends React.Component {
  render() {
    return (
      <Container>
        {this.props.listTabs &&
          this.props.listTabs.map(tab => (
            <Tab key={tab.id} active={tab.id <= this.props.activeTab}>
              {tab.id === this.props.activeTab && <h4>{tab.name}</h4>}
            </Tab>
          ))}
      </Container>
    )
  }
}

TabNewAppointment.propTypes = {
  listTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  activeTab: PropTypes.number
}

TabNewAppointment.defaultProps = { activeTab: 1 }

export default TabNewAppointment

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  width: 288px;
  margin: 30px auto 0 auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 164px;
  }
`
const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.colors.green};
  border-bottom: 3px solid ${props => props.theme.colors.whiteTwo};
  padding-bottom: 10px;
  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.green};
      border-bottom: 6px solid ${props => props.theme.colors.green};
    `} &:not(:first-child) {
    margin-left: 4px;
  }
  h4 {
    margin: 0;
    font-size: 13px;
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      font-size: 11px;
    }
  }
`
