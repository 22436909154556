import React from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import withUser from '../hoc/withUser'

const validateDisabledRoute = disable => disable
const validateLoggedUser = auth => !Object.keys(auth).length
const validateUserPermissions = (auth, permit) => permit && !auth.permisos.some(permiso => permiso.clave === permit)
const validateAllowedParams = (validParams, params) => validParams && !validParams.includes(params)

const PrivateRoute = ({
  auth,
  component: Component,
  permit,
  validParams,
  param,
  disable,
  ...rest }) => {

  const actualParam = useRouteMatch(rest.path).params[param];

  const invalidRoute = validateDisabledRoute(disable)
      || validateLoggedUser(auth)
      || validateUserPermissions(auth, permit)
      || validateAllowedParams(validParams, actualParam)

  if (invalidRoute) {
    return <Redirect to="/" />
  }

  return <Route {...rest} render={props => <Component {...props} />} />
}

export default withUser(PrivateRoute)
