import React from 'react'
import styled from 'styled-components'
import { Button, Select } from '../styled/generics'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'

import { ErrorField } from '../styled/generics'

const initialValues = { specialty: '', professional: '' }
const validations = yup.object().shape({
  specialty: yup.number().required('Campo requerido'),
  professional: yup.number().required('Campo requerido')
})

const capitalizeFLetter = (text) => { 
   return text.toLowerCase().split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
} 

const FormSelectProfessional = props => (
  <Container>
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={props.onSubmit}
      render={renderProps => (
        <Form onSubmit={renderProps.handleSubmit}>
          <FormInputs>
            <Select
              component="select"
              name="specialty"
              onChange={e => {
                renderProps.handleChange(e)
                renderProps.setFieldValue('professional', '')
                props.onSpecialtyChange(e.currentTarget.value)
              }}
              disabled={props.isOnline===true}
              warning={renderProps.errors.specialty && renderProps.touched.specialty ? 1 : 0}
            >
              <option value="" hidden disabled>
                Especialidad
              </option>
              {props.specialties.map((specialty, index) => {
                return (
                  <option value={specialty.idEspecialidad} key={index}>
                    {specialty.nombre}
                  </option>
                )
              })}
            </Select>
            {renderProps.touched.specialty &&
              renderProps.errors.specialty && (
                <ErrorField>{renderProps.errors.specialty}</ErrorField>
              )}
            <Select
              component="select"
              name="professional"
              disabled={props.professionals.length <= 0}
              warning={renderProps.errors.professional && renderProps.touched.professional ? 1 : 0}
            >
              <option value="" hidden disabled>
                Profesional
              </option>
              {props.professionals.map((professional, index) => {
                return (
                  <option value={professional.idProfesional} key={index}>
                    {capitalizeFLetter(professional.nombreProfesional)}
                  </option>
                )
              })}
            </Select>
            {renderProps.touched.professional &&
              renderProps.errors.professional && (
                <ErrorField>{renderProps.errors.professional}</ErrorField>
              )}
          </FormInputs>
          <ButtonsContainer>
            <SecondButton onClick={props.onCancel}>Cancelar</SecondButton>
            <Button disabled={props.isOnline} type="submit">Siguiente</Button>
          </ButtonsContainer>
        </Form>
      )}
    />
  </Container>
)

FormSelectProfessional.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  specialties: PropTypes.arrayOf(PropTypes.object).isRequired,
  professionals: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default FormSelectProfessional

/**
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-top: 30px;
  }
`
const Form = styled.form`
  width: 100%;
`
const FormInputs = styled.div`
  width: 520px;
  margin: auto;
  & > select:not(:first-child) {
    margin-top: 40px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    & > select:not(:first-child) {
      margin-top: 14px;
    }
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 39px;
  margin-top: 60px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`
const SecondButton = styled(Button)`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-row: 2;
  }
`
