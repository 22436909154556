import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import to from 'await-catch'
import { Redirect } from 'react-router-dom'

import { PATIENT_GROUP } from '../constants'
import { login as loginAction, setPatientDetails } from '../actions'
import { login, getPatientDataAtClinic } from '../api'
import FormLogin from '../components/FormLogin'
import RIGHT_IMG from '../images/login2-right.jpg'
import MOBILE_IMG from '../images/login2-right.jpg'

class Login extends React.Component {
  state = {
    isLoggedIn: false,
    seePassWord: false
  }

  componentWillMount() {
    this.checkLoggedInUser()
  }

  checkLoggedInUser = () => {
    if (Object.keys(this.props.auth).length) {
      this.setState({
        isLoggedIn: true
      })
    }
  }

  loadPatientDetails = async () => {
    const [err, { data } = {}] = await to(getPatientDataAtClinic(0))
    if (!err && data) {
      const doc = this.props.auth.metadatos.find(metadata => metadata.metadato.clave === 'NroDoc')
      const phone = this.props.auth.metadatos.filter(
        metadata => metadata.metadato.clave === 'Telefono'
      )
      
      this.props.setPatientDetails({
        ...data,
        numeroDocumento: doc ? doc.valor : null,
        telefono: phone.length ? phone[0].valor : data.telefono
      })
    }
  }

  handleSubmit = async values => {
    const [err, { data } = {}] = await to(login(values))
    if (!err) {
      this.props.loginAction(data)
      if (data.roles.some(value => value === PATIENT_GROUP)) {
        await this.loadPatientDetails()
      }
      this.checkLoggedInUser()
    }
  }

  handleSeePaswordChange = () => {
    this.setState({
      seePassWord: !this.state.seePassWord
    })
  }

  render() {
    if (this.state.isLoggedIn) {
      return <Redirect to="/inicio" />
    }

    return (
      <Container>
        <FormLogin
          handleSubmit={this.handleSubmit}
          handleSeePaswordChange={this.handleSeePaswordChange}
          seePassWord={this.state.seePassWord}
        />
        <Img src={RIGHT_IMG} alt="login background" />
      </Container>
    )
  }
}

const mapDispatchToProps = { loginAction, setPatientDetails }
function mapStateToProps({ auth }) {
  return { auth }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

/*
 * Styles
 */
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-items: end;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    max-width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 8px;
    background: url(${MOBILE_IMG}) no-repeat center center;
    background-size: cover;

    ${Img} {
      display: none;
    }
  }
`
