import { combineReducers, createStore, compose } from 'redux'
import persistState from 'redux-sessionstorage'
import config from './config'

import {
  authReducer,
  appointmentReducer,
  smartGuardReducer,
  flashMessageReducer,
  loaderReducer,
  userCreationReducer,
  patientReducer
} from './reducers'

const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  appointment: appointmentReducer,
  patient: patientReducer,
  smartGuard: smartGuardReducer,
  flashMessage: flashMessageReducer,
  userCreation: userCreationReducer
})

export const store = createStore(
  rootReducer,
  compose(
    persistState('auth', { key: 'auth' }), // Sync auth state with sessionStorage in browser
    persistState('patient', { key: 'patient' }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && config.showDevTools
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
)
