import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import { APPOINTMENT_STAGE_PATIENT } from '../constants';
import { setAppointmentStage } from '../actions'
import AppointmentHeader from './AppointmentHeader'
import AlertMessage from './ui/AlertMessage'
import PatientDetails from './PatientDetails'

class ConfirmPatientData extends React.Component {
  state = { showAlertMessage: false }

  handleNotCorrect = () => {
    return this.setState({ showAlertMessage: true })
  }

  componentDidMount() {
    this.props.setAppointmentStage(APPOINTMENT_STAGE_PATIENT)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appointment.activeClinic !== this.props.appointment.activeClinic) {
      this.setState({ showAlertMessage: false })
    }
  }

  userHasValidInsuranceInClinic() {
    return (
      this.props.patient.sedesHabilitadas &&
      this.props.patient.sedesHabilitadas.includes(this.props.appointment.activeClinic.codigoEmpresa) &&
      this.props.patient.coberturaHabilitada
    )
  }

  render() {
    return (
      <React.Fragment>
        {/* has patient data but no valid insurance */}
        {this.props.patient.nombreApellido &&
          !this.userHasValidInsuranceInClinic() && (
            <ErrorContent>
              <InsuranceMessage>
                Su cobertura no está habilitada para sacar turnos, por favor contactarte a la
                central de turnos:
                <br />
                0800-666-6587 o por WhatsApp al +54 114493-2017, de lunes a viernes de 8 a 20 hs
              </InsuranceMessage>
            </ErrorContent>
          )}
        {/* has patient data and valid insurance */}
        {this.props.patient.nombreApellido &&
          this.userHasValidInsuranceInClinic() && (
            <Content>
              <React.Fragment>
                <AppointmentHeader
                  fullName={this.props.patient.nombreApellido}
                  healthInsurance={this.props.patient.coberturaMedica}
                  memberNumber={this.props.patient.nroAfiliado}
                />
                <PatientDetails
                  patient={this.props.patient}
                  handleNotCorrect={this.handleNotCorrect}
                  handleCorrect={`/inicio/gestionar-turnos/${this.props.match.path.split('/')[3]}/listado`}
                  showButtons={!this.state.showAlertMessage}
                />
              </React.Fragment>
            </Content>
          )}

        {/* has no patient data */}
        {!this.props.patient.nombreApellido && (
          <Content>
            <SectionName>La clínica seleccionada no tiene datos del paciente</SectionName>
          </Content>
        )}

        {this.state.showAlertMessage && (
          <AlertContent>
            <AlertMessage type="error">
              Para modificar tus datos, por favor comunicate con la Central de Turnos o acercate a
              la Clínica.
            </AlertMessage>
          </AlertContent>
        )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = { setAppointmentStage }
function mapStateToProps({ appointment, patient }) {
  return { appointment, patient }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPatientData)

/*
 * Styles
 */
const SectionName = styled.h4`
  font-size: 26px;
  text-align: center;
`
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`
const AlertContent = styled.div`
  ${margin};
`

const ErrorContent = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.red};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 10px 10px;
  }
`

const InsuranceMessage = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${props => props.theme.colors.redTwo};
`
