import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { LOG_ACTION_LOGIN } from '../constants'
import { Button, MaterialIcon, Checkbox, CustomDateRangePicker } from '../styled/generics'

const validations = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required()
})

class FormFilterLogs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onFocus: null,
      showFilters: true,
      initialValues: {
        actions: props.actions.filter(item => item.key !== LOG_ACTION_LOGIN).map(item => item.key),
        startDate: props.defaultFrom,
        endDate: props.defaultTo
      }
    }
  }

  render() {
    return (
      <FormContainer>
        <FiltersMobile>
          <span>Filtros</span>
          <MaterialIcon
            name={this.state.showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          />
        </FiltersMobile>
        <Formik
          onSubmit={values => {
            this.setState({ showFilters: false })
            this.props.onSubmit(values)
          }}
          initialValues={this.state.initialValues}
          validationSchema={validations}
          render={renderProps => (
            <Form onSubmit={renderProps.handleSubmit} showFilters={this.state.showFilters}>
              <InputContainer>
                <Subtitle>Filtrar por tipo de evento</Subtitle>
                <CheckboxesContainer>
                  {this.props.actions.map((item, index) => (
                    <Checkbox
                      key={index}
                      groupName="actions"
                      item={item}
                      onChange={renderProps.submitForm}
                    />
                  ))}
                </CheckboxesContainer>
              </InputContainer>

              <InputContainer>
                <Subtitle>Filtrar por fecha</Subtitle>

                <CheckboxesContainer>
                  <CustomDateRangePicker
                    warning={
                      (renderProps.errors.startDate && renderProps.touched.startDate) ||
                      (renderProps.errors.endDate && renderProps.touched.endDate)
                        ? 1
                        : 0
                    }
                  >
                    <DateRangePicker
                      startDate={
                        moment(renderProps.values.startDate).isValid()
                          ? moment(renderProps.values.startDate)
                          : null
                      }
                      endDate={
                        moment(renderProps.values.endDate).isValid()
                          ? moment(renderProps.values.endDate)
                          : null
                      }
                      startDateId="startDate"
                      endDateId="endDate"
                      readOnly={true}
                      onDatesChange={({ startDate, endDate }) => {
                        renderProps.setFieldValue('startDate', startDate)
                        renderProps.setFieldValue('endDate', endDate)
                      }}
                      startDatePlaceholderText="Fecha de inicio"
                      endDatePlaceholderText="Fecha de fin"
                      focusedInput={this.state.onFocus}
                      onFocusChange={f => {
                        renderProps.setFieldTouched(f, true)
                        this.setState({ onFocus: f })
                      }}
                      hideKeyboardShortcutsPanel={true}
                      firstDayOfWeek={0}
                      minimumNights={0}
                      isOutsideRange={date => date.isAfter(moment(), 'day')}
                      displayFormat="DD - MM - YYYY"
                      numberOfMonths={1}
                    />
                  </CustomDateRangePicker>
                </CheckboxesContainer>
              </InputContainer>

              <SizedButton type="submit">Consultar</SizedButton>
            </Form>
          )}
        />
      </FormContainer>
    )
  }
}

export default FormFilterLogs

/**
 * Styles

 */
const FormContainer = styled.div``

const InputContainer = styled.div`
  width: 100%;
`
const CheckboxesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  > * {
    margin-bottom: 20px;
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;
  }
`

const FiltersMobile = styled.div`
  display: none;
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
  padding: 12px 30px 18px 30px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-bottom: 20px;
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
    display: ${props => (props.showFilters ? 'block' : 'none')};
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`
const SizedButton = styled(Button)`
  width: 140px;
  margin: auto;
  border-radius: 20px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`

const Subtitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.warmGreyTwo};
`
