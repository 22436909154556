import React from 'react'
import { withRouter } from 'react-router'
import styled, { css } from 'styled-components'

class BreadCrumbs extends React.Component {
  state = {
    routes: []
  }

  constructor(props) {
    super(props)
    this.state = {
      routes: this.props.location.pathname.split('/').splice(1)
    }
  }

  render() {
    if(this.state.routes.length <= 1) return null;
    return (
      <ContainerUl>
        {this.state.routes.map((route, index) => {
          return (
            <CrumbLi key={index}>
              <Span lastone={(index === (this.state.routes.length -1)).toString()}>{route}</Span>
            </CrumbLi>
          )
        })}
      </ContainerUl>
    )
  }
}

export default withRouter(BreadCrumbs)

const ContainerUl = styled.ul`
  list-style: none;
  padding: 0;
  & > li:after {
    content: "${props => props.separator || '/'}";
  }

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding-left: 30px;
  }
`

const CrumbLi = styled.li`
  display: inline-block;

  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`

const Span = styled.span`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #333333;

  ${
      props => (props.lastone === 'true') && (
          css`
            color: #04705e;
          `
      )
  }
`
