import React from 'react'
import styled from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'

import Layout from './shared/Layout'
import FormSpeciality from './FormSpeciality'

import {
  setClinicsTimesWithSpeciality,
  setPatientDetailsAtClinic,
  setGolEnabledSpecs
} from '../actions'
import { getGuardSpecialities, getWaitingTimes, signUpMW, getGolEnabledSpecs } from '../api'
class SmartGuard extends React.Component {
  state = { specialities: [] }

  async componentDidMount() {
    const [err, [{ data: specsData } = {}, { data: golEnabledSpecs } = {}]] = await to([
      getGuardSpecialities(),
      getGolEnabledSpecs()
    ])

    if (!err) {
      this.props.setGolEnabledSpecs(golEnabledSpecs)
      this.setState({
        specialities: specsData
      })
    }
  }

  handleSubmit = async values => {
    const [err, { data } = {}] = await to(getWaitingTimes(values.formValues.specialityId))

    if (!err) {
      this.props.setClinicsTimesWithSpeciality({
        speciality: this.state.specialities.find(
          speciality =>
            speciality.idEspecialidad === Number.parseInt(values.formValues.specialityId, 10)
        ),
        clinicsTimes: data
      })

      if (values.pediatric) {
        if (values.newPediatricPatient) {
          let newPatient = await this.createPediatricUser(values.formValues)
          if (!!newPatient) {
            this.props.setPatientDetailsAtClinic({
              ...newPatient,
              numeroDocumento: values.formValues.documentNumber
            })
          } else {
            return
          }
        } else {
          this.props.setPatientDetailsAtClinic({
            ...values.searchedValues
          })
        }
      } else {
        this.props.setPatientDetailsAtClinic({
          ...this.props.patient
        })
      }
      this.props.history.push(`${this.props.match.path}/guardia-clinica`)
    }
  }

  createPediatricUser = async values => {
    const paramsMW = {
      mail: values.email,
      nombres: values.name,
      apellidos: values.lastName,
      tipoDocumento: values.documentType,
      documento: values.documentNumber,
      sexo: values.gender,
      dob: values.birthDate,
      nroAfiliado: values.credentialNumber,
      obraSocial: values.healthInsurance,
      plan: values.plan
    }

    const [err, { data } = {}] = await to(signUpMW(paramsMW))
    if (!err && !!data) {
      return data
    } else {
      return null
    }
  }

  render() {
    return (
      <Layout
        topSpace
        title="Programá tu visita a la guardia"
        subTitle="¿Qué especialidad necesitás consultar?"
      >
        <Container>
          <FormSpeciality
            specialities={this.state.specialities.map(({ idEspecialidad: id, nombre: name }) => ({
              id,
              name
            }))}
            handleSubmit={this.handleSubmit}
          />
        </Container>
      </Layout>
    )
  }
}

function mapStateToProps({ patient }) {
  return { patient }
}
const mapDispatchToProps = {
  setClinicsTimesWithSpeciality,
  setPatientDetailsAtClinic,
  setGolEnabledSpecs
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartGuard)

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
`
