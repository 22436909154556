export default {
  layout: {
    header: '80px',
    smallHeader: '70px',
    heroImage: '290px'
  },
  forms: {
    height: '40px'
  },
  breakPoints: {
    small: '800px',
    medium: '1350px'
  }
}
