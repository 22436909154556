import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { DELIVERY_STATUS_FILTERS } from '../constants'
import { Button, MaterialIcon, Checkbox, CustomDateRangePicker, Select } from '../styled/generics'
import { InvoiceInput } from '../form-components/invoiceInput'

const validations = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required()
})

class FormFilterInvoices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onFocus: null,
      showFilters: true
    }
  }

  render() {
    return (
      <FormContainer>
        <FiltersMobile>
          <span>Filtros</span>
          <MaterialIcon
            name={this.state.showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          />
        </FiltersMobile>
        <Formik
          onSubmit={values => {
            this.props.onSubmit(values)
          }}
          initialValues={{
            sentStatus: this.props.defaultStatus,
            startDate: this.props.defaultStart,
            endDate: this.props.defaultEnd,
            tipoComprobante: this.props.defaultTipoComprobante,
            tipoFactura: this.props.defaultTipoFactura,
            centroFactura: this.props.defaultCentroFactura,
            numeroComprobante: this.props.defaultNumeroComprobante,
            clinic: this.props.clinic || '',
            institution: this.props.institution || ''
          }}
          validationSchema={validations}
          render={renderProps => (
            <Form onSubmit={renderProps.handleSubmit} showFilters={this.state.showFilters}>
              <SelectsContainer>
                {this.props.listOfClinics.length > 0 && (
                  <CustomSelect
                    component="select"
                    name="clinic"
                    warning={renderProps.errors.clinic && renderProps.touched.clinic ? 1 : 0}
                  >
                    {this.props.listOfClinics.map((clinic, idx) => (
                      <option value={clinic.id} key={idx}>
                        {clinic.nombre}
                      </option>
                    ))}
                  </CustomSelect>
                )}
                {this.props.listOfInstitutions.length > 0 && (
                  <CustomSelect
                    component="select"
                    name="institution"
                    warning={
                      renderProps.errors.institution && renderProps.touched.institution ? 1 : 0
                    }
                  >
                    <option value="" defaultValue>
                      Ver todas las instituciones
                    </option>
                    {this.props.listOfInstitutions.map((institution, idx) => (
                      <option value={institution.cuit} key={idx}>
                        {institution.descripcion}
                      </option>
                    ))}
                  </CustomSelect>
                )}
                <InvoiceInput
                  tipoComprobante={renderProps.values.tipoComprobante}
                  tipoFactura={renderProps.values.tipoFactura}
                  centroFactura={renderProps.values.centroFactura}
                  numeroComprobante={renderProps.values.numeroComprobante}
                  errors={renderProps.errors}
                  touched={renderProps.touched}
                />
              </SelectsContainer>
              <DateAndSentContainer>
                {this.props.showSentFilter &&
                  DELIVERY_STATUS_FILTERS.map((item, index) => (
                    <Checkbox
                      key={index}
                      groupName="sentStatus"
                      item={item}
                      onChange={renderProps.submitForm}
                    />
                  ))}

                <CustomDateRangePicker
                  warning={
                    (renderProps.errors.startDate && renderProps.touched.startDate) ||
                    (renderProps.errors.endDate && renderProps.touched.endDate)
                      ? 1
                      : 0
                  }
                >
                  <DateRangePicker
                    startDate={
                      moment(renderProps.values.startDate).isValid()
                        ? moment(renderProps.values.startDate)
                        : null
                    }
                    endDate={
                      moment(renderProps.values.endDate).isValid()
                        ? moment(renderProps.values.endDate)
                        : null
                    }
                    startDateId="startDate"
                    endDateId="endDate"
                    readOnly={true}
                    onDatesChange={({ startDate, endDate }) => {
                      renderProps.setFieldValue('startDate', startDate)
                      renderProps.setFieldValue('endDate', endDate)
                      //renderProps.submitForm()
                    }}
                    startDatePlaceholderText="Fecha de inicio"
                    endDatePlaceholderText="Fecha de fin"
                    focusedInput={this.state.onFocus}
                    onFocusChange={f => {
                      renderProps.setFieldTouched(f, true)
                      this.setState({ onFocus: f })
                    }}
                    hideKeyboardShortcutsPanel={true}
                    firstDayOfWeek={0}
                    minimumNights={0}
                    isOutsideRange={date => date.isAfter(moment(), 'day')}
                    displayFormat="DD - MM - YYYY"
                    numberOfMonths={1}
                  />
                </CustomDateRangePicker>
              </DateAndSentContainer>
              <SizedButton disabled={this.props.submitting} type="submit">
                Consultar
              </SizedButton>
            </Form>
          )}
        />
      </FormContainer>
    )
  }
}

export default FormFilterInvoices

/**
 * Styles
 */
const FormContainer = styled.div``

const FiltersMobile = styled.div`
  display: none;
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
  padding: 12px 30px 0px 30px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 32px 20px 32px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  display: ${props => (props.showFilters ? 'block' : 'none')};
  width: 100%;
  > * {
    margin-bottom: 32px;
    @media (max-width: ${props => props.theme.sizes.breakPoints.medium}) {
      margin-bottom: 20px;
    }
  }
`

const SelectsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 16px;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;

    > * {
      margin-bottom: 20px;
    }
  }
`

const DateAndSentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;

    > * {
      margin-bottom: 20px;
    }
  }
`

const CustomSelect = styled(Select)`
  width: 210px;
  margin-right: 16px;
`

const SizedButton = styled(Button)`
  width: 140px;
  margin: auto;
  border-radius: 20px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
