import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { clearSmartGuardState } from '../actions'
import { Paragraph, Button, AddToCalendarButton } from '../styled/generics'
import PanelConfirmation from './PanelConfirmation'

class ProgrammedGuardConfirmed extends React.Component {
  state = { cancelled: false }
  calendarEvent = {
    title: `Turno - ${this.props.smartGuard.speciality.nombre}`,
    description: `Turno de la especialidad: ${this.props.smartGuard.speciality.nombre}.`,
    location: `${this.props.appointment.activeClinic.direccion}`,
    startTime: moment(this.props.smartGuard.schedule),
    endTime: moment(this.props.smartGuard.schedule).add(1, 'hours')
  }

  handleFinishFlow = () => {
    this.props.clearSmartGuardState()
    this.props.history.push('/inicio')
  }

  handleAppointmentCancelled = () => this.setState({ cancelled: true })

  render() {
    return (
      <Container>
        {this.state.cancelled ? (
          <React.Fragment>
            <Container>
              <Icon className="material-icons">close</Icon>
              <Title red>Tu turno fue cancelado</Title>
            </Container>
            <ButtonsContainer>
              <Button2 onClick={this.handleFinishFlow}>Finalizar</Button2>
            </ButtonsContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PanelConfirmation
              handleAppointmentCancelled={this.handleAppointmentCancelled}
              clinic={this.props.appointment.activeClinic.codigoEmpresa}
              id={this.props.smartGuard.programmedId}
              isOnline={false}
            />

            {/* <Title>Resultado de la solicitud</Title> */}
            <Details>
              <Span>Número de solicitud:</Span>
              <Value>{this.props.smartGuard.programmedId}</Value>
              <Span>{`${this.props.appointment.activeClinic.nombre}:`}</Span>
              <Value>{this.props.appointment.activeClinic.direccion}</Value>
              <Span>Especialidad:</Span>
              <Value>{this.props.smartGuard.speciality.nombre}</Value>
              <Span>Fecha y hora del turno:</Span>
              <Value>{moment(this.props.smartGuard.schedule).format('DD/MM/YYYY - HH:mm')}hs</Value>
              <Span>Nombre del paciente:</Span>
              <Value>{this.props.appointment.patientAtClinicDetails.nombreApellido}</Value>
              <Span>Cobertura médica:</Span>
              <Value>{this.props.appointment.patientAtClinicDetails.coberturaMedica}</Value>
              <Span>Plan/Línea/Cartilla:</Span>
              <Value>{this.props.appointment.patientAtClinicDetails.planLineaCartilla}</Value>
              <Span>Número de Afiliado:</Span>
              <Value>{this.props.appointment.patientAtClinicDetails.nroAfiliado}</Value>
            </Details>
            <ButtonsContainer>
              <Button2 outline green onClick={window.print}>
                Imprimir
              </Button2>
              <AddToCalendarButton outline green event={this.calendarEvent} />
              <Button2 onClick={this.handleFinishFlow}>Finalizar</Button2>
            </ButtonsContainer>
          </React.Fragment>
        )}
      </Container>
    )
  }
}

const mapDispatchToProps = { clearSmartGuardState }
function mapStateToProps({ smartGuard, appointment }) {
  return { smartGuard, appointment }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgrammedGuardConfirmed))

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const Title = styled(Paragraph)`
  margin: 30px 0;
  font-weight: 600;
  font-size: 24px;
  color: ${props => props.theme.colors.green};
  text-align: center;
  ${props =>
    props.red &&
    css`
      color: ${props => props.theme.colors.redTwo};
    `};
`
const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  width: 100%;
  font-size: 14px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
    grid-gap: 13px 3px;
  }
`
const Span = styled.span`
  justify-self: end;
  color: ${props => props.theme.colors.warmGreyTwo};
`
const Value = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: column;
    width: 100%;
  }
`
const Button2 = styled(Button)`
  width: 320px;
  margin-top: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    margin-top: 20px;
  }
`
const Icon = styled.i`
  margin-top: 25px;
  font-size: 120px;
  color: ${props => props.theme.colors.redTwo};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 90px;
  }
`
