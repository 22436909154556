import React from 'react'
import FormResetPassword from './FormResetPassword'

import withUser from '../hoc/withUser'


class ChangePassword extends React.Component {
  render() {
    return (
      <FormResetPassword
        idUsuario={this.props.auth.idUsuario}
      />
    )
  }
}

export default withUser(ChangePassword)