import React from 'react'
import styled from 'styled-components'
import Layout from './shared/Layout'
import { Paragraph } from '../styled/generics'

const TermsAndConditions = () => (
  <Layout topSpace title="Términos y Condiciones">
    <P bold>Política de Confidencialidad y Protección de Datos OMINT</P>
    <P>
      <strong>OMINT S.A. de Servicios,</strong> (en adelante OMINT) se compromete a adoptar una
      política de confidencialidad y protección de datos, con el objeto de proteger la privacidad de
      la información personal obtenida a través de sus canales digitales (sitio web y aplicación
      móvil).
    </P>
    <P>
      OMINT se reserva el derecho de modificar la Política de Confidencialidad y Protección de Datos
      que a continuación se detalla a fin de adaptarla a nuevas exigencias legislativas,
      jurisprudenciales y/o técnicas, como así también a todas aquellos requerimientos que le
      permitan brindar mejores servicios y contenidos, con lo cual se sugiere la revisión de las
      normas, periódicamente.
    </P>
    <P>
      La presente declaración representa la
      <strong> Política de Confidencialidad y Protección de Datos </strong>
      de OMINT y describe las prácticas que mantiene OMINT con respecto a todos los visitantes de
      nuestro sitio como así de cualquier herramienta digital.
    </P>
    <P bold>Información personal</P>
    <P>
      OMINT recopila cierta información personal de los usuarios que acceden a nuestras
      aplicaciones, a fin de realizar consultas y trámites on-line. Dichos usuarios necesitan
      encontrarse previamente registrados. OMINT utiliza esta información internamente (para
      procesar su pedido y las facturas, para mantener o actualizar su cuenta en OMINT o para
      enviarle las actualizaciones de servicios nuevos, noticias y acontecimientos de interés). Al
      respecto, se aclara que: La información que OMINT obtiene por parte de sus socios es de
      carácter estrictamente confidencial, en especial, aquella relacionada a su salud,
      tratamientos, diagnósticos y medicación.
    </P>
    <UL>
      <li>
        La información de carácter económico y comercial de los socios también reviste el carácter
        de confidencial, por lo que su tratamiento deberá ser utilizado conforme la política de
        OMINT y las leyes vigentes.
      </li>
      <li>
        Los empleados y asesores de OMINT podrán utilizar la información confidencial de los socios
        exclusivamente durante el desempeño de las tareas habituales de la empresa, encontrándose
        prohibido hacer uso de la misma fuera del ámbito laboral o con propósitos distintos a los
        necesarios para su desempeño.
      </li>
      <li>
        Toda documentación que contenga información confidencial de un socio, cualquiera sea el
        soporte en que se encuentre (físico, magnético o digital), deberá ser tratada con cuidado y
        diligencia.
      </li>
      <li>
        Se encuentra rigurosamente prohibido brindar acceso a información de socios a terceros
        ajenos a la empresa, con la única excepción de aquellas solicitudes efectuadas por autoridad
        competente, parlamentaria, administrativa o judicial. En ningún caso se le podrá brindar
        información a un socio relacionada a otro socio que no pertenezca a su grupo familiar.
      </li>
      <li>
        En caso que un socio solicite información confidencial propia, deberá previamente
        constatarse la identidad del requirente, solicitándole que informe su nombre, domicilio,
        DNI, composición del grupo familiar, fecha de vencimiento de su factura y cualquier otra
        pregunta que el personal considere necesaria a los efectos de constatar su identidad.
      </li>
    </UL>
    <P bold>Menores de Edad</P>
    <P>
      Los menores de 18 años de edad no deben utilizar las aplicaciones digitales de OMINT.
      Consideramos que, quien se registra y utiliza este sitio tiene por lo menos 18 años de edad.
      OMINT utiliza información del perfil (como la edad, domicilio, sexo, etcétera) contenida en el
      formulario de registro para crear contenidos, servicios y publicidad personalizados en el
      sitio Web de OMINT. OMINT puede estudiar su actividad en el sitio Web y en la aplicación OMINT
      Móvil para hacer un seguimiento preciso de su cuenta y ofrecerle los productos y servicios de
      OMINT que corresponden a sus intereses. OMINT puede recopilar datos en forma global para poder
      comprender mejor a los usuarios que visitan nuestro sitio. Por ejemplo, pueden generarse
      informes sobre los términos de búsqueda más habituales a través de la recopilación de datos
      generales de los términos de búsqueda basados en búsquedas individuales. Los datos globales
      son anónimos y no contienen ninguna información personal que identifique al usuario.
    </P>
    <P bold>Opción de no recepción de correo electrónico</P>
    <P>
      OMINT ofrece a sus socios, la opción de no permitir el envío de correos electrónicos
      relacionados con promociones, sorteos y eventos. En caso que no desee recibir estas novedades,
      deberá responder el mail en la pantalla de cancelación de mails.
    </P>
    <P>+Seguridad</P>
    <P>
      +OMINT dispone de diversas funciones operativas para proteger la confidencialidad de los datos
      personales. Sin embargo, la seguridad perfecta en Internet no existe y OMINT no garantiza que
      su sitio sea impenetrable ni invulnerable para los piratas informáticos.
    </P>
    <P>+Cookies</P>
    <P>
      +Cuando el usuario visita el sitio Web de OMINT, éste envía a su computadora una “cookie”, la
      cual consiste en un pequeño archivo que reside en la unidad de disco duro de su equipo y que
      contiene información personal del usuario no identificable. Las cookies sólo identifican la
      computadora del usuario. Las cookies permiten que OMINT mejore el sitio Web para que sea más
      útil mediante el almacenamiento de información sobre sus preferencias. En relación a ello, se
      podrá definir las opciones del explorador en caso que no se acepte el envio de la “cookie”,
      sin embargo ello le impedirá obtener acceso a todas las funciones del sitio Web de OMINT.
    </P>
    <P underline>Correo electrónico</P>
    <P>
      OMINT no cede listados de direcciones de correo electrónico. En caso de requerir que OMINT
      envíe una nota por correo electrónico para informar sobre estado de la facturación, lo hará a
      la dirección de correo electrónico que fuera proporcionada por el socio.
    </P>
    <P underline>Encuestas</P>
    <P>
      La participación en encuestas en el sitio Web de OMINT es totalmente voluntaria, por lo cual,
      el usuario tiene la opción de revelar información personal o no. OMINT puede solicitar
      información de contacto e información demográfica. La información de las encuestas se
      utilizará para supervisar o mejorar el uso del sitio Web de OMINT.
    </P>
    <P>+Enlaces</P>
    <P>
      +Se debe tener presente que el sitio Web de OMINT puede contener enlaces a otros sitios en los
      que rigen sus propias políticas de confidencialidad. OMINT no es responsable de las prácticas
      de confidencialidad de estos sitios y pedimos a nuestros usuarios que lo tengan en cuenta
      cuando salgan de nuestro sitio. Recomendamos a nuestros usuarios que lean las declaraciones de
      confidencialidad de cada uno de los sitios Web que reúnan información personal identificable.
    </P>
    <P bold>
      Si tiene alguna pregunta sobre la Política de Confidencialidad y Protección de Datos de OMINT
      o le preocupa la información personal que ha facilitado, póngase en contacto con el Centro de
      Atención al Socio. |
    </P>
  </Layout>
)

export default TermsAndConditions

/*
 * Styles
 */
const P = styled(Paragraph)`
  margin: 15px 0;
  text-align: justify;
`

const UL = styled.ul`
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};
  > li {
    font-size: 14px;
    margin: 10px 0;
    text-align: justify;
  }
`
