//Role Groups
export const PATIENT_GROUP = 'Paciente'
export const FUNDER_GROUP = 'Financiador'
export const ADMINISTRATOR_GROUP = 'Administrador'
export const AUTOMANAGEMENTFUNDER_GROUP = 'AutogestionFinanciadores'
export const MEDIC_GROUP = 'Medico'
export const PREGNANCYPLANNER_GROUP = 'PregnancyPlanner'
export const BILLING_GROUP = 'Facturacion'

// Roles
export const PATIENT = 'Paciente'
export const FUNDER = 'FinanciadorGlobal'
export const FUNDERMEDIC = 'FinanciadorMedico'
export const FUNDERADMINISTRATIVE = 'FinanciadorAdministrativo'
export const ADMINISTRATOR = 'Administrador'
export const AUTOMANAGEMENTFUNDER = 'AutogestorFinanciadores'
export const MEDIC = 'Medico'
export const PREGNANCYPLANNER = 'PregnancyPlanner'
export const PATIENTADMIN = 'AdministradorPacientes'
export const BILLING = 'Facturacion'