import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { MaterialIcon } from '../styled/generics'

import Modal from './Modal'
// import LOADER_IMG from '../images/loader.svg'

const Loader = props =>
  props.loader.show ? (
    <Modal>
      <Container>
        <MaterialIcon name="access_time" />        
        <p>Un momento por favor</p>
        {props.loader.downloadData && (
          <DownloadProgress>
            {props.loader.downloadData.status}...{' '} 
            {props.loader.downloadData.progress ? props.loader.downloadData.progress + '%' : ''}
          </DownloadProgress>
        )}
      </Container>
    </Modal>
  ) : null

function mapStateToProps({ loader }) {
  return { loader }
}
export default connect(mapStateToProps)(Loader)

/* 
 * Styles
 */
const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  color: ${props => props.theme.colors.green};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 70px 120px;
  }

  > i {
    font-size: 60px;
    margin-bottom: 10px;
  }
`

const DownloadProgress = styled.p`
  margin: 0;
`