import {
  SET_CLINICS_TIMES_WITH_SPECIALITY,
  SET_CHECK_IN_INFO,
  SET_PROGRAMMED_GUARD_SCHEDULE,
  SET_PROGRAMMED_GUARD_ID,
  CLEAR_SMART_GUARD_STATE,
  SET_GOL_ENABLED_SPECS
} from '../actions'


export const smartGuardDefaults = {
  speciality: null,
  clinicsTimes: [],
  checkIn: null,
  schedule: null,
  programmedId: null,
  golEnabledSpecs: []
}
export const smartGuardReducer = function (state = smartGuardDefaults, action) {
  switch (action.type) {
    case SET_CLINICS_TIMES_WITH_SPECIALITY:
      return {
        ...state,
        ...action.payload
      }
    case SET_CHECK_IN_INFO:
      return {
        ...state,
        checkIn: action.payload
      }
    case SET_GOL_ENABLED_SPECS:
      return {
        ...state,
        golEnabledSpecs: action.payload
      }
    case SET_PROGRAMMED_GUARD_SCHEDULE:
      return {
        ...state,
        schedule: action.payload
      }
    case SET_PROGRAMMED_GUARD_ID:
      return {
        ...state,
        programmedId: action.payload
      }
    case CLEAR_SMART_GUARD_STATE:
      return {
        ...smartGuardDefaults
      }
    default:
      return state
  }
}