import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

import { Button, Select, MaterialIcon, CustomDateRangePicker } from '../styled/generics'

const initialValues = {
  startDate: '',
  endDate: '',
  clinic: ''
}

const validations = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  clinic: yup.string().required()
})

class FormFilterHospitalizedPatients extends React.Component {
  state = { onFocus: null, showFilters: true }
  render() {
    return (
      <FormContainer>
        <FiltersMobile>
          <span>Filtros</span>
          <MaterialIcon
            name={this.state.showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          />
        </FiltersMobile>
        <Formik
          onSubmit={values => {
            this.setState({ showFilters: false })
            this.props.onSubmit(values)
          }}
          initialValues={initialValues}
          validationSchema={validations}
          render={renderProps => (
            <Form onSubmit={renderProps.handleSubmit} showFilters={this.state.showFilters}>
              <CustomDateRangePicker
                warning={
                  (renderProps.errors.startDate && renderProps.touched.startDate) ||
                  (renderProps.errors.endDate && renderProps.touched.endDate)
                    ? 1
                    : 0
                }
              >
                <DateRangePicker
                  startDate={
                    moment(renderProps.values.startDate).isValid()
                      ? moment(renderProps.values.startDate)
                      : null
                  }
                  endDate={
                    moment(renderProps.values.endDate).isValid()
                      ? moment(renderProps.values.endDate)
                      : null
                  }
                  startDateId="startDate"
                  endDateId="endDate"
                  onDatesChange={({ startDate, endDate }) => {
                    renderProps.setFieldValue('startDate', startDate)
                    renderProps.setFieldValue('endDate', endDate)
                  }}
                  startDatePlaceholderText="Fecha de inicio"
                  endDatePlaceholderText="Fecha de fin"
                  focusedInput={this.state.onFocus}
                  onFocusChange={f => {
                    renderProps.setFieldTouched(f, true)
                    this.setState({ onFocus: f })
                  }}
                  hideKeyboardShortcutsPanel={true}
                  firstDayOfWeek={0}
                  minimumNights={0}
                  isOutsideRange={date => date.isAfter(moment(), 'day')}
                  displayFormat="DD - MM - YYYY"
                  numberOfMonths={1}
                />
              </CustomDateRangePicker>

              <SizedSelect
                component="select"
                name="clinic"
                warning={renderProps.errors.clinic && renderProps.touched.clinic ? 1 : 0}
              >
                <option value="" hidden disabled>
                  Seleccione una clinica
                </option>
                {this.props.clinics.map((clinic, index) => {
                  return (
                    <option value={clinic.id} key={index}>
                      {clinic.nombre}
                    </option>
                  )
                })}
              </SizedSelect>

              <SizedButton type="submit">Consultar</SizedButton>
            </Form>
          )}
        />
      </FormContainer>
    )
  }
}

export default FormFilterHospitalizedPatients

/**
 * Styles
 */
const FormContainer = styled.div``
const FiltersMobile = styled.div`
  display: none;
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  font-size: 20px;
  padding: 12px 30px 18px 30px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: ${props => (props.showFilters ? 'block' : 'none')};
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    > * {
      margin-bottom: 20px;
    }
  }
`
const SizedButton = styled(Button)`
  width: 140px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
const SizedSelect = styled(Select)`
  width: 240px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`