import {
    PATIENT_GROUP,
    FUNDER_GROUP,
    ADMINISTRATOR_GROUP,
    AUTOMANAGEMENTFUNDER_GROUP,
    MEDIC_GROUP,
    PREGNANCYPLANNER_GROUP,
    BILLING_GROUP
} from './roles'

export const USERS_FILTERS = [
    { key: PATIENT_GROUP, name: 'Pacientes' },
    { key: FUNDER_GROUP, name: 'Financiadores' },
    { key: ADMINISTRATOR_GROUP, name: 'Administradores' },
    { key: AUTOMANAGEMENTFUNDER_GROUP, name: 'Autogestores' },
    { key: MEDIC_GROUP, name: 'Médicos' },
    { key: PREGNANCYPLANNER_GROUP, name: 'Pregnancy Planner' },
    { key: BILLING_GROUP, name: 'Facturacíon' }
]


export const REDUCED_USERS_FILTERS = [{ key: PATIENT_GROUP, name: 'Pacientes' }]