import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import GENERIC_AVATAR from '../../images/generic_avatar.svg'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import sizes from '../../styled/sizes'
import { ROUTES_BY_ROLE_GROUP } from '../../constants'
import Logout from '../Logout'

class Sidebar extends React.Component {
  state = {
    activeIdx: null,
    outStart: false
  }

  async componentDidMount() {
    await this.setState({
      outStart: this.props.location.pathname !== '/inicio'
    })
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  setActiveIdx = idx => {
    if (this.state.activeIdx !== idx) {
      return this.setState({ activeIdx: idx })
    }

    return this.setState({ activeIdx: null })
  }

  getRoutesByRoles = roleGroup => {
    return ROUTES_BY_ROLE_GROUP[roleGroup].filter(routes =>
      (routes.show === undefined || routes.show) &&
      this.props.auth.permisos.some(p => routes.permit === p.clave)
    )
  }

  render() {
    return (
      <SidebarContainer
        sizes={sizes}
        isOpen={this.props.showSidebarMobile}
        scrollHeight={this.props.scrollHeight}
      >
        <ImageProfileContainer image={GENERIC_AVATAR} />
        <PersonName>
          {this.props.auth.apellido}, {this.props.auth.nombre}
        </PersonName>
        {!!this.props.patient.coberturaMedica &&
          !!this.props.patient.nroAfiliado && (
            <PlanName>
              Plan {this.props.patient.coberturaMedica}
              <br />
              <PlanNumber>{this.props.patient.nroAfiliado}</PlanNumber>
            </PlanName>
          )}

        {/* Side menu Cellular My Data buttons */}
        <Ul sizes={sizes}>
          <Li>
            <Btn>
              <LinkNoStyle to="/inicio/perfil">Mis Datos</LinkNoStyle>
            </Btn>
          </Li>
          <Li>
            <Btn>
              <LinkNoStyle to="/inicio/contacto">Contacto</LinkNoStyle>
            </Btn>
          </Li>
          <Li button>
            <Logout />
          </Li>
        </Ul>
        {/* End Side menu Cellular My Data buttons */}

        {this.state.outStart ? (
          <>
            {/* Side menu What do you need? buttons*/}
            <HR />
            <SpecialtyUl sizes={sizes}>
              {this.props.auth.gruposRoles.map((roleName, idxRoleName) =>
                this.getRoutesByRoles(roleName).map((item, itemIdx) => {
                  return item.children && item.children.length > 0 ? (
                    <SpecialtyLi
                      key={idxRoleName + itemIdx}
                      onClick={() => this.setActiveIdx(idxRoleName + itemIdx)}
                    >
                      <SpecialtyBtn active={this.state.activeIdx === idxRoleName + itemIdx}>
                        {item.name}
                      </SpecialtyBtn>

                      <ChildUl active={this.state.activeIdx === idxRoleName + itemIdx}>
                        {item.children.map((child, childIdx) => (
                          <SpecialtyChildLi key={idxRoleName + itemIdx + childIdx}>
                            <SpecialtyBtn
                              activeChild={this.props.location.pathname === child.pathName}
                            >
                              <Route to={child.pathName}>{child.name}</Route>
                            </SpecialtyBtn>
                          </SpecialtyChildLi>
                        ))}
                      </ChildUl>
                    </SpecialtyLi>
                  ) : item.isExternalLink ? (
                    <SpecialtyLi key={idxRoleName + itemIdx}>
                      <SpecialtyBtn active={this.props.location.pathname === item.pathName}>
                        <ExternalLink target="_blank" href={item.pathName}>
                          {item.name}
                        </ExternalLink>
                      </SpecialtyBtn>
                    </SpecialtyLi>
                  ) : (
                    <SpecialtyLi key={idxRoleName + itemIdx}>
                      <SpecialtyBtn active={this.props.location.pathname === item.pathName}>
                        <LinkNoStyle to={item.pathName}>{item.name}</LinkNoStyle>
                      </SpecialtyBtn>
                    </SpecialtyLi>
                  )
                })
              )}
            </SpecialtyUl>
            {/* End Side menu What do you need? buttons */}
          </>
        ) : (
          <>
            <HR />
            {/* Se comenta por ahora ya que no se sabe si portal tendra esa funcionalidad */}
            {/*
            <RightText>Última consulta</RightText>

            <ConsultationShift>
              Clínica Bazterrica
              <br />
              <DatePlace>14/11/2020 - Guardia Médica</DatePlace>
            </ConsultationShift>
            <DrName>Dr. Marquez, Juan Carlos</DrName>

            <Btn>Repetir Consulta</Btn> */}
          </>
        )}
      </SidebarContainer>
    )
  }
}

function mapStateToProps({ auth, patient }) {
  return { auth, patient }
}
export default connect(
  mapStateToProps,
  null
)(withRouter(Sidebar))

/**
 * Sidebar container and general styles
 */
const SidebarContainer = styled.div`
  width: 20%;
  min-height: 70vh;
  float: left;
  justify-content: center;
  text-align: center;
  display: block;
  background-color: white;
  padding-bottom: 48px;
  @media (max-width: ${props => props.sizes.breakPoints.small}) {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-box-shadow: 11px 19px 21px -10px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 11px 19px 21px -10px rgba(0, 0, 0, 0.48);
    box-shadow: 11px 19px 21px -10px rgba(0, 0, 0, 0.48);
    width: 80vw;
    height: calc(
      100vh - ${props => props.theme.sizes.layout.smallHeader} + ${props => props.scrollHeight}px
    );
    position: fixed;
    top: calc(${props => props.theme.sizes.layout.smallHeader} - ${props => props.scrollHeight}px);
    ${props =>
    !props.isOpen &&
    css`
        left: -1000px;
      `};
    transition: left 0.5s, box-shadow 3s;
    z-index: 1;
  }

  ${props =>
    props.isOpen &&
    css`
      left: 0;
    `};
`

const ImageProfileContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 64px;
  border-radius: 100px;
  background-image: url('${props => props.image}');
  background-size: cover;
  background-repeat: no-repeat;
`

const PersonName = styled.p`
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  text-align: center;
  color: #04705e;
`

const PlanName = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #015445;
`

const PlanNumber = styled.span`
  font-weight: bold;
  font-size: 16px;
`

const HR = styled.hr`
  width: 80%;
  margin: auto;
  margin-top: 24px;
  background-color: #757575;
`

// const RightText = styled.p`
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 23px;
//   color: #4f4f4f;
//   text-align: start;
//   padding-left: 16%;
// `

// const ConsultationShift = styled.p`
//   font-weight: 600;
//   font-size: 14px;
//   text-align: center;
//   letter-spacing: 0.25px;
//   color: #4a4a4a;
// `

// const DatePlace = styled.span`
//   font-weight: normal;
//   font-size: 12px;
// `

// const DrName = styled(ConsultationShift)`
//   font-size: 16px;
// `

const Btn = styled.button`
  width: 170px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #04705e;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #04705e;
  margin: auto;
  margin-top: 8px;
`
/**
 * End sidebar container and general styles
 */

/**
 * Cellular information buttons style
 */
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.sizes.breakPoints.small}) {
    display: none;
  }
`

const baseLi = css`
  cursor: pointer;
`

const Li = styled.li`
  ${baseLi} 
  ${props =>
    props.active &&
    css`
      ${ChildLi} {
        display: flex;
        align-items: center;
      }

      ${Route} {
        padding: 0;
        text-align: left;
      }
    `};

  ${props =>
    props.button &&
    css`
      margin-top: auto;

      button {
        width: 170px;
        height: 41px;
        background: #ffffff;
        border: 1px solid #04705e;
        box-sizing: border-box;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #04705e;
        margin: auto;
        margin-top: 8px;
      }
    `};
`

const LinkNoStyle = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const ChildUl = styled.ul`
  display: none;

  ${props =>
    props.active &&
    css`
      display: block;
    `};
`

const ChildLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Route = styled(Link)`
  text-decoration: none;
  text-align: center;
  font-size: 12px;
  justify-content: center;
  color: #04705e;
`
const ExternalLink = styled.a`
  text-decoration: none;
  text-align: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
  margin: auto;
  margin-top: 8px;

  ${props =>
    props.active
      ? css`
          font-weight: 600;
          color: #04705e;
        `
      : css`
          font-weight: normal;
          color: #616161;
        `};
`
/**
 * End Cellular information buttons style
 */

/**
 * Specialty Button Styles
 */
const SpecialtyUl = styled(Ul)`
  display: block;
`

const SpecialtyLi = styled(Li)`
  align-items: start;
  text-align: start;
  padding-left: 16%;
`

const SpecialtyChildLi = styled(ChildLi)``

const SpecialtyBtn = styled(Btn)`
  width: 100%;
  border: none;
  font-size: 16px;
  text-align: start;

  ${props =>
    props.active
      ? css`
          font-weight: 600;
          color: #04705e;
        `
      : css`
          font-weight: normal;
          color: #616161;
        `};

  ${Route} {
    font-style: normal;
    font-size: 12px;

    ${props =>
    props.activeChild
      ? css`
            font-weight: bold;
            color: #000000;
          `
      : css`
            font-weight: 300;
            color: #333333;
          `};
  }
`
/**
 * End Specialty Button Styles
 */
