import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import SANTA_ISABEL from '../../images/santa-isabel.svg'
import BAZTERRICA from '../../images/bazterrica.svg'
import DEL_SOL from '../../images/del-sol.svg'
import CENTRO_BAZTERRICA from '../../images/centro-bazterrica.svg'
import SANTA_RITA from '../../images/santa-rita.svg'
import GENERIC_AVATAR from '../../images/generic_avatar.svg'

import { MaterialIcon } from '../../styled/generics'
import withUser from '../../hoc/withUser'
import Logout from '../Logout'

const Navigation = props => {
  const isUserLoggedIn = Object.keys(props.auth).length

  return (
    <Container>
      <SmallScreen>
        <IconButton onClick={props.handleShowSidebar}>
          <MaterialIcon name="menu" />
        </IconButton>
      </SmallScreen>
      <RootLink to="/">
        <ClinicLogo src={SANTA_ISABEL} alt="Clínica Santa Isabel" />
        <ClinicLogo src={DEL_SOL} alt="Clínica del Sol" />
        <ClinicLogo src={BAZTERRICA} alt="Clínica Bazterrica" />
        <ClinicLogo src={CENTRO_BAZTERRICA} alt="Centro Médico Bazterrica" />
        <ClinicLogo src={SANTA_RITA} alt="Centro Médico Santa Rita" />
      </RootLink>
      {isUserLoggedIn ? (
        <UserInfoContainer>
          <UserName>
            <ImageProfileContainer image={GENERIC_AVATAR} />
            <p>
              {props.auth.apellido}, {props.auth.nombre}
            </p>
          </UserName>
          <MaterialIconContainer>
            <MaterialIcon name="expand_more" />
          </MaterialIconContainer>
          <FloatingMenu>
            <Link to="/inicio/contacto">Contacto</Link>
            <Link to="/inicio/perfil">Mis datos</Link>
            <Link to="/inicio/nueva-contrasenia">Cambiar contraseña</Link>
            <Logout />
          </FloatingMenu>
        </UserInfoContainer>
      ) : null}
    </Container>
  )
}

export default withUser(Navigation)

/*
 * Styles
 */
const SmallScreen = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const IconButton = styled.div`
  padding: 10px;
  margin: 0;
  margin-top: 3px;
  background: none;
  border: none;
  position: absolute;
  left: 10px;
  color: #04705e;
  i {
    font-size: 32px;
  }
`

const RootLink = styled(Link)`
  display: inline-flex;
  height: 100%;
`

const ClinicLogo = styled.img`
  width: 55px;
  heigth: 55px;
  margin: 5px;
  margin-right: 24px;
  filter: brightness(65%);
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    filter: brightness(30%);
  }
`

const ActionContainer = styled.div``

const FloatingMenu = styled.div`
  position: absolute;
  top: 60px;
  background: white;
  display: none;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  box-shadow: 0 10px 20px -5px #ddd;

  a,
  button {
    color: ${props => props.theme.colors.warmGreyTwo};
    padding: 10px;
    width: 100%;
    text-align: right;
  }

  a:hover,
  button:hover {
    background: #04705e;
    color: white;
  }

  a {
    text-decoration: none;
  }

  button {
    background: none;
    border: none;
  }
`

const UserInfoContainer = styled.div`
  color: white;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-left: 10px;

  &:hover {
    cursor: pointer;
    background: white;
    color: ${props => props.theme.colors.warmGreyTwo};

    ${FloatingMenu} {
      display: flex;
      cursor: pointer;
    }
  }
`

const ImageProfileContainer = styled.div`
  width: 30px;
  height: 30px;
  margin: auto;
  margin-right: 8px;
  margin-top: 12px;
  border-radius: 100px;
  float: left;
  background-image: url('${props => props.image}');
  background-size: cover;
  background-repeat: no-repeat;
`

const UserName = styled.span`
  margin-right: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #04705e;

  p {
    float: right;
  }
`

const MaterialIconContainer = styled.div`
  color: #04705e;
  margin-top: 6px;

  i {
    font-size: 32px;
  }
`

const Container = styled.div`
  width: 90vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: auto;
    padding: 0 10px;

    ${RootLink}, ${ActionContainer} {
      display: none;
    }

    ${SmallScreen} {
      display: flex;
    }
  }
`
