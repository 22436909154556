import React, { Fragment } from 'react'
import styled from 'styled-components'
import to from 'await-catch'
import moment from 'moment'

import Layout from './shared/Layout'
import { downloadDutiesAsFile, getDuties } from '../api'
import { PAGINATION_PARAMETERS } from '../constants'
import withUser from '../hoc/withUser'
import Table from './Table'
import { MaterialIcon, MobileFullWidth } from '../styled/generics'
import FormFilterDuties from './FormFilterDuties'

class DutiesList extends React.Component {
  state = { clinics: [] }
  constructor(props) {
    super(props)
    this.state = {
      request: {
        cuit: props.auth.metadatos.find(metadata => metadata.metadato.clave === 'CUIT').valor,
        email: props.auth.email,
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas,
        fechaDesde: null
      },
      searchedAndNoData: false,
      dutiesSearched: false
    }
  }

  handleSubmit = async (formValues = undefined) => {
    var form = {}
    if (formValues) {
      this.setState(prevState => ({
        request: {
          ...prevState.request,
          fechaDesde: formValues.startDate.format('DD/MM/YYYY')
        }
      }))

      form = {
        ...this.state.request,
        items: []
      }
    } else {
      form = {
        ...this.state.request
      }
    }

    const [err, { data } = {}] = await to(getDuties(form))
    if (!err) {
      this.setState({
        dutiesSearched: true
      })
      if (data.hasOwnProperty('items')) {
        this.setState({
          request: {
            ...form,
            ...data
          },
          searchedAndNoData: false
        })
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        }
      }
    }
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      () => this.handleSubmit()
    )
  }

  setInitialPage = () => {
    const newState = this.state
    newState.request.pagina = 1

    if (!newState.request.fechaDesde) {
      const startDate = moment()
        .subtract(15, 'days')
        .format('DD/MM/YYYY')

      newState.request.fechaDesde = startDate
    }

    this.setState(newState)
  }

  downloadDutiesAsFile = async (e, row, extension) => {
    e.preventDefault()
    const request = {
      id: row.id
    }

    const [err, { data } = {}] = await to(downloadDutiesAsFile(request, extension))
    if (!err) {
      const type = extension === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel'
      const blob = new Blob([data], { type })
      const filename = `arancel_${new Date().getTime()}.${extension}`

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      }
    }
  }

  render() {
    const columns = [
      { title: 'Fecha Vigencia', index: 'fechaVigencia', size: 'minmax(200px,1fr)' },
      { title: 'Clínica', index: 'clinica', size: 'minmax(200px,1fr)' },
      { title: 'Observaciones', index: 'observaciones', size: 'minmax(200px,1fr)' },
      {
        title: 'Descargas',
        index: 'descargas',
        render: row => {
          return (
            <Fragment>
              <Download href="" onClick={e => this.downloadDutiesAsFile(e, this.state.request.items[row.id], "pdf")}>
                <MaterialIcon name="insert_drive_file" />
                <span>PDF</span>
              </Download>
              <Download href="" onClick={e => this.downloadDutiesAsFile(e, this.state.request.items[row.id], "xls")}>
                <MaterialIcon name="insert_drive_file" />
                <span>XLS</span>
              </Download>
            </Fragment>
          )
        },
        size: 'minmax(200px,1fr)'
      }
    ]

    return (
      <Layout topSpace title="Listado de aranceles">
        <FiltersContainer>
          <FormFilterDuties
            onSubmit={values => {
              this.setInitialPage()
              this.handleSubmit(values)
            }}
          />
        </FiltersContainer>
        {this.state.dutiesSearched && (
          <MobileFullWidth>
            <Table
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
              autoHeightCells
            >
            </Table>
          </MobileFullWidth>
        )}
      </Layout>
    )
  }
}

export default withUser(DutiesList)

/**
 * Styles
 */

const FiltersContainer = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
  background: ${props => props.theme.colors.greyFive};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    min-height: 55px;
    height: auto;
  }
`

const Download = styled.a`
  display: flex;
  width: auto;
  margin: 0 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.green};
`