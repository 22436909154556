import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { Route } from 'react-router-dom'
import to from 'await-catch'
import { withRouter } from 'react-router-dom'

import { confirmProgrammedGuardAppointment } from '../api'
import { setActiveClinic, setPatientDetailsAtClinic, setProgrammedGuardId } from '../actions'
import AppointmentHeader from './AppointmentHeader'
import ProgrammedGuardSchedule from './ProgrammedGuardSchedule'
import FormConfirmAppointment from './FormConfirmAppointment'
import ProgrammedGuardConfirmed from './ProgrammedGuardConfirmed'

class ProgrammedGuard extends React.Component {
  state = { email: '' }
  async componentDidMount() {
    this.props.setActiveClinic(
      this.props.smartGuard.clinicsTimes.find(
        time => time.clinica.codigoEmpresa === this.props.activeClinicId
      ).clinica
    )
  }

  onConfirmAppointment = async values => {
    const params = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      nombreClinica: this.props.appointment.activeClinic.nombre,
      direccionClinica: this.props.appointment.activeClinic.direccion,
      idEspecialidad: this.props.smartGuard.speciality.idEspecialidad,
      nombreEspecialidad: this.props.smartGuard.speciality.nombre,
      idObraSocial: this.props.appointment.patientAtClinicDetails.idObraSocial,
      coberturaMedica: this.props.appointment.patientAtClinicDetails.coberturaMedica,
      idPlan: this.props.appointment.patientAtClinicDetails.idPlan,
      plan: this.props.appointment.patientAtClinicDetails.planLineaCartilla,
      idPaciente: this.props.appointment.patientAtClinicDetails.idPaciente,
      idSistema: 'valtech.gol',
      fechaHora: this.props.smartGuard.schedule,
      nroCobertura: this.props.appointment.patientAtClinicDetails.nroCobertura,
      nroAfiliado: this.props.appointment.patientAtClinicDetails.nroAfiliado,
      nombreCompletoPaciente: this.props.appointment.patientAtClinicDetails.nombreApellido,
      nombrePaciente: this.props.appointment.patientAtClinicDetails.nombres,
      nroDocumento: this.props.appointment.patientAtClinicDetails.numeroDocumento,
      telefono: values.phone,
      mail: values.email,
      esVideoconsulta: false
    }

    const [err, { data } = {}] = await to(confirmProgrammedGuardAppointment(params))
    if (!err) {
      this.props.setProgrammedGuardId(data)
      this.setState({ email: values.email })
      this.props.history.push(`${this.props.match.url}/confirmada`)
    }
  }

  userHasValidInsuranceInClinic() {
    return (
      this.props.appointment.patientAtClinicDetails.sedesHabilitadas &&
      this.props.appointment.activeClinic &&
      this.props.appointment.patientAtClinicDetails.sedesHabilitadas.includes(
        this.props.appointment.activeClinic.codigoEmpresa
      ) &&
      this.props.appointment.patientAtClinicDetails.coberturaHabilitada
    )
  }

  render() {
    return (
      <Content>
        {/* has no patient data */}
        {!this.props.appointment.patientAtClinicDetails.nombreApellido && (
          <InfoContent>
            <NoPatientMessage>
              No se pudieron obtener los datos del paciente.
              <br />
              Por favor, intentá de nuevo más tarde.
            </NoPatientMessage>
          </InfoContent>
        )}

        {/* has patient data but no valid insurance */}
        {this.props.appointment.patientAtClinicDetails.nombreApellido &&
          !this.userHasValidInsuranceInClinic() && (
            <ErrorContent>
              <InsuranceMessage>
                Su cobertura no está habilitada para sacar turnos, por favor contactarte a la
                central de turnos:
                <br />
                0800-666-6587 o por WhatsApp al +54 114493-2017, de lunes a viernes de 8 a 20 hs
              </InsuranceMessage>
            </ErrorContent>
          )}

        {/* has patient data and valid insurance */}
        {this.props.appointment.patientAtClinicDetails.nombreApellido &&
          this.props.appointment.patientAtClinicDetails &&
          this.props.appointment.activeClinic &&
          this.userHasValidInsuranceInClinic() && (
            <React.Fragment>
              <AppointmentHeader
                fullName={this.props.appointment.patientAtClinicDetails.nombreApellido}
                healthInsurance={this.props.appointment.patientAtClinicDetails.coberturaMedica}
                memberNumber={this.props.appointment.patientAtClinicDetails.nroAfiliado}
              />
              <Route exact path={`${this.props.match.url}`} component={ProgrammedGuardSchedule} />
              <Route
                exact
                path={`${this.props.match.url}/confirmar`}
                render={() => (
                  <FormConfirmAppointment
                    clinic={this.props.appointment.activeClinic}
                    patient={this.props.appointment.patientAtClinicDetails}
                    schedule={this.props.smartGuard.schedule}
                    onCancel={() => this.props.history.goBack()}
                    onSubmit={this.onConfirmAppointment}
                  />
                )}
              />
              <Route
                exact
                path={`${this.props.match.url}/confirmada`}
                render={() => <ProgrammedGuardConfirmed email={this.state.email} />}
              />
            </React.Fragment>
          )}
      </Content>
    )
  }
}

const mapDispatchToProps = { setActiveClinic, setPatientDetailsAtClinic, setProgrammedGuardId }
function mapStateToProps({ smartGuard, appointment, auth, patient }) {
  return { smartGuard, appointment, auth, patient }
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProgrammedGuard)
)

/**
 * Styles
 */
const margin = css`
  margin: 50px 0;
`

const Content = styled.div`
  ${margin};
`

const ErrorContent = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.red};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 10px 10px;
  }
`

const InsuranceMessage = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${props => props.theme.colors.redTwo};
`

const InfoContent = styled.div`
  ${margin} padding: 20px 20px 40px;
  background: ${props => props.theme.colors.cloudyBlueTwo};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 10px 10px;
  }
`

const NoPatientMessage = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${props => props.theme.colors.darkishBlue};
`
