import { LOGIN, LOGOUT } from '../actions'

export const authReducer = function (state = {}, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload
    case LOGOUT:
      return {}
    default:
      return state
  }
}