import React from 'react'
import styled from 'styled-components'

import { CLINICS_LIST, PERSONAL_DATA_TEXT } from '../../constants'
import FACEBOOK from '../../images/facebook.svg'
import INSTAGRAM from '../../images/instagram.svg'


const Footer = () => (
  <Container>
    <ContactContainer>
      <Clinics>
        <ClinicsTitle>Nuestras clínicas:</ClinicsTitle>
        <ClinicNames>
          {Object.entries(CLINICS_LIST).map(([id, clinicData]) => (
            <AnchorContainer key={id}>
              <ClinicLink href={clinicData.url} target="_blank" rel="noopener noreferrer">
                {clinicData.name}
              </ClinicLink>
            </AnchorContainer>
          ))}
        </ClinicNames>
      </Clinics>

      {/* FACEBOOK LINKS */}
      <SocialContainer>
        <Icons>
          <a
            href="https://es-la.facebook.com/GrupoOMINT/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FACEBOOK} alt="facebook" />
          </a>
        </Icons>
        <SocialAnchorContainer>
          <a
            href="https://www.facebook.com/cl.santaisabel/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook Santa Isabel
          </a>
        </SocialAnchorContainer>
        <SocialAnchorContainer>
          <a
            href="https://www.facebook.com/clinicabazterrica"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook Bazterrica
          </a>
        </SocialAnchorContainer>
        <SocialAnchorContainer>
          <a
            href="https://www.facebook.com/clinica.delsol/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook del Sol
          </a>
        </SocialAnchorContainer>
      </SocialContainer>

      {/* INSTAGRAM LINKS */}
      <SocialContainer>
        <Icons>
          <a href="#/">
            <img src={INSTAGRAM} alt="instagram" />
          </a>
        </Icons>
        <SocialAnchorContainer>
          <a
            href="https://www.instagram.com/clinica_santaisabel/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram Santa Isabel
          </a>
        </SocialAnchorContainer>
        <SocialAnchorContainer>
          <a
            href="https://www.instagram.com/clinicabazterrica/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram Bazterrica
          </a>
        </SocialAnchorContainer>
        <SocialAnchorContainer>
          <a
            href="https://www.instagram.com/clinica_delsol/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram del Sol
          </a>
        </SocialAnchorContainer>
        <SocialAnchorContainer>
          <a
            href="https://www.instagram.com/cmsantarita/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram Santa Rita
          </a>
        </SocialAnchorContainer>
      </SocialContainer>
    </ContactContainer>
    <ProteccionDatosContainer>
      <ProteccionDatos>{PERSONAL_DATA_TEXT}</ProteccionDatos>
    </ProteccionDatosContainer>
  </Container>
)

export default Footer

const Container = styled.footer`
  margin-top: 30px;
  background: ${props => props.theme.colors.greyFour};
  display: flex;
  flex-direction: column;
  align-items: center;
  clear:both;
  bottom: 0;
`
const ClinicsTitle = styled.h5`
  color: ${props => props.theme.colors.greyFive};
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  margin: 20px 0 0 0;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 5px 0;
  }
`

const ProteccionDatosContainer = styled.div`
  background: ${props => props.theme.colors.warmGrey};
  width: 100%;
  display: flex;
  justify-content: center;
`

const ProteccionDatos = styled.div`
  color: ${props => props.theme.colors.greyFive};
  font-size: 10px;
  width: 60%;
  margin: 35px 0;
  white-space: pre-line;
  text-align: justify;
  line-height: 16px;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 85%;
    margin: 20px 0;
  }
`

const Clinics = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 55%;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 0 0 25px 0;
    align-items: center;
  }
`
const ClinicLink = styled.a`
  text-decoration: none;
  color: white;
`
const AnchorContainer = styled.div`
  width: 50%;
  margin: 5px 0;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 3px 0;
    width: auto;
  }
`
const SocialAnchorContainer = styled(AnchorContainer)`
  width: 100%;
  & img {
    color: white;
    text-decoration-color: none;
    font-size: 14px;
  }
  & a {
    color: white;
    text-decoration-color: none;
    font-size: 14px;
  }
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    text-align: center;
  }
`
const ClinicNames = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 0;
  width: 100%;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  max-width: ${props => props.theme.sizes.breakPoints.small};
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 0;
    flex-direction: column;
    width: auto;
    text-align: center;
  }
`
const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: auto;
    flex-wrap: wrap;
    & a {
      margin: 0 10px;
    }
  }
`
const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: auto;
    align-items: center;
    justify-content: center;
  }
`
const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 35px 0;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 15px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
