import { API } from './instances'

export const getPatients = async data => {
    const url = '/Internaciones/Buscar'
    return await API.get(url, { params: data })
}

export const downloadPatientList = async data => {
    const url = '/Internaciones/Exportar'
    return await API.post(url, data)
}