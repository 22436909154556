export const PAGINATION_PARAMETERS = {
    pagina: 1,
    itemsPorPagina: 10,
    totalPaginas: 0
}

export const PAGINATION_PARAMETERS_EXTENDED = {
    pagina: 1,
    itemsPorPagina: 50,
    totalPaginas: 0
}