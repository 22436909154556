import { API } from './instances'

export const getDuties = async data => {
    const url = '/Aranceles'
    return await API.get(url, { params: data })
}

export const downloadDutiesAsFile = async (data, extension) => {
    const url = `/Aranceles/exportar/${extension}/${data.id}`
    return await API.get(url, { responseType: 'arraybuffer' })
}