import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const DashboardTab = props => (
  <Container>
    {props.listTabs &&
      props.listTabs.map(tab => (
        <Tab key={tab.id} active={tab.id === props.activeTab} onClick={() => props.onClick(tab.id)}>
          <h4>{tab.name}</h4>
        </Tab>
      ))}
  </Container>
)

DashboardTab.propTypes = {
  listTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  activeTab: PropTypes.number,
  onClick: PropTypes.func
}

DashboardTab.defaultProps = { activeTab: 0 }

export default DashboardTab

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  margin: 10px auto 60px auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 10px auto 30px auto;
  }
`
const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.colors.whiteTwo};
  border-bottom: 3px solid ${props => props.theme.colors.whiteTwo};
  padding-bottom: 10px;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.colors.green};
      border-bottom: 6px solid ${props => props.theme.colors.green};
    `} &:not(:first-child) {
    margin-left: 6px;
  }
  h4 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding-bottom: 6px;
    h4 {
      font-size: 16px;
    }
    &:not(:first-child) {
      margin-left: 0;
    }
  }
`
