import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Login from './components/Login'
import Dashboard from './components/Dashboard'
import PrivateRoute from './components/PrivateRoute'
import Appointment from './components/Appointment'
import SmartGuard from './components/SmartGuard'
import ClinicGuard from './components/ClinicGuard'
import SignUp from './components/SignUp'
import Perfil from './components/Perfil'
import HospitalizedPatients from './components/HospitalizedPatients'
import Contact from './components/Contact'
import DutiesList from './components/DutiesList'
import RolesList from './components/RolesList'
import UsersList from './components/UsersList'
import UsersNew from './components/UsersNew'
import UsersUpdate from './components/UsersUpdate'
import UsersNewConfirmData from './components/UsersNewConfirmData'
import LabsList from './components/LabsList'
import LabConfirmPatientData from './components/LabConfirmPatientData'
import {
  FUTURE_APPOINTMENTS,
  HOSPITALIZED_PATIENTS,
  DUTIES,
  ROLES,
  USERS,
  MEGOLI,
  LABORATORIES,
  BILLING_PERMIT,
  WOMEN_CENTER,
  SPORT_MEDICINE,
  FACE_TO_FACE
} from './constants'
import features from './features'
import FormForgotPassword from './components/FormForgotPassword'
import FormResetPassword from './components/FormResetPassword'
import MegoliQuestions from './components/MegoliQuestions'
import MegoliMedic from './components/MegoliMedic'
import TermsAndConditions from './components/TermsAndConditions'
import ChangePassword from './components/ChangePassword'
import FormNewPassword from './components/FormNewPassword'
import InvoiceList from './components/InvoiceList'
import MegoliAppointment from './components/MegoliAppointment'
import InvoiceDetail from './components/InvoiceDetail'
import InvoiceBondDetail from './components/InvoiceBondDetail'
import LogsList from './components/LogsList'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/registro" exact component={SignUp} />
      <Route path="/recuperar-cuenta" exact component={FormForgotPassword} />
      <Route path="/inicio/nuevo" exact component={FormNewPassword} />
      <Route path="/inicio/cambiar-contrasenia" exact component={FormResetPassword} />
      <Route path="/terminos-condiciones" exact component={TermsAndConditions} />

      <PrivateRoute path="/inicio/nueva-contrasenia" exact component={ChangePassword} />
      <PrivateRoute path="/inicio" exact component={Dashboard} />
      <PrivateRoute path="/inicio/perfil" exact component={Perfil} />
      <PrivateRoute path="/inicio/contacto" exact component={Contact} />

      <PrivateRoute
        path="/inicio/gestionar-turnos/:attention"
        component={Appointment}
        permit={FUTURE_APPOINTMENTS}
        validParams={
          features.enableNewSpecialties
            ? [WOMEN_CENTER, FACE_TO_FACE, SPORT_MEDICINE]
            : [FACE_TO_FACE]
        }
        param={'attention'}
      />

      <PrivateRoute
        path="/inicio/guardia-smart"
        exact
        component={SmartGuard}
        permit={FUTURE_APPOINTMENTS}
        disable={features.disableClinicGuard}
      />

      <PrivateRoute
        path="/inicio/guardia-smart/guardia-clinica"
        component={ClinicGuard}
        permit={FUTURE_APPOINTMENTS}
      />

      <PrivateRoute
        path="/inicio/pacientes-internados"
        exact
        component={HospitalizedPatients}
        permit={HOSPITALIZED_PATIENTS}
      />
      <PrivateRoute path="/inicio/aranceles" exact component={DutiesList} permit={DUTIES} />

      <PrivateRoute path="/inicio/roles" exact component={RolesList} permit={ROLES} />
      <PrivateRoute path="/inicio/usuarios" exact component={UsersList} permit={USERS} />
      <PrivateRoute path="/inicio/usuarios/nuevo" exact component={UsersNew} permit={USERS} />
      <PrivateRoute
        path="/inicio/usuarios/modificar"
        exact
        component={UsersUpdate}
        permit={USERS}
      />

      <PrivateRoute
        path="/inicio/usuarios/nuevo/confirmar"
        exact
        component={UsersNewConfirmData}
        permit={USERS}
      />

      <PrivateRoute
        path="/inicio/usuarios/modificar/confirmar"
        exact
        component={UsersNewConfirmData}
        permit={USERS}
      />

      <PrivateRoute path="/inicio/logs" exact component={LogsList} permit={USERS} />

      <PrivateRoute path="/inicio/laboratorios" exact component={LabsList} permit={LABORATORIES} />
      <PrivateRoute
        path="/inicio/laboratorios/confirmar"
        exact
        component={LabConfirmPatientData}
        permit={LABORATORIES}
      />

      <PrivateRoute
        path="/inicio/videoconsulta"
        exact
        component={MegoliQuestions}
        permit={MEGOLI}
        disable={features.disableVirtualConsultation}
      />

      <PrivateRoute
        path="/inicio/videoconsulta/turnos"
        component={MegoliAppointment}
        permit={MEGOLI}
      />

      <PrivateRoute
        path="/inicio/videoconsulta-medico"
        exact
        component={MegoliMedic}
        permit={MEGOLI}
      />

      <PrivateRoute
        path="/inicio/facturacion"
        exact
        component={InvoiceList}
        permit={BILLING_PERMIT}
      />

      <PrivateRoute
        path="/inicio/facturacion/:id"
        exact
        component={InvoiceDetail}
        permit={BILLING_PERMIT}
      />

      <PrivateRoute
        path="/inicio/facturacion/:id/:bondId"
        exact
        component={InvoiceBondDetail}
        permit={BILLING_PERMIT}
      />
    </Switch>
  </BrowserRouter>
)

export default Routes