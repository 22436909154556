import React from 'react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  Input,
  Button,
  ErrorField,
  CheckboxLabel,
  CheckboxContainer,
  Select,
  Paragraph, 
} from '../styled/generics'
import { PediatricPatientSearch } from './PediatricPatientSearch'


class  FormMegoliQuestions extends React.Component {
   constructor(props) {
    super(props);
    
    this.state = {
      initialValues: {
        phone: this.props.phone,
        clinic: '',
        speciality: this.props.selectedSpec ? this.props.selectedSpec.idEspecialidad : '',
        terms: false,
        documentType: '',
        documentNumber: '',
        name: '',
        lastName: '',
        email: '',
        repeatEmail: '',
        gender: '',
        birthDate: '',
        credentialNumber: '',
        healthInsurance: '',
        plan: ''
      }
    };
  }

  getValidationSchema = () => {
    return yup.object().shape({
    phone: yup.string().matches(/^[+]*[-\s./0-9]*$/, 'Teléfono no válido').required('Campo requerido'),
    clinic: this.props.clinics.length 
      ? yup.string().required('Campo requerido') 
      : yup.string().notRequired(),
    speciality: yup.string().required('Campo requerido'),
    documentType: this.props.isPediatria()
      ? yup.string().required('Campo requerido') 
      : yup.string().notRequired(),
    documentNumber: this.props.isPediatria()
      ? yup.string()
          .matches(/^[0-9]*$/, 'El campo debe ser numérico')
          .test('len', 'El campo debe tener a lo sumo 9 dígitos', val => !val || val.toString().length <= 9)
          .notOneOf(['0'], 'El campo no puede ser 0')
          .required('Campo requerido') 
      : yup.string().notRequired(),
    name: this.props.isNewPatient()
      ? yup.string().required('Campo requerido') 
      : yup.string().notRequired(),
    lastName: this.props.isNewPatient()
      ? yup.string().required('Campo requerido') 
      : yup.string().notRequired(),
    email: this.props.isNewPatient()
      ? yup.string()
          .email('Email no válido')
          .required('Campo requerido') 
      : yup.string().notRequired(),
    repeatEmail: this.props.isNewPatient()
      ? yup.string()
          .email('Email no válido')
          .oneOf([yup.ref('email'), null], 'Los emails deben coincidir')
          .required('Campo requerido') 
      : yup.string().notRequired(),
    gender: this.props.isNewPatient()
      ? yup.string().required('Campo requerido') 
      : yup.string().notRequired(),
    birthDate: this.props.isNewPatient()
      ? yup.date()
          .typeError("Fecha de nacimiento no válida")
          .min(moment().subtract(18, 'years').startOf('day').toDate(), "El paciente debe ser menor de edad")
          .max(moment().endOf('day').toDate(), "Fecha de nacimiento no válida")
          .required('Campo requerido')
      : yup.date().notRequired(),
    credentialNumber: this.props.isNewPatient()
      ? yup.string()
          .matches(/^[a-zA-Z0-9]*$/, 'El campo solo puede contener letras y numeros')  
          .test('len', 'El campo puede tener hasta 30 caracteres', val => !val || val.toString().length <= 30)
          .required('Campo requerido') 
      : yup.string().notRequired(),
    healthInsurance: this.props.isNewPatient()
      ? yup.string().required('Campo requerido') 
      : yup.string().notRequired(),
    plan: (this.props.isNewPatient())
      ? yup.string().required('Campo requerido')
      : yup.string().notRequired(),
    terms: yup.boolean().oneOf([true], 'Campo requerido')
    })
  }

  renderNotAvailable = () => {
    return (
        <React.Fragment>
          {this.props.selectedSpec && (                   
            <NotAvailable>
              {"La atencíon es de " +  this.props.selectedSpec.descripcionHorario.toLowerCase()}
            </NotAvailable>
          )}
          <Button type="button" onClick={this.props.onReturn}>Volver</Button>
        </React.Fragment>
      )
  }

  resetSignUpFields = (renderProps) => {
    renderProps.setFieldValue('name', '')
    renderProps.setFieldTouched('name', false)
    renderProps.setFieldValue('lastName', '')
    renderProps.setFieldTouched('lastName', false)
    renderProps.setFieldValue('email', '')
    renderProps.setFieldTouched('email', false)
    renderProps.setFieldValue('repeatEmail', '')
    renderProps.setFieldTouched('repeatEmail', false)
    renderProps.setFieldValue('gender', '')
    renderProps.setFieldTouched('gender', false)
    renderProps.setFieldValue('birthDate', '')
    renderProps.setFieldTouched('birthDate', false)
    renderProps.setFieldValue('credentialNumber', '')
    renderProps.setFieldTouched('credentialNumber', false)
    renderProps.setFieldValue('healthInsurance', '')
    renderProps.setFieldTouched('healthInsurance', false)
    renderProps.setFieldValue('plan', '')
    renderProps.setFieldTouched('plan', false)
  }

  render() {
    return (
      <FullWidthContainer>
        <Paragraph center bold>
          El servicio de Videoconsulta está disponible {this.props.renderSpecHours()}.
        </Paragraph>

        <Paragraph center bold>
          Podrás recibir orientación médica en caso de tener alguno de estos síntomas: 
        </Paragraph>

        <UL>
          <li>. Resfrío, congestión nasal, tos o catarro.</li>
          <li>. Gripe, malestar general.</li>
          <li>. Fiebre (menos de 48 horas de evolución).</li>
          <li>. Dolor de garganta.</li>
          <li>. Dolor de cabeza.</li>
          <li>. Diarrea.</li>
          <li>. Náuseas, vómitos.</li>
          <li>. Molestias al orinar.</li>
          <li>. Erupción, manchas en la piel, picaduras.</li>
        </UL>
        <Paragraph center>
          Ante cualquier otro cuadro, recordá que podés hacer{' '}
          <Link to="/inicio/guardia-smart">Check In o Guardia Programada</Link>.
        </Paragraph>

        <Formik
          onSubmit={this.props.onSubmit}
          initialValues={this.state.initialValues}
          validationSchema={this.getValidationSchema}
          render={renderProps => (
            <form onSubmit={renderProps.handleSubmit}>
              <Container>
                <P>
                  Teléfono de contacto en caso de que se corte la llamada
                </P>
                <Input
                  type="tel"
                  name="phone"
                  value={renderProps.values.phone}
                  placeholder="Teléfono de contacto"
                  white={1}
                  border={1}
                  warning={renderProps.errors.phone && renderProps.touched.phone ? 1 : 0}
                />
                {renderProps.errors.phone &&
                  renderProps.touched.phone && <ErrorField>{renderProps.errors.phone}</ErrorField>}

                <P>
                  Elegí la especialidad con la que querés atenderte.
                </P>
                <Select
                  component="select"
                  name="speciality"
                  placeholder = ""
                  warning={renderProps.errors.speciality && renderProps.touched.speciality ? 1 : 0}
                  onChange={e => {
                    renderProps.handleChange(e)
                    this.props.onSpecialitySelected(e.currentTarget.value)
                    renderProps.setFieldValue('clinic', '')
                    renderProps.setFieldTouched('clinic', false)
                    renderProps.setFieldValue('documentType', '')
                    renderProps.setFieldTouched('documentType', false)
                    renderProps.setFieldValue('documentNumber', '')
                    renderProps.setFieldTouched('documentNumber', false)
                  }}
                >
                  <option value="" disabled hidden>
                    Elegí la especialidad
                  </option>
                  {this.props.specHours.map((elem, idx) => (
                    <option value={elem.idEspecialidad} key={idx}>
                      {elem.especialidad}
                    </option>
                  ))}
                </Select>
                {renderProps.errors.speciality &&
                  renderProps.touched.speciality && <ErrorField>{renderProps.errors.speciality}</ErrorField>}
              </Container>

              {this.props.isPediatria() && this.props.selectedSpec.disponible && (
                /* BUSQUEDA DE PACIENTE PEDIATRIA*/
                <PediatricPatientSearch
                    formikRenderProps={renderProps}
                    onSearchChanged={this.props.onSearchChanged}
                    onSearchPediatricPatient={this.props.onSearchPediatricPatient}
                    patientError={this.props.patientError}
                    resetSignUpFields={this.resetSignUpFields}
                  />
              )}

              <Container>
                {(this.props.selectedSpec && this.props.selectedSpec.disponible && this.props.canConnect()) && (
                  <React.Fragment>
                    <P>
                      Elegí la clínica a la que irías, en caso de que el médico te prescriba algún estudio
                      complementario.
                    </P>
                    <Select
                      component="select"
                      name="clinic"
                      warning={renderProps.errors.clinic && renderProps.touched.clinic ? 1 : 0}
                    >
                      <option value="" disabled hidden>
                        Elegí la clínica
                      </option>
                      {this.props.clinics.map((clinic, idx) => (
                        <option value={clinic.codigoEmpresa} key={idx}>
                          {clinic.nombre}
                        </option>
                      ))}
                    </Select>
                    {renderProps.errors.clinic &&
                      renderProps.touched.clinic && <ErrorField>{renderProps.errors.clinic}</ErrorField>}
               
                                  
                    <Field name="terms">
                      {({ field, form }) => (
                        <CheckboxContainer warning={form.errors.terms ? 1 : 0} marginTop={'25px'}>
                          <input
                            type="checkbox"
                            checked={field.value}
                            id="terms"
                            onChange={() => {
                              form.setFieldValue('terms', !field.value)
                              form.setFieldTouched('terms', true)
                            }}
                          />
                          <CheckboxLabel htmlFor="terms">
                            Acepto los{' '}
                            <Link
                              to="/static/tycmoliclinicas.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Términos y Condiciones
                            </Link>
                          </CheckboxLabel>
                        </CheckboxContainer>
                      )}
                    </Field>
                  </React.Fragment>
                )}

                {(this.props.selectedSpec && !this.props.selectedSpec.disponible) ?
                  this.renderNotAvailable() : 
                  <Button type="submit" disabled={!this.props.canConnect()}>
                    Consultar
                  </Button>
                }
              </Container>
            </form>
          )}
        />
      </FullWidthContainer>
    )
  } 
}

export default FormMegoliQuestions

const FullWidthContainer = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin-bottom: 50px;
  background: ${props => props.theme.colors.greyFive};
`

const UL = styled.ul`
  color: ${props => props.theme.colors.warmGreyTwo};
  list-style-type: none;
  padding: 0;
  text-align: center;
  > li {
    margin: 10px 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  input[type='tel'],
  select {
    width: 370px;
    margin: 15px 0 5px 0;
  }
  button {
    margin: 37px auto 0 auto;
    width: 370px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    input[type='tel'],
    button,
    select {
      width: 100%;
    }
  }
`
const P = styled.p`
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
  text-align: center;
`

const NotAvailable = styled.p`
  color: ${props => props.theme.colors.redTwo};
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
  text-align: center;
`
