import React from 'react'
import styled, { css } from 'styled-components'

import { APPOINTMENT_STAGE_LIST } from '../constants';
import AppointmentHeader from './AppointmentHeader'
import { LinkButton } from '../styled/generics'
import TableAppointments from './TableAppointments'
import withPatient from '../hoc/withPatient'

class ListAppointments extends React.Component {

  componentDidMount() {
    this.props.setAppointmentStage(APPOINTMENT_STAGE_LIST);
  }

  render() {
    return (
      <React.Fragment>
        <Content>
          <AppointmentHeader
            fullName={this.props.patient.nombreApellido}
            healthInsurance={this.props.patient.coberturaMedica}
            memberNumber={this.props.patient.nroAfiliado}
          />
          <ButtonContent>
            <LinkButton to={`/inicio/gestionar-turnos/${this.props.match.path.split('/')[3]}/nuevo`}>Nuevo turno</LinkButton>
          </ButtonContent>
        </Content>
        <TableAppointments />
      </React.Fragment>
    )
  }
}

export default withPatient(ListAppointments)

/*
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`
const ButtonContent = styled.div`
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
  > a {
    width: 320px;
  }
`
