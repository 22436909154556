import { SHOW_LOADER, HIDE_LOADER, SET_LOADER_PROGRESS } from '../actions'


export const loaderDefaults = {
  show: false,
  downloadData: null
}
export const loaderReducer = function (state = loaderDefaults, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        show: true
      }
    case HIDE_LOADER:
      return {
        ...loaderDefaults
      }
    case SET_LOADER_PROGRESS:
      return {
        ...state,
        downloadData: action.payload
      }
    default:
      return state
  }
}