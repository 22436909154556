//Invoice Actions
export const INVOICE_ACTION_SEND = 'Enviar'
export const INVOICE_ACTION_SEND_ALL = 'Enviar varios'
export const INVOICE_ACTION_CANCEL = 'Cancelar'
export const INVOICE_ACTION_CANCEL_ALL = 'Cancelar varios'

//Users Actions
export const USERS_ACTION_PASSWORD = 'Password'
export const USERS_ACTION_DELETE = 'Borrar'

//Log Actions
export const LOG_ACTION_LOGIN = 1