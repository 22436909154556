import { SET_PATIENT_DETAILS } from '../actions'

export const patientDefaults = {
    nombres: null,
    apellidos: null,
    nombreApellido: null,
    idObraSocial: null,
    coberturaMedica: null,
    idPlan: null,
    planLineaCartilla: null,
    nroAfiliado: null,
    telefono: null,
    mail: null,
    idPaciente: null,
    nroCobertura: null,
    coberturaHabilitada: null,
    pacienteERID: null,
    numeroDocumento: null
}
export const patientReducer = function (state = patientDefaults, action) {
    switch (action.type) {
        case SET_PATIENT_DETAILS:
            return {
                ...action.payload
            }
        default:
            return state
    }
}